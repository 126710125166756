import * as React from 'react';

export const ConstantContactIcon = (props: any) =>
<svg
    className={`system-icon ${props.className}`}
    width= {props.width || 154}
    height= {props.height || 43}
    viewBox="0 0 154 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
<path fillRule="evenodd" clipRule="evenodd" d="M64.8772 36.9649C64.5 36.4369 64.0474 36.286 63.3684 36.6632C62.3877 37.1912 61.1807 37.5684 59.9737 37.5684C56.5789 37.5684 54.2404 35.2298 54.2404 31.9105C54.2404 28.5912 56.5789 26.2527 59.9737 26.2527C61.1053 26.2527 62.3877 26.5544 63.3684 27.1579C64.0474 27.5351 64.5 27.4597 64.8772 26.8562L65.2544 26.3281C65.6316 25.7246 65.5561 25.1965 65.0281 24.8193C63.5193 23.8386 61.9351 23.386 60.0491 23.386C57.4088 23.386 55.0702 24.2913 53.4105 25.9509C51.8263 27.5351 50.9965 29.6474 50.9965 31.986C50.9965 34.3246 51.8263 36.4369 53.4105 38.0211C55.0702 39.6807 57.3333 40.586 60.0491 40.586C62.0105 40.586 63.5947 40.1334 65.179 39.0772C65.6316 38.7 65.707 38.2474 65.3298 37.6439L64.8772 36.9649Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M72.8737 27.8368C69.2526 27.8368 66.386 30.628 66.386 34.1736C66.386 37.7192 69.2526 40.5105 72.8737 40.5105C76.5702 40.5105 79.4368 37.7192 79.4368 34.1736C79.3614 30.7035 76.4193 27.8368 72.8737 27.8368ZM72.8737 37.7947C70.9123 37.7947 69.4035 36.2105 69.4035 34.1736C69.4035 32.1368 70.9123 30.5526 72.8737 30.5526C74.8351 30.5526 76.3438 32.1368 76.3438 34.1736C76.2684 36.2105 74.7596 37.7947 72.8737 37.7947Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M88.2632 27.8368C86.5281 27.8368 85.1702 28.5912 84.5667 29.8736V29.1947C84.5667 28.5157 84.1895 28.1385 83.5105 28.1385H82.4544C81.7754 28.1385 81.3983 28.5157 81.3983 29.1947V39.1526C81.3983 39.8315 81.7754 40.2087 82.4544 40.2087H83.5105C84.1895 40.2087 84.5667 39.8315 84.5667 39.1526V34.3245C84.5667 33.0421 85.1702 30.5526 87.5842 30.5526C88.8667 30.5526 89.6211 31.6087 89.6211 33.4192V39.0771C89.6211 39.7561 89.9982 40.1333 90.6772 40.1333H91.7333C92.4123 40.1333 92.7895 39.7561 92.7895 39.0771V33.2684C92.7895 29.8736 91.1298 27.8368 88.2632 27.8368Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M116.402 28.1385H115.346C114.667 28.1385 114.289 28.5157 114.289 29.1947V29.7228C113.384 28.5157 111.951 27.8368 110.367 27.8368C106.972 27.8368 104.482 30.4771 104.482 34.1736C104.482 37.7947 106.972 40.5105 110.367 40.5105C112.026 40.5105 113.384 39.8315 114.289 38.6245V39.1526C114.289 39.8315 114.667 40.2087 115.346 40.2087H116.402C117.081 40.2087 117.458 39.8315 117.458 39.1526V29.1947C117.458 28.5157 117.081 28.1385 116.402 28.1385ZM110.895 37.7947C108.933 37.7947 107.575 36.2859 107.575 34.1736C107.575 32.0614 108.933 30.5526 110.895 30.5526C112.856 30.5526 114.289 32.0614 114.289 34.1736C114.289 36.2859 112.932 37.7947 110.895 37.7947Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M126.511 30.5526C126.888 30.5526 127.718 30.628 128.547 31.0052C129.226 31.307 129.679 31.2315 130.056 30.7035L130.358 30.3263C130.584 30.0245 130.66 29.7228 130.584 29.4964C130.509 29.2701 130.358 29.0438 130.056 28.8175C128.623 27.9877 127.265 27.7614 126.36 27.7614C122.286 27.7614 119.495 30.4017 119.495 34.1736C119.495 37.7947 122.361 40.3596 126.36 40.3596C127.189 40.3596 128.472 40.2087 129.754 39.5298C130.132 39.3035 130.433 39.0017 130.509 38.7C130.584 38.3982 130.509 38.1719 130.282 37.8701L129.981 37.4929C129.604 36.9649 129.151 36.8894 128.472 37.2666C127.567 37.7193 126.586 37.7192 126.435 37.7192C124.172 37.7192 122.588 36.2105 122.588 34.0982C122.663 32.0614 124.247 30.5526 126.511 30.5526Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M140.24 37.1158C140.089 36.9649 140.014 36.8895 139.863 36.8895C139.712 36.8895 139.561 36.9649 139.411 37.1158L139.335 37.1913C139.26 37.3421 138.882 37.6439 138.354 37.7193C138.203 37.7193 138.053 37.7948 137.902 37.7193C137.223 37.6439 136.846 37.1913 136.846 36.286V30.4772H139.712C140.089 30.4772 140.316 30.2509 140.316 29.8737V28.6667C140.316 28.2895 140.089 28.0632 139.712 28.0632H136.846V27.0071V25.8C136.846 25.1211 136.468 24.7439 135.789 24.7439H134.809C134.13 24.7439 133.753 25.1211 133.753 25.8V27.9878H132.546C132.168 27.9878 131.942 28.2141 131.942 28.5913V29.7983C131.942 30.1755 132.168 30.4018 132.546 30.4018H133.753V31.6842V36.286C133.753 39.9825 136.318 40.5106 137.826 40.5106C138.354 40.5106 138.807 40.4351 139.26 40.3597C139.486 40.2842 139.712 40.2088 139.939 40.0579C140.316 39.9071 140.542 39.6807 140.844 39.379C140.995 39.2281 141.146 39.0772 141.221 38.9263C141.447 38.7 141.372 38.3983 141.146 38.0965L140.24 37.1158Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M102.596 37.1158C102.446 36.9649 102.37 36.8895 102.219 36.8895C102.068 36.8895 101.918 36.9649 101.767 37.1158L101.691 37.1913C101.616 37.3421 101.239 37.6439 100.786 37.7193C100.635 37.7193 100.484 37.7948 100.333 37.7193C99.6544 37.6439 99.2772 37.1913 99.2772 36.286V30.4772H102.144C102.521 30.4772 102.747 30.2509 102.747 29.8737V28.6667C102.747 28.2895 102.521 28.0632 102.144 28.0632H99.2772V27.0071V25.8C99.2772 25.1211 98.9 24.7439 98.2211 24.7439H97.2404C96.5614 24.7439 96.1842 25.1211 96.1842 25.8V27.9878H94.9772C94.6 27.9878 94.3737 28.2141 94.3737 28.5913V29.7983C94.3737 30.1755 94.6 30.4018 94.9772 30.4018H96.1842V31.6842V36.286C96.1842 39.9825 98.7491 40.5106 100.258 40.5106C100.786 40.5106 101.239 40.4351 101.691 40.3597C101.918 40.2842 102.144 40.2088 102.37 40.0579C102.747 39.9071 102.974 39.6807 103.275 39.379C103.426 39.2281 103.577 39.0772 103.653 38.9263C103.879 38.7 103.804 38.3983 103.577 38.0965L102.596 37.1158Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M64.8772 15.7667C64.5 15.2386 64.0474 15.0877 63.3684 15.4649C62.3877 15.993 61.1807 16.3702 59.9737 16.3702C56.5789 16.3702 54.2404 14.0316 54.2404 10.7123C54.2404 7.39301 56.5789 5.05441 59.9737 5.05441C61.1053 5.05441 62.3877 5.35617 63.3684 5.95967C64.0474 6.33687 64.5 6.26143 64.8772 5.65792L65.2544 5.12985C65.6316 4.52634 65.5561 3.99827 65.0281 3.62108C63.5193 2.64038 61.9351 2.18774 60.0491 2.18774C57.4088 2.18774 55.0702 3.09301 53.4105 4.75266C51.8263 6.33687 50.9965 8.44915 50.9965 10.7877C50.9965 13.1263 51.8263 15.2386 53.4105 16.8228C55.0702 18.4825 57.3333 19.3877 60.0491 19.3877C62.0105 19.3877 63.5947 18.9351 65.179 17.879C65.6316 17.5018 65.707 17.0491 65.3298 16.4456L64.8772 15.7667Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M72.8737 6.63855C69.2526 6.63855 66.386 9.42978 66.386 12.9754C66.386 16.521 69.2526 19.3122 72.8737 19.3122C76.5702 19.3122 79.4368 16.521 79.4368 12.9754C79.3614 9.42978 76.4193 6.63855 72.8737 6.63855ZM72.8737 16.521C70.9123 16.521 69.4035 14.9368 69.4035 12.9754C69.4035 10.9386 70.9123 9.35434 72.8737 9.35434C74.8351 9.35434 76.3438 10.9386 76.3438 12.9754C76.2684 14.9368 74.7596 16.521 72.8737 16.521Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M88.2632 6.63855C86.5281 6.63855 85.1702 7.39294 84.5667 8.67539V7.99644C84.5667 7.3175 84.1895 6.9403 83.5105 6.9403H82.4544C81.7754 6.9403 81.3983 7.3175 81.3983 7.99644V17.9543C81.3983 18.6333 81.7754 19.0105 82.4544 19.0105H83.5105C84.1895 19.0105 84.5667 18.6333 84.5667 17.9543V13.1263C84.5667 11.8438 85.1702 9.35434 87.5842 9.35434C88.8667 9.35434 89.6211 10.4105 89.6211 12.221V17.8789C89.6211 18.5578 89.9982 18.935 90.6772 18.935H91.7333C92.4123 18.935 92.7895 18.5578 92.7895 17.8789V12.0701C92.7895 8.67539 91.1298 6.63855 88.2632 6.63855Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M100.711 11.9193L99.1263 11.3912C98.2965 11.1649 97.9947 10.7877 97.9947 10.2596C97.9947 9.58066 98.5982 9.12802 99.3526 9.12802C99.8807 9.12802 100.333 9.2789 100.56 9.65609C100.937 10.1087 101.314 10.2596 101.918 10.1842L102.446 10.0333C102.823 9.95785 103.125 9.73153 103.2 9.42978C103.275 9.20346 103.275 8.90171 103.049 8.59995C102.295 7.3175 101.012 6.63855 99.2772 6.63855C96.7877 6.63855 94.9772 8.22276 94.9772 10.4105C94.9772 11.9947 96.0333 13.2017 98.221 13.8807L99.7298 14.4087C100.635 14.7105 100.937 15.0877 100.937 15.5403C100.937 16.2193 100.258 16.7473 99.2772 16.7473C98.5228 16.7473 97.7684 16.4456 97.3912 15.9175C97.014 15.4649 96.6368 15.1631 96.0333 15.314L95.4298 15.4649C95.0526 15.5403 94.7509 15.7666 94.6754 15.9929C94.6 16.2193 94.6 16.521 94.7509 16.8228C95.5053 18.3315 97.1649 19.2368 99.2772 19.2368C102.446 19.2368 103.879 17.3508 103.879 15.4649C103.879 14.3333 103.351 12.7491 100.711 11.9193Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M120.777 19.2368C122.437 19.2368 123.795 18.5578 124.7 17.3508V17.8789C124.7 18.5578 125.077 18.935 125.756 18.935H126.812C127.491 18.935 127.868 18.5578 127.868 17.8789V7.99644C127.868 7.3175 127.491 6.9403 126.812 6.9403H125.756C125.077 6.9403 124.7 7.3175 124.7 7.99644V8.52451C123.795 7.3175 122.361 6.63855 120.777 6.63855C117.382 6.63855 114.893 9.2789 114.893 12.9754C114.818 16.5964 117.307 19.2368 120.777 19.2368ZM121.305 9.2789C123.267 9.2789 124.7 10.7877 124.7 12.9C124.7 15.0122 123.267 16.521 121.305 16.521C119.344 16.521 117.986 15.0122 117.986 12.9C118.061 10.7877 119.419 9.2789 121.305 9.2789Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M137.525 6.63855C135.789 6.63855 134.432 7.39294 133.828 8.67539V7.99644C133.828 7.3175 133.451 6.9403 132.772 6.9403H131.716C131.037 6.9403 130.66 7.3175 130.66 7.99644V17.9543C130.66 18.6333 131.037 19.0105 131.716 19.0105H132.772C133.451 19.0105 133.828 18.6333 133.828 17.9543V13.1263C133.828 11.8438 134.432 9.35434 136.846 9.35434C138.128 9.35434 138.882 10.4105 138.882 12.221V17.8789C138.882 18.5578 139.26 18.935 139.939 18.935H140.995C141.674 18.935 142.051 18.5578 142.051 17.8789V12.0701C142.051 8.67539 140.391 6.63855 137.525 6.63855Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M152.461 16.8982L151.707 15.9175C151.556 15.7666 151.481 15.6911 151.33 15.6911C151.179 15.6911 151.028 15.7666 150.877 15.9175L150.802 15.9929C150.726 16.1438 150.349 16.4455 149.821 16.521C149.67 16.521 149.519 16.5964 149.368 16.521C148.689 16.4455 148.312 15.9929 148.312 15.0876V9.27887H151.179C151.556 9.27887 151.782 9.05255 151.782 8.67536V7.54378C151.782 7.16659 151.556 6.94027 151.179 6.94027H148.312V5.88413V4.67711C148.312 3.99816 147.935 3.62097 147.256 3.62097H146.275C145.596 3.62097 145.219 3.99816 145.219 4.67711V6.86483H144.012C143.635 6.86483 143.409 7.09115 143.409 7.46834V8.67536C143.409 9.05255 143.635 9.27887 144.012 9.27887H145.219V14.9368C145.219 18.6333 147.784 19.1613 149.293 19.1613C149.821 19.1613 150.274 19.0859 150.726 19.0104C150.953 18.935 151.179 18.8596 151.405 18.7087C151.782 18.5578 152.009 18.3315 152.311 18.0297C152.461 17.8789 152.612 17.728 152.688 17.5771C152.763 17.4262 152.763 17.1245 152.461 16.8982Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M105.388 9.3543H106.595V10.4104V15.0122C106.595 18.7087 109.16 19.2368 110.668 19.2368C111.196 19.2368 111.649 19.1613 112.102 19.0859C112.328 19.0104 112.554 18.935 112.781 18.7841C113.158 18.6333 113.384 18.4069 113.686 18.1052C113.837 17.9543 113.988 17.8034 114.063 17.6526C114.289 17.4262 114.214 17.1245 113.988 16.8227L113.233 15.842C113.082 15.6911 113.007 15.6157 112.856 15.6157C112.705 15.6157 112.554 15.6911 112.404 15.842L112.328 15.9175C112.253 16.0683 111.875 16.3701 111.423 16.4455C111.272 16.4455 111.121 16.521 110.97 16.4455C110.291 16.3701 109.914 15.9175 109.914 15.0122V9.20343H112.781C113.158 9.20343 113.384 8.97711 113.384 8.59992V7.54378C113.384 7.16659 113.158 6.94027 112.781 6.94027H109.914V5.88413V4.67711C109.914 3.99816 109.537 3.62097 108.858 3.62097H107.877C107.198 3.62097 106.821 3.99816 106.821 4.67711V6.86483H105.614C105.237 6.86483 105.011 7.09115 105.011 7.46834V8.67536C104.784 9.12799 105.011 9.3543 105.388 9.3543Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M27.3842 17.5018C27.7614 18.6334 28.8176 19.3123 30.0246 19.3123C30.3263 19.3123 30.6281 19.2369 30.9298 19.1614C32.3632 18.6334 33.1176 17.0492 32.5895 15.6158C31.6842 13.1264 29.7983 11.165 27.1579 9.95794C25.8 9.35443 24.1404 9.95794 23.4614 11.3158C22.8579 12.6737 23.4614 14.3334 24.8193 15.0123C26.1772 15.5404 27.0825 16.4457 27.3842 17.5018Z" fill="#FF9E1A"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.4789 0.0754217C24.9702 -0.226333 23.5368 0.754369 23.2351 2.26314C22.9333 3.77191 23.914 5.20525 25.4228 5.507C31.6842 6.78946 35.9842 10.9386 37.0403 17.0491C37.2667 18.407 38.4737 19.3123 39.7561 19.3123C39.907 19.3123 40.0579 19.3123 40.2088 19.2368C41.7175 18.9351 42.6982 17.5017 42.4719 16.0684C40.9632 7.77016 35.0035 1.73507 26.4789 0.0754217Z" fill="#FF9E1A"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.4632 27.686C16.9737 27.686 14.8614 25.6491 14.8614 23.0842C14.8614 20.5948 16.8983 18.4825 19.4632 18.4825C20.9719 18.4825 22.2544 17.2755 22.2544 15.6913C22.2544 14.1825 21.0474 12.9 19.4632 12.9C13.8807 12.9 9.3544 17.4263 9.3544 23.0088C9.3544 28.5913 13.8807 33.1176 19.4632 33.1176C25.0456 33.1176 29.5719 28.5913 29.5719 23.0088C29.5719 21.5 28.3649 20.2176 26.7807 20.2176C25.2719 20.2176 23.9895 21.4246 23.9895 23.0088C24.0649 25.6491 22.0281 27.686 19.4632 27.686Z" fill="#1856ED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.2105 20.3683C34.7018 20.3683 33.4193 21.5754 33.4193 23.1596C33.4193 30.8543 27.1579 37.1157 19.4632 37.1157C11.7684 37.1157 5.50702 30.8543 5.50702 23.1596C5.50702 15.4648 11.7684 9.20343 19.4632 9.20343C20.9719 9.20343 22.2544 7.99641 22.2544 6.4122C22.2544 4.90343 21.0474 3.62097 19.4632 3.62097C8.75088 3.62097 0 12.3718 0 23.1596C0 33.8718 8.75088 42.6227 19.4632 42.6227C30.1754 42.6227 38.9263 33.8718 38.9263 23.1596C38.9263 21.5754 37.7193 20.3683 36.2105 20.3683Z" fill="#1856ED"/>
</svg>
