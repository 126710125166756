import * as React from 'react'
import './discover.css'

export const DiscoverIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width="34.796" height="23.997" viewBox="0 0 34.796 23.997" className="discover-icon">
    <g id="Group_6422" data-name="Group 6422" transform="translate(-185 -200)">
        <g id="Group_6421" data-name="Group 6421" transform="translate(-33 5)">
            <g id="discover" transform="translate(218 195)">
                <path id="Path_21797" d="M34.8 101.391a2.057 2.057 0 0 1-2.057 2.057H2.057A2.057 2.057 0 0 1 0 101.391V81.508a2.057 2.057 0 0 1 2.057-2.057h30.682a2.057 2.057 0 0 1 2.061 2.057v19.883z" className="cls-1" data-name="Path 21797" transform="translate(0 -79.451)"/>
                <path id="Path_21798" d="M1.462 101.391V81.508a2.057 2.057 0 0 1 2.057-2.057H2.057A2.057 2.057 0 0 0 0 81.508v19.883a2.057 2.057 0 0 0 2.057 2.057h1.462a2.057 2.057 0 0 1-2.057-2.057z" className="cls-2" data-name="Path 21798" transform="translate(0 -79.451)"/>
                <path id="Path_21799" d="M239.3 220.943a2.516 2.516 0 0 0-2.546-2.542h-.009a2.555 2.555 0 1 0 2.556 2.555l-.001-.013z" className="cls-3" data-name="Path 21799" transform="translate(-218.274 -208.958)"/>
                <g id="Group_6420" data-name="Group 6420" transform="translate(2.303 9.394)">
                    <path id="Path_21800" d="M175.748 221.363a1.57 1.57 0 0 1-1.114.54 1.587 1.587 0 0 1-1.605-1.67 1.854 1.854 0 0 1 .481-1.239 1.5 1.5 0 0 1 1.042-.432 1.549 1.549 0 0 1 1.179.541v-1.13a2.469 2.469 0 0 0-1.179-.295 2.847 2.847 0 0 0-1.666.693 2.676 2.676 0 0 0-.89 1.911 2.5 2.5 0 0 0 2.556 2.506h.049a2.78 2.78 0 0 0 1.18-.295l-.033-1.131c-.017.017.016.032 0 .048z" className="cls-4" data-name="Path 21800" transform="translate(-162.61 -217.677)"/>
                    <path id="Path_21801" d="M309.925 221.073l-1.113 2.736-1.278-3.244H306.5l2.015 4.964h.541l2.113-4.964h-1.032z" className="cls-4" data-name="Path 21801" transform="translate(-287.975 -220.37)"/>
                    <path id="Path_21802" d="M380.983 222.728v2.654h2.7v-.782h-1.72v-1.327h1.671v-.836h-1.671V221.4h1.72v-.836h-2.7z" className="cls-4" data-name="Path 21802" transform="translate(-357.394 -220.37)"/>
                    <path id="Path_21803" d="M35.26 220.566h-1.376v4.816h1.376a2.666 2.666 0 0 0 1.72-.541 2.43 2.43 0 0 0 .885-1.867 2.378 2.378 0 0 0-2.605-2.408zm1.13 3.637a1.89 1.89 0 0 1-1.278.393h-.295V221.4h.246a1.669 1.669 0 0 1 1.278.393 1.57 1.57 0 0 1 .541 1.18 1.469 1.469 0 0 1-.492 1.227z" className="cls-4" data-name="Path 21803" transform="translate(-33.884 -220.37)"/>
                    <path id="Rectangle_2131" d="M0 0h.934v4.816H0z" className="cls-4" data-name="Rectangle 2131" transform="translate(4.423 .196)"/>
                    <path id="Path_21804" d="M120.454 221.763c-.59-.2-.737-.344-.737-.59a.622.622 0 0 1 .688-.541.951.951 0 0 1 .786.393l.492-.639a2.14 2.14 0 0 0-1.425-.541 1.442 1.442 0 0 0-1.524 1.376c0 .688.3.983 1.18 1.327a1.911 1.911 0 0 1 .639.295.541.541 0 0 1 .295.491.685.685 0 0 1-.737.688 1.146 1.146 0 0 1-1.032-.639l-.59.59a1.928 1.928 0 0 0 1.671.934 1.55 1.55 0 0 0 1.67-1.573c0-.834-.295-1.177-1.376-1.571z" className="cls-4" data-name="Path 21804" transform="translate(-112.738 -219.7)"/>
                    <path id="Path_21805" d="M432.169 223.367a1.3 1.3 0 0 0 1.13-1.376c0-.884-.639-1.425-1.72-1.425h-1.425v4.816h.934v-1.917h.148l1.327 1.917h1.13zm-.787-.59h-.295V221.3h.295c.59 0 .934.246.934.737s-.344.74-.934.74z" className="cls-4" data-name="Path 21805" transform="translate(-403.223 -220.37)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
