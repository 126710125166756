import * as React from 'react'
import './infusionsoft.css'

export const InfusionsoftIcon = (props: any) =>
<svg viewBox="0 0 276 111" fill="none" xmlns="http://www.w3.org/2000/svg" className={`system-icon ${props.className}`}>
    <path d="M0 4C0 1.79086 1.79086 0 4 0H272C274.209 0 276 1.79086 276 4V111H0V4Z" fill="#36A635"/>
    <path d="M62.2489 40.3157V66.467H57.9766V40.3157H62.2489Z" fill="white"/>
    <path d="M79.5024 66.4672V55.6119C79.5024 52.6514 78.1879 51.0066 75.3945 51.0066C72.601 51.0066 70.6292 53.6382 70.6292 56.5987V66.4672H66.6855V47.8816H70.4649V49.6908H71.4508C72.4367 48.2106 74.2442 47.3882 76.709 47.3882C81.31 47.3882 83.6104 50.1842 83.6104 54.7895V66.4672H79.5024Z" fill="white"/>
    <path d="M92.3191 51.3357V66.4673H88.2112V51.3357H85.2534V47.8817H88.2112V46.0725C88.2112 41.6317 90.8403 39.8225 95.1126 39.8225H97.0844V43.2765H95.1126C92.9764 43.2765 92.3191 44.7567 92.3191 46.237V47.8817H97.0844V51.3357H92.3191Z" fill="white"/>
    <path d="M103.165 47.8816V58.7369C103.165 61.6974 104.479 63.3422 107.273 63.3422C110.23 63.3422 112.038 60.7106 112.038 57.75V47.8816H116.146V66.4672H112.202V64.6579H111.381C110.23 66.1382 108.423 66.9606 105.958 66.9606C101.357 66.9606 99.0566 64.1645 99.0566 59.5592V47.8816H103.165Z" fill="white"/>
    <path d="M130.277 52.8158C129.948 51.1711 128.469 50.8421 126.662 50.8421C124.69 50.8421 123.211 51.3356 123.211 52.8158C123.211 53.9671 124.033 54.4606 125.676 54.625L129.291 55.2829C132.577 55.7764 134.878 57.5856 134.878 61.0395C134.878 64.1645 132.084 66.9606 126.99 66.9606C121.568 66.9606 118.939 64.658 118.446 60.8751H122.554C122.882 62.8487 124.526 63.5066 126.826 63.5066C129.291 63.5066 130.77 62.8487 130.77 61.0395C130.77 59.7237 129.948 59.0658 127.812 58.5724L123.868 57.9145C121.239 57.4211 119.267 55.9408 119.267 52.9803C119.267 49.8553 121.568 47.3882 126.826 47.3882C131.263 47.3882 133.892 49.5263 134.385 52.8158H130.277Z" fill="white"/>
    <path d="M137.343 43.7699V39.8225H141.451V43.7699H137.343ZM141.451 66.4673H137.343V47.8817H141.451V66.4673Z" fill="white"/>
    <path d="M162.813 57.0922C162.813 62.8487 159.362 66.9606 153.611 66.9606C147.695 66.9606 144.245 62.8487 144.245 57.0922C144.245 51.3356 147.86 47.3882 153.611 47.3882C159.362 47.3882 162.813 51.5 162.813 57.0922ZM158.705 57.0922C158.705 53.9671 157.226 50.8421 153.611 50.8421C149.996 50.8421 148.353 53.9671 148.353 57.0922C148.353 60.3816 149.996 63.5066 153.611 63.5066C157.226 63.5066 158.705 60.5461 158.705 57.0922Z" fill="white"/>
    <path d="M178.587 66.4672V55.6119C178.587 52.6514 177.108 51.0066 174.479 51.0066C171.521 51.0066 169.714 53.6382 169.714 56.5987V66.4672H165.606V47.8816H169.55V49.6908H170.371C171.521 48.2106 173.329 47.3882 175.794 47.3882C180.23 47.3882 182.695 50.1842 182.695 54.7895V66.4672H178.587Z" fill="white"/>
    <path d="M184.831 61.3681H187.132C187.624 63.6708 189.432 64.9866 192.554 64.9866C195.676 64.9866 197.648 63.8352 197.648 61.3681C197.648 59.5589 196.662 58.5721 194.033 58.0787C193.211 58.0787 191.075 57.5852 189.761 57.2563C187.296 56.9273 185.653 55.6115 185.653 52.98C185.653 50.0194 187.953 47.7168 192.554 47.7168C196.662 47.7168 199.127 49.6905 199.455 52.8155H197.155C196.991 50.8418 194.855 49.6905 192.39 49.6905C189.596 49.6905 187.953 50.8418 187.953 52.651C187.953 54.2958 188.939 55.1181 190.911 55.4471C191.568 55.6115 192.225 55.6115 192.718 55.776L195.019 56.105C197.977 56.5984 199.948 58.2431 199.948 61.3681C199.948 64.3287 197.319 66.9603 192.718 66.9603C187.789 66.9603 185.324 64.8221 184.831 61.3681Z" fill="white"/>
    <path d="M219.009 57.4208C219.009 62.6839 215.887 66.9603 210.3 66.9603C204.713 66.9603 201.591 62.6839 201.591 57.4208C201.591 51.9931 204.713 47.7168 210.465 47.7168C216.051 47.7168 219.009 51.9931 219.009 57.4208ZM216.709 57.4208C216.709 53.4734 214.737 49.6905 210.3 49.6905C205.864 49.6905 203.892 53.4734 203.892 57.4208C203.892 61.2037 205.864 64.9866 210.3 64.9866C214.737 64.9866 216.709 61.2037 216.709 57.4208Z" fill="white"/>
    <path d="M225.747 50.1844V66.4673H223.446V50.1844H220.16V48.2107H223.446V45.7436C223.446 41.7962 225.418 39.8225 229.362 39.8225H230.84V41.7962H229.362C226.732 41.7962 225.747 43.6054 225.747 45.7436V48.2107H230.84V50.1844H225.747Z" fill="white"/>
    <path d="M243 48.2107V50.1844H238.235V61.3686C238.235 63.5068 239.056 64.4936 241.85 64.4936H243V66.4673H241.686C237.906 66.4673 235.934 65.316 235.934 61.5331V50.1844H232.648V48.2107H235.934V42.4541H238.235V48.2107H243Z" fill="white"/>
    <path d="M48.6103 51.9934V39L33 48.8684L46.1455 53.6382L48.6103 51.9934Z" fill="white"/>
    <path d="M46.1455 53.6382L44.9953 54.4606L45.8169 54.7895L49.1033 55.9408V66.6316L34.1502 61.204L33 62.0264L50.0892 68.2764V55.1185L46.1455 53.6382Z" fill="white"/>
    <path d="M44.9953 54.4608L33 50.02V62.0266" fill="white"/>
    <path d="M194.526 79.6249C194.526 81.9276 193.376 83.5723 191.404 83.5723C191.075 83.5723 190.582 83.5723 190.254 83.4079C189.925 83.2434 189.596 82.9144 189.268 82.5855H188.939V83.2434H186.967V72.717H188.939V76.4999H189.432C189.596 76.171 189.925 76.0065 190.254 75.842C190.582 75.6776 190.911 75.6776 191.404 75.6776C193.376 75.6776 194.526 77.3223 194.526 79.6249ZM192.554 79.6249C192.554 78.3092 191.897 77.3223 190.747 77.3223C189.596 77.3223 188.939 78.4736 188.939 79.6249C188.939 80.7763 189.596 81.7631 190.747 81.7631C192.061 81.7631 192.554 80.7763 192.554 79.6249Z" fill="white"/>
    <path d="M196.662 86.2039L198.305 82.5855L195.183 75.842H197.155L199.127 79.7894H199.455L201.099 75.842H203.07L198.634 86.2039H196.662Z" fill="white"/>
    <path d="M208.493 72.8818H210.629V77.3226L214.901 72.8818H217.366L214.244 76.1713L217.859 83.4082H215.558L212.929 78.145H212.601L210.629 79.9542V83.4082H208.493V72.8818Z" fill="white"/>
    <path d="M225.418 79.7893H220.16C220.16 81.1051 220.817 81.763 221.967 81.763C222.789 81.763 223.118 81.5986 223.282 81.1051H225.418C225.089 82.5854 223.939 83.5722 221.967 83.5722C219.667 83.5722 218.188 81.9275 218.188 79.6249C218.188 77.1578 219.667 75.6775 221.967 75.6775C222.296 75.6775 222.789 75.6775 223.282 75.842C223.775 76.0064 224.103 76.3354 224.432 76.6643C224.761 76.9933 225.089 77.3222 225.254 77.8157C225.418 78.3091 225.418 78.8025 225.418 79.2959V79.7893ZM220.324 78.4735H223.446C223.446 78.1446 223.118 77.8157 222.953 77.4867C222.625 77.3222 222.296 77.1578 221.803 77.1578C221.474 77.1578 221.146 77.3222 220.817 77.4867C220.488 77.8157 220.324 78.1446 220.324 78.4735Z" fill="white"/>
    <path d="M231.661 82.7499H231.333C231.004 82.9143 230.675 83.2433 230.347 83.4078C230.018 83.5722 229.689 83.5722 229.196 83.5722C228.868 83.5722 228.539 83.5722 228.211 83.4078C227.882 83.2433 227.718 83.0788 227.389 82.9143C227.225 82.5854 226.896 82.4209 226.896 82.092C226.732 81.763 226.567 81.4341 226.567 81.1051C226.567 79.9538 227.389 78.638 229.689 78.638H231.497V78.3091C231.497 77.6512 231.168 77.1578 230.182 77.1578C229.361 77.1578 229.032 77.4867 229.032 77.9801H227.06C227.06 76.4999 228.375 75.6775 230.182 75.6775C232.154 75.6775 233.469 76.4999 233.469 78.4735V83.4078H231.661V82.7499ZM231.661 80.2828V79.9538H230.018C229.196 79.9538 228.704 80.1183 228.704 80.9407C228.704 81.5986 229.196 81.9275 230.018 81.9275C230.182 81.9275 230.347 81.9275 230.511 81.763C230.84 81.763 231.004 81.5986 231.168 81.4341C231.333 81.2696 231.333 81.1051 231.497 80.9407C231.497 80.7762 231.661 80.6117 231.661 80.2828Z" fill="white"/>
    <path d="M237.413 75.842V76.4999H237.906C238.07 76.1709 238.399 76.0064 238.728 75.842C239.056 75.6775 239.549 75.6775 239.878 75.6775C241.85 75.6775 243 77.3222 243 79.6249C243 81.763 241.85 83.5722 239.878 83.5722C239.549 83.5722 239.22 83.5722 238.728 83.4078C238.399 83.2433 238.07 82.9143 237.906 82.5854H237.577V86.2038H235.605V75.842H237.413ZM237.413 79.6249C237.413 80.7762 238.07 81.763 239.22 81.763C240.535 81.763 241.028 80.7762 241.028 79.6249C241.028 78.3091 240.535 77.3222 239.22 77.3222C238.07 77.3222 237.413 78.4736 237.413 79.6249Z" fill="white"/>
</svg>
