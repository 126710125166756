import * as React from 'react'
import './new-scheduled-content.css'

export const NewScheduledContentIcon = (props: any) =>
<svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"   width={props.svgwidth}
    height={props.svgheight}>
<path d="M15.3333 4.33325V10.9999" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28.6667 4.33325V10.9999" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M33.6667 7.66675H10.3333C8.49238 7.66675 7 9.15913 7 11.0001V34.3334C7 36.1744 8.49238 37.6667 10.3333 37.6667H33.6667C35.5076 37.6667 37 36.1744 37 34.3334V11.0001C37 9.15913 35.5076 7.66675 33.6667 7.66675Z" fill={props.greyScale ? '#efefef' : '#B8E5E1'} stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7 17.6667H37" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 27.6666L20.3333 30.9999L27 24.3333" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>