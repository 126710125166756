import * as React from 'react'
import './hubspot.css'

export const HubspotIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" className={`system-icon ${props.className}`} viewBox="0 0 74 56">
    <defs>
        <clipPath id="clip-path">
            <path id="Rectangle_2003" d="M0 0h74v56H0z" className="hs-1" data-name="Rectangle 2003" transform="translate(438 336)"/>
        </clipPath>
        <clipPath id="clip-path-2">
            <path id="Rectangle_2058" d="M0 0h62.955v17.847H0z" className="hs-2" data-name="Rectangle 2058"/>
        </clipPath>
        <clipPath id="clip-path-4">
            <path id="Rectangle_2057" d="M0 0h16.821v17.566H0z" className="hs-2" data-name="Rectangle 2057"/>
        </clipPath>
    </defs>
    <g id="Mask_Group_18" className="hs-3" data-name="Mask Group 18" transform="translate(-438 -336)">
        <g id="Group_5092" className="hs-4" data-name="Group 5092" transform="translate(443.353 355.539)">
            <g id="Outlines_-_Full_Color" data-name="Outlines - Full Color">
                <g id="Group_5083" data-name="Group 5083">
                    <g id="Group_5097" data-name="Group 5097">
                        <g id="Group_5096" className="hs-5" data-name="Group 5096">
                            <g id="Group_5082" data-name="Group 5082">
                                <g id="Group_5081" data-name="Group 5081">
                                    <path id="Path_13598" d="M-1919.491-458.121v5.034h-4.623v-5.034h-2.195v12.016h2.195v-4.87h4.623v4.87h2.195v-12.016z" className="hs-6" data-name="Path 13598" transform="translate(1926.309 461.075)"/>
                                    <g id="Group_5077" data-name="Group 5077">
                                        <g id="Group_5093" data-name="Group 5093">
                                            <g id="Group_5092-2" className="hs-5" data-name="Group 5092">
                                                <g id="Group_5076" data-name="Group 5076" transform="translate(10.323 2.953)">
                                                    <g id="Group_5075" data-name="Group 5075">
                                                        <path id="Path_13599" d="M-1894.614-445.46a1.816 1.816 0 0 1-1.816 1.816 1.816 1.816 0 0 1-1.816-1.816v-5.147h-2.082v5.147a3.894 3.894 0 0 0 3.894 3.894 3.894 3.894 0 0 0 3.894-3.894v-5.147h-2.074z" className="hs-6" data-name="Path 13599" transform="translate(1900.327 453.594)"/>
                                                        <path id="Path_13600" d="M-1850.908-454.607a1.291 1.291 0 0 1 1.462-1.391 3.12 3.12 0 0 1 1.962 1.038l1.364-1.606a4.3 4.3 0 0 0-3.192-1.557c-2.258 0-3.892 1.323-3.892 3.515 0 4.068 4.972 2.778 4.972 5.054a1.435 1.435 0 0 1-1.463 1.323 2.556 2.556 0 0 1-2.2-1.239l-1.513 1.573a4.465 4.465 0 0 0 3.591 1.792c2.146 0 3.871-1.339 3.871-3.432 0-4.516-4.972-3.113-4.972-5.071" className="hs-6" data-name="Path 13600" transform="translate(1872.047 458.124)"/>
                                                        <path id="Path_13601" d="M-1775.3-446.652c-1.232 0-1.579-.532-1.579-1.347v-3.608h1.912v-1.828h-1.912v-2.412l-2.111.947v7.35c0 1.88 1.3 2.827 3.075 2.827a4.244 4.244 0 0 0 .833-.067l.516-1.9c-.232.016-.5.032-.733.032" className="hs-6" data-name="Path 13601" transform="translate(1827.201 456.752)"/>
                                                        <path id="Path_13602" d="M-1872.876-454.965a3.193 3.193 0 0 0-2.446.982v-3.955h-2.085v7.331a4.376 4.376 0 0 0 4.211 4.624 4.569 4.569 0 0 0 4.649-4.489 4.413 4.413 0 0 0-4.331-4.49m-.013 6.882a2.366 2.366 0 0 1-2.366-2.366 2.366 2.366 0 0 1 2.365-2.366 2.366 2.366 0 0 1 2.366 2.366 2.366 2.366 0 0 1-2.366 2.366" className="hs-6" data-name="Path 13602" transform="translate(1886.514 458.012)"/>
                                                        <path id="Path_13603" d="M-1822.461-446.238a4.562 4.562 0 0 0-4.633-4.49h-.016a4.375 4.375 0 0 0-4.211 4.624v7.335h2.085v-3.961a3.2 3.2 0 0 0 2.445.982 4.41 4.41 0 0 0 4.331-4.489m-1.973-.026a2.366 2.366 0 0 1-2.365 2.366 2.366 2.366 0 0 1-2.366-2.366 2.366 2.366 0 0 1 2.366-2.366 2.366 2.366 0 0 1 2.365 2.366" className="hs-6" data-name="Path 13603" transform="translate(1858.738 453.667)"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_5080" data-name="Group 5080" transform="translate(40.421 .014)">
                                        <g id="Group_5095" data-name="Group 5095">
                                            <g id="Group_5094" className="hs-7" data-name="Group 5094">
                                                <g id="Group_5079" data-name="Group 5079" transform="translate(.122 .112)">
                                                    <g id="Group_5078" data-name="Group 5078">
                                                        <path id="Path_13604" d="M-1811.494-459.505v-2.073a1.6 1.6 0 0 0 .921-1.439v-.049a1.6 1.6 0 0 0-1.595-1.595h-.048a1.6 1.6 0 0 0-1.595 1.595v.049a1.6 1.6 0 0 0 .921 1.439v2.073a4.525 4.525 0 0 0-2.151.946l-5.693-4.429a1.759 1.759 0 0 0 .064-.449 1.8 1.8 0 0 0-1.8-1.8 1.8 1.8 0 0 0-1.8 1.8 1.8 1.8 0 0 0 1.8 1.8 1.781 1.781 0 0 0 .89-.242l5.6 4.357a4.537 4.537 0 0 0 .07 5.114l-1.7 1.7a1.449 1.449 0 0 0-.422-.069 1.479 1.479 0 0 0-1.479 1.478 1.479 1.479 0 0 0 1.479 1.479 1.479 1.479 0 0 0 1.478-1.479 1.438 1.438 0 0 0-.068-.422l1.685-1.685a4.544 4.544 0 0 0 6.369-.853 4.544 4.544 0 0 0-.853-6.368 4.54 4.54 0 0 0-2.064-.88m-.7 6.82a2.332 2.332 0 0 1-2.331-2.331 2.332 2.332 0 0 1 2.331-2.331 2.332 2.332 0 0 1 2.332 2.331 2.332 2.332 0 0 1-2.332 2.331" className="hs-8" data-name="Path 13604" transform="translate(1824.268 465.238)"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
