import * as React from 'react';
import './integrately.css'

export const IntegratelyIcon = (props: any) =>
<svg version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={`system-icon ${props.className}`}
  width={props.width || 400}
  height={props.height || 333.33}
  viewBox="0 0 250 215"
  fill="none"
>
  <path className="integrately-st0" d="M105.9,209.5L105.9,209.5C49.3,209.5,3,163.2,3,106.9l0,0C3,50.3,49.2,4.3,105.9,4.3l0,0
    c56.3,0,102.6,46.3,102.6,102.6l0,0C208.5,163.3,162.2,209.5,105.9,209.5z"/>
  <path className="integrately-st2" d="M155.8,131.9c-4,20.4-22.2,36.5-43.2,37.8c-21.9,1.5-42.6-10-50.5-31.7c-2.4-6.4-3-13.1-3-20.1
    c0-6.7,0-13.1,0-19.8c0-6.1,2.1-8.2,8.2-8.2c11.6,0,23.1,0,34.4,0c0,0,0,0,0-0.3c11.3,0,22.5,0,33.8,0c5.2,0,10.4,0.3,15.5,0.3
    c3,0,5.8,2.1,5.8,5.2C157,107.8,158.3,120,155.8,131.9z"/>
  <path className="integrately-st2" d="M87.3,83.8c7.3,0.3,9.7-0.6,10-5.2c0.3-8.2,0.3-16.1,0-24.4c0-3-2.4-5.2-5.2-5.2c-1.8,0-3.3,0-5.2,0
    c-3.3,0-5.5,2.4-5.8,5.8c0,4,0,7.6,0,11.6s0,7.9,0,11.9C81.5,81.6,83.7,83.5,87.3,83.8z"/>
  <path className="integrately-st2" d="M125.4,83.8c7,0,9.7-1.2,9.7-5.5c0-8.2,0-16.1,0-24.4c0-3-2.4-4.9-5.5-5.2c-1.5,0-3.3,0-4.9,0
    c-3.7,0-5.8,2.1-5.8,5.8s0,7.6,0,11.3c0,4,0,7.9,0,12.2C119.3,81.6,121.4,83.8,125.4,83.8z"/>
</svg>
