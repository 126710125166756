import * as React from 'react'
import './eye-open.css'


export const EyeOpenIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
    <g id="Group_6190" data-name="Group 6190" transform="translate(-1214 -304)">
        <rect id="Rectangle_2242" width="33" height="33"
        className="eye-open-1" data-name="Rectangle 2242" rx="5" transform="translate(1214 304)"/>
        <g id="Group_6146" data-name="Group 6146" transform="translate(1217.925 314.525)">
            <path id="Path_12118" d="M248.283 375.241c-6.563 0-11.772 5.554-11.991 5.79a.514.514 0 0 0 0 .7c.218.237 5.428 5.791 11.991 5.791s11.772-5.554 11.991-5.791a.514.514 0 0 0 0-.7c-.219-.231-5.428-5.79-11.991-5.79zm-4.773 6.139a4.773 4.773 0 1 1 4.773 4.773 4.778 4.778 0 0 1-4.773-4.773zm1.607-4.651a5.624 5.624 0 0 0 0 9.3 19.79 19.79 0 0 1-7.721-4.65 19.723 19.723 0 0 1 7.721-4.65zm6.335 9.3a5.623 5.623 0 0 0 0-9.3 19.783 19.783 0 0 1 7.72 4.649 19.782 19.782 0 0 1-7.721 4.652z" className="eye-open-2" data-name="Path 12118" transform="translate(-236.156 -375.241)"/>
            <path id="Path_12119" d="M272.175 386.182a3.151 3.151 0 1 0 3.151 3.151 3.151 3.151 0 0 0-3.151-3.151zm1.011 3.151a1.011 1.011 0 1 1 1.011-1.011 1.011 1.011 0 0 1-1.011 1.011z" className="eye-open-2" data-name="Path 12119" transform="translate(-260.048 -383.194)"/>
        </g>
    </g>
</svg>
