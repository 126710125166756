import * as React from 'react'
import './link-out.css'

export const LinkOutIcon = (props: any) =>
<svg id="interface_1_" data-name="interface (1)" xmlns="http://www.w3.org/2000/svg"
width={props.width || '15.18'} height={props.height || '15.18'} viewBox="0 0 15.18 15.18">
  <g id="Group_6615" data-name="Group 6615" transform="translate(5.52)">
    <g id="Group_6614" data-name="Group 6614">
      <path id="Path_23829" data-name="Path 23829" className="linkout-1" d="M195.153,0h-5.52a.69.69,0,1,0,0,1.38h3.854l-7.1,7.1a.69.69,0,1,0,.976.976l7.1-7.1V6.21a.69.69,0,1,0,1.38,0V.69A.69.69,0,0,0,195.153,0Z" transform="translate(-186.184)"/>
    </g>
  </g>
  <g id="Group_6617" data-name="Group 6617" transform="translate(0 2.76)">
    <g id="Group_6616" data-name="Group 6616">
      <path id="Path_23830" data-name="Path 23830" className="linkout-1" d="M11.73,97.231a.69.69,0,0,0-.69.69v6.21H1.38v-9.66H7.59a.69.69,0,1,0,0-1.38H.69a.69.69,0,0,0-.69.69v11.04a.69.69,0,0,0,.69.69H11.73a.69.69,0,0,0,.69-.69v-6.9A.69.69,0,0,0,11.73,97.231Z" transform="translate(0 -93.091)"/>
    </g>
  </g>
</svg>
