import * as React from 'react'
import './sources.css'

export const NimbleIcon = (props: any) =>
<svg className={`system-icon ${props.className}`} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		 height={props.width || '60px'} viewBox="0 0 1264 623" enable-background="new 0 0 1264 623">
<path fill="#FDFEFF" opacity="1.000000" stroke="none"
	d="
M875.000000,624.000000
	C583.371887,624.000000 292.243744,624.000000 1.057818,624.000000
	C1.057818,416.399384 1.057818,208.798752 1.057818,1.099055
	C422.211487,1.099055 843.422974,1.099055 1264.817261,1.099055
	C1264.817261,208.666534 1264.817261,416.333252 1264.817261,624.000000
	C1135.131958,624.000000 1005.315979,624.000000 875.000000,624.000000
M20.840391,389.500000
	C20.840422,442.664215 20.792604,495.828522 20.857893,548.992676
	C20.899025,582.485535 47.454330,608.995911 80.795784,608.996094
	C448.952850,608.998352 817.109924,608.998169 1185.266968,608.996399
	C1218.519897,608.996216 1245.380371,582.809753 1245.403442,549.696533
	C1245.513550,391.534088 1245.459106,233.371521 1245.439209,75.208992
	C1245.437866,63.949501 1242.461914,53.441578 1236.299438,44.026443
	C1223.453369,24.400093 1205.010498,15.774772 1181.724365,15.780233
	C815.900574,15.866027 450.076782,15.840416 84.252991,15.840498
	C83.419678,15.840498 82.586090,15.827740 81.753090,15.844346
	C45.990959,16.557280 20.841675,42.220840 20.841045,78.008102
	C20.839224,181.505402 20.840368,285.002716 20.840391,389.500000
z"/>
<path fill="#0070BA" opacity="1.000000" stroke="none"
	d="
M20.840368,389.000000
	C20.840368,285.002716 20.839224,181.505402 20.841045,78.008102
	C20.841675,42.220840 45.990959,16.557280 81.753090,15.844346
	C82.586090,15.827740 83.419678,15.840498 84.252991,15.840498
	C450.076782,15.840416 815.900574,15.866027 1181.724365,15.780233
	C1205.010498,15.774772 1223.453369,24.400093 1236.299438,44.026443
	C1242.461914,53.441578 1245.437866,63.949501 1245.439209,75.208992
	C1245.459106,233.371521 1245.513550,391.534088 1245.403442,549.696533
	C1245.380371,582.809753 1218.519897,608.996216 1185.266968,608.996399
	C817.109924,608.998169 448.952850,608.998352 80.795784,608.996094
	C47.454330,608.995911 20.899025,582.485535 20.857893,548.992676
	C20.792604,495.828522 20.840422,442.664215 20.840368,389.000000
M314.241913,335.788208
	C316.299072,332.873108 318.356201,329.958008 320.627106,326.739960
	C321.943176,328.535645 322.934692,329.853577 323.890015,331.197266
	C336.915466,349.517670 350.119171,367.715973 362.831787,386.251038
	C366.108704,391.028778 369.790344,391.748535 374.749725,390.778381
	C387.293945,388.324432 395.898193,380.929474 401.694885,369.782654
	C403.451233,366.405273 403.198303,363.606873 400.951904,360.556488
	C395.917328,353.720001 391.092438,346.729034 386.188171,339.796722
	C376.783905,326.503418 367.383789,313.207184 357.760529,299.599060
	C359.368622,298.931549 360.551270,298.423309 361.746643,297.947083
	C379.350769,290.934143 396.948181,283.904144 414.569550,276.934875
	C417.820892,275.648956 420.627258,274.178467 420.848206,270.107605
	C421.571564,256.780457 418.185730,245.138687 407.804474,235.969635
	C404.465607,233.020630 401.459076,232.500397 397.405548,234.164368
	C380.947754,240.920166 364.399689,247.455704 347.896271,254.100708
	C342.891693,256.115784 341.174072,259.037476 342.726746,262.672058
	C344.305115,266.366791 347.277985,267.182953 352.467987,265.126770
	C366.226501,259.675873 379.959991,254.161789 393.700653,248.665878
	C401.367462,245.599350 403.818176,246.596786 406.904388,254.455597
	C407.806335,256.752350 408.099304,259.284424 408.722412,261.695465
	C409.581726,265.020813 408.342316,266.629974 405.055603,267.902557
	C386.147034,275.223633 367.336731,282.799255 348.508850,290.327881
	C341.568542,293.103058 340.706604,296.278625 345.098022,302.497589
	C350.476959,310.115021 355.871826,317.721313 361.258118,325.333557
	C370.855804,338.897644 380.452271,352.462555 389.951630,365.889191
	C384.933807,374.192902 381.567352,376.674774 372.563995,378.808929
	C371.535583,377.412079 370.435883,375.963898 369.383453,374.482117
	C355.210083,354.525848 341.059937,334.553009 326.864624,314.612366
	C322.625061,308.656952 318.571289,308.677917 314.390137,314.630157
	C306.829254,325.393860 299.323364,336.196167 291.779938,346.972168
	C284.346069,357.591736 276.894135,368.198700 268.638031,379.968933
	C263.916504,376.775360 259.359894,373.939026 255.087921,370.724487
	C251.797714,368.248718 251.761749,365.602356 254.402237,361.942261
	C268.623932,342.228973 282.520203,322.280762 296.512512,302.402161
	C300.785400,296.331757 299.754730,292.908661 292.931061,290.223389
	C275.604370,283.404846 258.270569,276.604187 240.958527,269.748596
	C232.054123,266.222412 231.200562,264.026367 235.286987,255.217224
	C236.119476,253.422592 237.348999,251.812759 238.387726,250.113007
	C240.219345,247.115707 242.319809,246.475525 245.848175,247.944168
	C260.424408,254.011368 275.132751,259.764221 289.838593,265.514740
	C293.388702,266.902985 297.070618,266.871826 298.638031,262.647095
	C300.094360,258.721741 298.210785,255.968491 294.340149,254.434555
	C277.498901,247.760376 260.728577,240.900909 243.786209,234.495743
	C241.615982,233.675262 237.892471,234.236954 236.071838,235.665955
	C225.239929,244.167953 220.302307,255.529449 221.194473,269.346588
	C221.444412,273.217468 223.161453,275.699921 226.996979,277.133667
	C237.115082,280.915924 247.115845,285.012482 257.158936,288.994995
	C265.878479,292.452667 274.592010,295.925476 283.683716,299.540955
	C282.416534,301.435425 281.531921,302.817932 280.588623,304.159210
	C267.370117,322.954407 254.195541,341.780975 240.872345,360.501709
	C238.406906,363.965973 238.382462,367.025604 240.371292,370.647156
	C246.361740,381.555511 255.429565,388.176147 267.531952,390.773163
	C272.311646,391.798828 275.917236,390.997314 279.024414,386.400848
	C290.400085,369.572662 302.217957,353.043396 314.241913,335.788208
M644.417358,285.499939
	C644.417358,320.108826 644.417358,354.717712 644.417358,389.508301
	C649.396484,389.508301 653.761902,389.508301 658.582764,389.508301
	C658.582764,387.365265 658.582764,385.559723 658.582764,383.754181
	C658.582764,361.595673 658.534180,339.436981 658.603638,317.278687
	C658.637329,306.540924 659.893921,296.000732 665.027771,286.312225
	C670.774658,275.466858 680.991028,270.495392 693.925781,272.150177
	C705.335754,273.609924 712.541443,280.436401 714.946350,292.966553
	C716.099426,298.974701 716.648499,305.181488 716.696960,311.304382
	C716.889160,335.627289 716.776001,359.952545 716.776001,384.276947
	C716.776001,386.044403 716.776001,387.811829 716.776001,389.497284
	C721.682007,389.497284 725.918884,389.497284 730.582764,389.497284
	C730.582764,387.334991 730.582764,385.530853 730.582764,383.726685
	C730.582703,361.568146 730.485596,339.408936 730.648621,317.251587
	C730.691223,311.452087 731.161804,305.596497 732.095154,299.874176
	C734.610352,284.453735 742.913208,275.130798 755.952271,272.459442
	C772.926575,268.981873 785.297302,276.993622 787.846802,293.291412
	C788.461548,297.221222 788.725159,301.243195 788.736694,305.223999
	C788.812744,331.547455 788.776001,357.871185 788.776001,384.194855
	C788.776001,385.977753 788.776001,387.760651 788.776001,389.423706
	C793.782593,389.423706 798.176025,389.423706 802.643433,389.423706
	C802.733093,388.009888 802.849976,387.023468 802.850342,386.037018
	C802.860229,360.046570 802.984375,334.055084 802.770325,308.066406
	C802.715820,301.444000 802.069458,294.738281 800.839905,288.232422
	C798.403687,275.341339 791.989319,265.163208 778.718506,261.125031
	C760.356934,255.537811 737.680481,259.062561 726.487854,279.127319
	C726.297485,279.468567 725.792908,279.634491 725.418152,279.893829
	C713.811829,256.280609 678.071045,249.963104 658.840149,274.137390
	C658.640442,269.687988 658.461304,265.696503 658.285339,261.776215
	C653.421753,261.776215 649.175537,261.776215 644.417603,261.776215
	C644.417603,269.582703 644.417603,277.041351 644.417358,285.499939
M825.827271,263.500000
	C825.827271,305.582397 825.827271,347.664795 825.827271,389.741699
	C830.821838,389.741699 835.183105,389.741699 840.124390,389.741699
	C840.124390,383.001953 840.124390,376.677826 840.124390,369.620789
	C861.233337,397.484039 903.494385,400.513306 928.858337,377.942169
	C946.209717,362.501434 952.014465,342.392303 950.891846,319.860046
	C948.826172,278.399384 914.047058,251.918137 873.556702,260.920471
	C860.172913,263.896149 849.204468,270.918182 839.627319,282.644562
	C839.627319,250.571899 839.627319,219.907028 839.627319,189.311798
	C834.726196,189.311798 830.472961,189.311798 825.827209,189.311798
	C825.827209,213.919312 825.827209,238.209656 825.827271,263.500000
M1070.500000,327.175018
	C1086.399292,327.175018 1102.298584,327.175018 1119.146240,327.175018
	C1117.976562,319.217896 1117.616699,312.012665 1115.788208,305.201630
	C1109.754517,282.726135 1096.421753,266.469360 1073.239258,260.617004
	C1050.073120,254.768723 1029.091675,259.783661 1012.461853,277.532623
	C995.691223,295.432007 991.608948,317.293671 996.154053,340.718750
	C1000.731812,364.312317 1013.796936,381.773254 1037.328857,389.032776
	C1060.973877,396.327209 1082.919800,392.049622 1101.302124,374.848938
	C1106.603394,369.888275 1110.465576,363.389496 1115.187500,357.339874
	C1112.119263,355.450134 1110.319946,354.274475 1108.455811,353.213074
	C1106.642700,352.180725 1104.763062,351.265411 1102.941528,350.313843
	C1085.177368,380.838501 1058.421753,383.287384 1038.273315,375.202454
	C1019.213501,367.554321 1005.787048,345.462524 1009.245850,327.175079
	C1029.262695,327.175079 1049.381348,327.175079 1070.500000,327.175018
M566.366028,292.482849
	C566.907898,298.099365 567.879272,303.712952 567.917664,309.332916
	C568.088257,334.318878 567.991638,359.306671 567.991638,384.293823
	C567.991638,386.070343 567.991638,387.846863 567.991638,389.811554
	C573.315918,389.811554 578.093506,389.811554 583.178040,389.811554
	C583.313782,388.426056 583.549500,387.135681 583.550598,385.845123
	C583.572327,360.524811 583.665710,335.203857 583.491882,309.884735
	C583.452148,304.088776 582.877380,298.225891 581.842834,292.521851
	C579.582947,280.061829 574.278870,269.420563 562.168213,263.593079
	C544.863892,255.266464 511.436768,254.747025 494.896515,277.311340
	C494.672974,277.616364 494.146973,277.699677 493.128601,278.193909
	C493.128601,272.359711 493.128601,266.976562 493.128601,261.417267
	C487.840240,261.417267 483.103790,261.417267 478.208618,261.417267
	C478.208618,304.188538 478.208618,346.693298 478.208618,389.232117
	C483.341461,389.232117 488.086884,389.232117 493.173950,389.232117
	C493.173950,386.917908 493.154968,384.936493 493.176819,382.955536
	C493.437622,359.315125 493.382843,335.664825 494.112640,312.038849
	C494.450531,301.100983 497.435425,290.503448 505.383148,282.342224
	C516.990540,270.422974 531.589600,269.887115 546.698242,272.724548
	C557.220398,274.700623 563.764648,281.156433 566.366028,292.482849
M965.774231,346.499542
	C965.774231,360.804810 965.774231,375.110077 965.774231,389.615479
	C970.481140,389.615479 974.729065,389.615479 979.280701,389.615479
	C979.280701,322.657043 979.280701,255.943253 979.280701,189.156464
	C974.638489,189.156464 970.268677,189.156464 965.774597,189.156464
	C965.774597,241.525635 965.774597,293.512573 965.774231,346.499542
M620.228699,380.396576
	C620.228699,340.835571 620.228699,301.274536 620.228699,261.846497
	C615.210388,261.846497 610.983337,261.846497 606.758484,261.846497
	C606.758484,304.686554 606.758484,347.090240 606.758484,389.574463
	C611.352234,389.574463 615.592712,389.574463 620.228271,389.574463
	C620.228271,386.645813 620.228271,384.001648 620.228699,380.396576
M168.219635,245.153778
	C169.758606,245.779617 171.273376,246.821274 172.843475,246.912674
	C174.370132,247.001541 175.953476,246.116486 177.511932,245.659073
	C176.712616,244.257339 176.252258,242.195724 175.050125,241.578247
	C171.518723,239.764313 167.762878,238.279694 163.946426,237.182831
	C162.479904,236.761337 160.163284,237.193298 158.998230,238.158676
	C149.262253,246.226105 144.354645,256.538696 145.903549,269.309265
	C146.120895,271.101349 147.987213,273.395691 149.681717,274.180573
	C155.852356,277.038818 162.269547,279.366425 168.602005,281.873291
	C182.176422,287.247040 195.755966,292.607880 209.705017,298.120880
	C208.537079,299.888184 207.674026,301.269073 206.738327,302.598816
	C193.141754,321.921051 179.642349,341.313354 165.841171,360.488464
	C163.086639,364.315521 162.479172,367.407745 165.495300,371.084778
	C167.390289,373.394958 168.943344,376.018341 171.016678,378.143219
	C176.949402,384.223328 184.369308,387.385986 192.756378,388.079346
	C193.910858,388.174805 195.205627,386.573181 196.435455,385.757233
	C195.460770,384.643097 194.622437,383.345367 193.460907,382.482788
	C192.748459,381.953674 191.529144,382.142822 190.557556,381.921234
	C182.994949,380.196686 176.706619,376.544708 172.037018,370.162567
	C170.012650,367.395782 169.714279,365.385010 171.927826,362.300720
	C186.187683,342.431305 200.187592,322.375244 214.253876,302.367126
	C219.059921,295.530914 218.943939,295.257904 210.984787,292.114655
	C192.443436,284.792175 173.953232,277.335907 155.326233,270.238525
	C152.087845,269.004608 151.458054,267.315369 151.566238,264.350067
	C151.807007,257.750732 154.289001,252.156509 158.347900,247.028305
	C160.977509,243.705933 163.676849,242.276016 168.219635,245.153778
M225.271835,330.737061
	C218.662445,340.121368 212.052200,349.505066 205.443954,358.890198
	C200.369186,366.097382 200.394257,366.385162 205.549377,373.621765
	C211.722275,382.287170 220.278305,386.891235 230.675995,388.072266
	C231.940659,388.215912 233.411667,386.542694 234.787064,385.711548
	C233.776138,384.617554 232.892746,383.345825 231.707214,382.495178
	C230.977768,381.971771 229.774384,382.144043 228.805008,381.914459
	C221.272690,380.130585 214.952911,376.562012 210.338974,370.088379
	C208.335342,367.277161 208.146729,365.295715 210.294250,362.295868
	C224.819611,342.005798 239.116760,321.552216 253.470062,301.139130
	C257.166870,295.881592 256.941925,295.199646 250.761215,292.747955
	C231.612366,285.152222 212.476868,277.521698 193.276215,270.058960
	C190.687897,269.052948 189.700226,267.830292 189.809586,265.022736
	C190.117310,257.123291 193.065720,250.435104 198.748199,245.086990
	C199.798080,244.098923 202.216293,243.845078 203.747650,244.240646
	C206.775513,245.022766 209.555374,246.763397 212.582672,247.550247
	C213.782715,247.862183 215.368408,246.690460 216.778519,246.194244
	C216.131348,244.847366 215.830002,242.793930 214.776077,242.278107
	C210.611450,240.239685 206.282211,238.451767 201.854935,237.087082
	C200.497757,236.668732 198.260757,237.451904 197.058105,238.447571
	C187.733871,246.167023 183.345383,256.106934 183.920013,268.293243
	C184.074692,271.573669 185.226089,273.373108 188.363953,274.557281
	C198.932022,278.545410 209.400818,282.796997 219.902115,286.961548
	C229.231155,290.661194 238.552933,294.379150 248.184082,298.210846
	C240.439331,309.221405 233.073639,319.693115 225.271835,330.737061
M306.748810,190.182251
	C302.735931,192.136063 298.299530,193.533844 298.375061,199.395844
	C298.516632,210.378860 298.411591,221.365005 298.418945,232.349838
	C298.421692,236.410400 300.016296,239.244980 304.483734,239.174484
	C308.989929,239.103394 310.303223,236.198349 310.251282,232.127060
	C310.160034,224.971420 310.226044,217.813797 310.226074,210.656998
	C310.226135,200.527634 311.978790,198.926071 322.388672,199.750443
	C324.363220,199.906815 326.305908,200.431534 328.274017,200.710678
	C331.493256,201.167313 332.565613,202.908340 332.485901,206.181000
	C332.275177,214.830582 332.444489,223.488846 332.403107,232.143372
	C332.383484,236.251389 333.982666,239.116959 338.380005,239.074387
	C342.768860,239.031891 344.266815,236.130692 344.243683,232.038132
	C344.183533,221.386520 344.103760,210.732224 344.287964,200.083450
	C344.355316,196.190521 342.725098,193.893738 339.401123,192.248108
	C329.057587,187.127213 318.448425,186.248367 306.748810,190.182251
M270.671600,192.029358
	C269.727844,192.349426 268.794983,192.707367 267.838470,192.983215
	C262.886261,194.411285 260.637329,197.244507 260.996796,202.816803
	C261.521210,210.945770 261.040253,219.135345 261.185699,227.295547
	C261.230835,229.828629 259.796295,233.865677 264.231415,233.831528
	C268.330597,233.799973 266.927551,229.952728 266.969635,227.574509
	C267.111053,219.580795 267.194244,211.577560 266.938202,203.589554
	C266.837952,200.461349 267.935852,198.682785 270.814789,198.341568
	C275.883514,197.740814 281.010773,197.620575 286.071014,196.968979
	C287.707855,196.758194 289.211426,195.512772 290.775970,194.740631
	C289.464661,193.516083 288.186462,191.289749 286.836151,191.245117
	C281.727509,191.076218 276.595428,191.615021 270.671600,192.029358
M229.018616,210.540176
	C229.018616,208.709137 229.300003,206.824783 228.965179,205.057159
	C228.011765,200.023727 230.721542,197.911926 235.053574,197.484848
	C239.484833,197.048019 243.985489,197.322449 248.419662,196.903519
	C249.914505,196.762299 251.298859,195.451538 252.733719,194.675308
	C251.583069,193.478455 250.588684,191.475098 249.256165,191.216919
	C241.196411,189.655258 233.298767,190.499451 226.142181,194.724091
	C224.628708,195.617508 223.181259,198.003403 223.122803,199.749146
	C222.822189,208.727814 222.846603,217.726273 223.125488,226.706390
	C223.170258,228.147842 225.074097,229.531555 226.118500,230.941971
	C227.084625,229.464828 228.773163,228.039658 228.883850,226.500977
	C229.241226,221.532883 229.018051,216.523041 229.018616,210.540176
z"/>
<path fill="#F9FCFE" opacity="1.000000" stroke="none"
	d="
M314.055603,336.094910
	C302.217957,353.043396 290.400085,369.572662 279.024414,386.400848
	C275.917236,390.997314 272.311646,391.798828 267.531952,390.773163
	C255.429565,388.176147 246.361740,381.555511 240.371292,370.647156
	C238.382462,367.025604 238.406906,363.965973 240.872345,360.501709
	C254.195541,341.780975 267.370117,322.954407 280.588623,304.159210
	C281.531921,302.817932 282.416534,301.435425 283.683716,299.540955
	C274.592010,295.925476 265.878479,292.452667 257.158936,288.994995
	C247.115845,285.012482 237.115082,280.915924 226.996979,277.133667
	C223.161453,275.699921 221.444412,273.217468 221.194473,269.346588
	C220.302307,255.529449 225.239929,244.167953 236.071838,235.665955
	C237.892471,234.236954 241.615982,233.675262 243.786209,234.495743
	C260.728577,240.900909 277.498901,247.760376 294.340149,254.434555
	C298.210785,255.968491 300.094360,258.721741 298.638031,262.647095
	C297.070618,266.871826 293.388702,266.902985 289.838593,265.514740
	C275.132751,259.764221 260.424408,254.011368 245.848175,247.944168
	C242.319809,246.475525 240.219345,247.115707 238.387726,250.113007
	C237.348999,251.812759 236.119476,253.422592 235.286987,255.217224
	C231.200562,264.026367 232.054123,266.222412 240.958527,269.748596
	C258.270569,276.604187 275.604370,283.404846 292.931061,290.223389
	C299.754730,292.908661 300.785400,296.331757 296.512512,302.402161
	C282.520203,322.280762 268.623932,342.228973 254.402237,361.942261
	C251.761749,365.602356 251.797714,368.248718 255.087921,370.724487
	C259.359894,373.939026 263.916504,376.775360 268.638031,379.968933
	C276.894135,368.198700 284.346069,357.591736 291.779938,346.972168
	C299.323364,336.196167 306.829254,325.393860 314.390137,314.630157
	C318.571289,308.677917 322.625061,308.656952 326.864624,314.612366
	C341.059937,334.553009 355.210083,354.525848 369.383453,374.482117
	C370.435883,375.963898 371.535583,377.412079 372.563995,378.808929
	C381.567352,376.674774 384.933807,374.192902 389.951630,365.889191
	C380.452271,352.462555 370.855804,338.897644 361.258118,325.333557
	C355.871826,317.721313 350.476959,310.115021 345.098022,302.497589
	C340.706604,296.278625 341.568542,293.103058 348.508850,290.327881
	C367.336731,282.799255 386.147034,275.223633 405.055603,267.902557
	C408.342316,266.629974 409.581726,265.020813 408.722412,261.695465
	C408.099304,259.284424 407.806335,256.752350 406.904388,254.455597
	C403.818176,246.596786 401.367462,245.599350 393.700653,248.665878
	C379.959991,254.161789 366.226501,259.675873 352.467987,265.126770
	C347.277985,267.182953 344.305115,266.366791 342.726746,262.672058
	C341.174072,259.037476 342.891693,256.115784 347.896271,254.100708
	C364.399689,247.455704 380.947754,240.920166 397.405548,234.164368
	C401.459076,232.500397 404.465607,233.020630 407.804474,235.969635
	C418.185730,245.138687 421.571564,256.780457 420.848206,270.107605
	C420.627258,274.178467 417.820892,275.648956 414.569550,276.934875
	C396.948181,283.904144 379.350769,290.934143 361.746643,297.947083
	C360.551270,298.423309 359.368622,298.931549 357.760529,299.599060
	C367.383789,313.207184 376.783905,326.503418 386.188171,339.796722
	C391.092438,346.729034 395.917328,353.720001 400.951904,360.556488
	C403.198303,363.606873 403.451233,366.405273 401.694885,369.782654
	C395.898193,380.929474 387.293945,388.324432 374.749725,390.778381
	C369.790344,391.748535 366.108704,391.028778 362.831787,386.251038
	C350.119171,367.715973 336.915466,349.517670 323.890015,331.197266
	C322.934692,329.853577 321.943176,328.535645 320.627106,326.739960
	C318.356201,329.958008 316.299072,332.873108 314.055603,336.094910
z"/>
<path fill="#F6FAFD" opacity="1.000000" stroke="none"
	d="
M644.417480,284.999969
	C644.417603,277.041351 644.417603,269.582703 644.417603,261.776215
	C649.175537,261.776215 653.421753,261.776215 658.285339,261.776215
	C658.461304,265.696503 658.640442,269.687988 658.840149,274.137390
	C678.071045,249.963104 713.811829,256.280609 725.418152,279.893829
	C725.792908,279.634491 726.297485,279.468567 726.487854,279.127319
	C737.680481,259.062561 760.356934,255.537811 778.718506,261.125031
	C791.989319,265.163208 798.403687,275.341339 800.839905,288.232422
	C802.069458,294.738281 802.715820,301.444000 802.770325,308.066406
	C802.984375,334.055084 802.860229,360.046570 802.850342,386.037018
	C802.849976,387.023468 802.733093,388.009888 802.643433,389.423706
	C798.176025,389.423706 793.782593,389.423706 788.776001,389.423706
	C788.776001,387.760651 788.776001,385.977753 788.776001,384.194855
	C788.776001,357.871185 788.812744,331.547455 788.736694,305.223999
	C788.725159,301.243195 788.461548,297.221222 787.846802,293.291412
	C785.297302,276.993622 772.926575,268.981873 755.952271,272.459442
	C742.913208,275.130798 734.610352,284.453735 732.095154,299.874176
	C731.161804,305.596497 730.691223,311.452087 730.648621,317.251587
	C730.485596,339.408936 730.582703,361.568146 730.582764,383.726685
	C730.582764,385.530853 730.582764,387.334991 730.582764,389.497284
	C725.918884,389.497284 721.682007,389.497284 716.776001,389.497284
	C716.776001,387.811829 716.776001,386.044403 716.776001,384.276947
	C716.776001,359.952545 716.889160,335.627289 716.696960,311.304382
	C716.648499,305.181488 716.099426,298.974701 714.946350,292.966553
	C712.541443,280.436401 705.335754,273.609924 693.925781,272.150177
	C680.991028,270.495392 670.774658,275.466858 665.027771,286.312225
	C659.893921,296.000732 658.637329,306.540924 658.603638,317.278687
	C658.534180,339.436981 658.582764,361.595673 658.582764,383.754181
	C658.582764,385.559723 658.582764,387.365265 658.582764,389.508301
	C653.761902,389.508301 649.396484,389.508301 644.417358,389.508301
	C644.417358,354.717712 644.417358,320.108826 644.417480,284.999969
z"/>
<path fill="#FBFDFE" opacity="1.000000" stroke="none"
	d="
M825.827271,263.000000
	C825.827209,238.209656 825.827209,213.919312 825.827209,189.311798
	C830.472961,189.311798 834.726196,189.311798 839.627319,189.311798
	C839.627319,219.907028 839.627319,250.571899 839.627319,282.644562
	C849.204468,270.918182 860.172913,263.896149 873.556702,260.920471
	C914.047058,251.918137 948.826172,278.399384 950.891846,319.860046
	C952.014465,342.392303 946.209717,362.501434 928.858337,377.942169
	C903.494385,400.513306 861.233337,397.484039 840.124390,369.620789
	C840.124390,376.677826 840.124390,383.001953 840.124390,389.741699
	C835.183105,389.741699 830.821838,389.741699 825.827271,389.741699
	C825.827271,347.664795 825.827271,305.582397 825.827271,263.000000
M851.568359,288.108765
	C838.373413,304.509521 836.090698,323.202179 841.346558,342.804413
	C850.781433,377.992584 891.159973,391.967651 917.808960,369.135162
	C934.638855,354.715485 940.065247,335.440887 935.805603,314.033630
	C931.986206,294.839050 921.196838,280.151978 901.883911,274.408783
	C883.108398,268.825378 866.032837,273.241089 851.568359,288.108765
z"/>
<path fill="#FAFCFE" opacity="1.000000" stroke="none"
	d="
M1070.000000,327.175049
	C1049.381348,327.175079 1029.262695,327.175079 1009.245850,327.175079
	C1005.787048,345.462524 1019.213501,367.554321 1038.273315,375.202454
	C1058.421753,383.287384 1085.177368,380.838501 1102.941528,350.313843
	C1104.763062,351.265411 1106.642700,352.180725 1108.455811,353.213074
	C1110.319946,354.274475 1112.119263,355.450134 1115.187500,357.339874
	C1110.465576,363.389496 1106.603394,369.888275 1101.302124,374.848938
	C1082.919800,392.049622 1060.973877,396.327209 1037.328857,389.032776
	C1013.796936,381.773254 1000.731812,364.312317 996.154053,340.718750
	C991.608948,317.293671 995.691223,295.432007 1012.461853,277.532623
	C1029.091675,259.783661 1050.073120,254.768723 1073.239258,260.617004
	C1096.421753,266.469360 1109.754517,282.726135 1115.788208,305.201630
	C1117.616699,312.012665 1117.976562,319.217896 1119.146240,327.175018
	C1102.298584,327.175018 1086.399292,327.175018 1070.000000,327.175049
M1009.016724,310.958740
	C1008.064026,315.093689 1011.102173,314.131226 1013.196472,314.133209
	C1042.312744,314.161102 1071.428955,314.155426 1100.545166,314.137970
	C1101.662720,314.137299 1102.780273,313.935272 1103.884155,313.828400
	C1103.056396,294.222046 1083.163696,273.963165 1063.004272,271.959442
	C1037.394043,269.413971 1016.355164,284.307281 1009.016724,310.958740
z"/>
<path fill="#FAFCFE" opacity="1.000000" stroke="none"
	d="
M566.258362,292.081909
	C563.764648,281.156433 557.220398,274.700623 546.698242,272.724548
	C531.589600,269.887115 516.990540,270.422974 505.383148,282.342224
	C497.435425,290.503448 494.450531,301.100983 494.112640,312.038849
	C493.382843,335.664825 493.437622,359.315125 493.176819,382.955536
	C493.154968,384.936493 493.173950,386.917908 493.173950,389.232117
	C488.086884,389.232117 483.341461,389.232117 478.208618,389.232117
	C478.208618,346.693298 478.208618,304.188538 478.208618,261.417267
	C483.103790,261.417267 487.840240,261.417267 493.128601,261.417267
	C493.128601,266.976562 493.128601,272.359711 493.128601,278.193909
	C494.146973,277.699677 494.672974,277.616364 494.896515,277.311340
	C511.436768,254.747025 544.863892,255.266464 562.168213,263.593079
	C574.278870,269.420563 579.582947,280.061829 581.842834,292.521851
	C582.877380,298.225891 583.452148,304.088776 583.491882,309.884735
	C583.665710,335.203857 583.572327,360.524811 583.550598,385.845123
	C583.549500,387.135681 583.313782,388.426056 583.178040,389.811554
	C578.093506,389.811554 573.315918,389.811554 567.991638,389.811554
	C567.991638,387.846863 567.991638,386.070343 567.991638,384.293823
	C567.991638,359.306671 568.088257,334.318878 567.917664,309.332916
	C567.879272,303.712952 566.907898,298.099365 566.258362,292.081909
z"/>
<path fill="#F6FAFD" opacity="1.000000" stroke="none"
	d="
M965.774414,345.999512
	C965.774597,293.512573 965.774597,241.525635 965.774597,189.156464
	C970.268677,189.156464 974.638489,189.156464 979.280701,189.156464
	C979.280701,255.943253 979.280701,322.657043 979.280701,389.615479
	C974.729065,389.615479 970.481140,389.615479 965.774231,389.615479
	C965.774231,375.110077 965.774231,360.804810 965.774414,345.999512
z"/>
<path fill="#F5F9FC" opacity="1.000000" stroke="none"
	d="
M620.228516,380.877014
	C620.228271,384.001648 620.228271,386.645813 620.228271,389.574463
	C615.592712,389.574463 611.352234,389.574463 606.758484,389.574463
	C606.758484,347.090240 606.758484,304.686554 606.758484,261.846497
	C610.983337,261.846497 615.210388,261.846497 620.228699,261.846497
	C620.228699,301.274536 620.228699,340.835571 620.228516,380.877014
z"/>
<path fill="#EBA52B" opacity="1.000000" stroke="none"
	d="
M167.858810,245.048553
	C163.676849,242.276016 160.977509,243.705933 158.347900,247.028305
	C154.289001,252.156509 151.807007,257.750732 151.566238,264.350067
	C151.458054,267.315369 152.087845,269.004608 155.326233,270.238525
	C173.953232,277.335907 192.443436,284.792175 210.984787,292.114655
	C218.943939,295.257904 219.059921,295.530914 214.253876,302.367126
	C200.187592,322.375244 186.187683,342.431305 171.927826,362.300720
	C169.714279,365.385010 170.012650,367.395782 172.037018,370.162567
	C176.706619,376.544708 182.994949,380.196686 190.557556,381.921234
	C191.529144,382.142822 192.748459,381.953674 193.460907,382.482788
	C194.622437,383.345367 195.460770,384.643097 196.435455,385.757233
	C195.205627,386.573181 193.910858,388.174805 192.756378,388.079346
	C184.369308,387.385986 176.949402,384.223328 171.016678,378.143219
	C168.943344,376.018341 167.390289,373.394958 165.495300,371.084778
	C162.479172,367.407745 163.086639,364.315521 165.841171,360.488464
	C179.642349,341.313354 193.141754,321.921051 206.738327,302.598816
	C207.674026,301.269073 208.537079,299.888184 209.705017,298.120880
	C195.755966,292.607880 182.176422,287.247040 168.602005,281.873291
	C162.269547,279.366425 155.852356,277.038818 149.681717,274.180573
	C147.987213,273.395691 146.120895,271.101349 145.903549,269.309265
	C144.354645,256.538696 149.262253,246.226105 158.998230,238.158676
	C160.163284,237.193298 162.479904,236.761337 163.946426,237.182831
	C167.762878,238.279694 171.518723,239.764313 175.050125,241.578247
	C176.252258,242.195724 176.712616,244.257339 177.511932,245.659073
	C175.953476,246.116486 174.370132,247.001541 172.843475,246.912674
	C171.273376,246.821274 169.758606,245.779617 167.858810,245.048553
z"/>
<path fill="#ECA62B" opacity="1.000000" stroke="none"
	d="
M225.489883,330.450928
	C233.073639,319.693115 240.439331,309.221405 248.184082,298.210846
	C238.552933,294.379150 229.231155,290.661194 219.902115,286.961548
	C209.400818,282.796997 198.932022,278.545410 188.363953,274.557281
	C185.226089,273.373108 184.074692,271.573669 183.920013,268.293243
	C183.345383,256.106934 187.733871,246.167023 197.058105,238.447571
	C198.260757,237.451904 200.497757,236.668732 201.854935,237.087082
	C206.282211,238.451767 210.611450,240.239685 214.776077,242.278107
	C215.830002,242.793930 216.131348,244.847366 216.778519,246.194244
	C215.368408,246.690460 213.782715,247.862183 212.582672,247.550247
	C209.555374,246.763397 206.775513,245.022766 203.747650,244.240646
	C202.216293,243.845078 199.798080,244.098923 198.748199,245.086990
	C193.065720,250.435104 190.117310,257.123291 189.809586,265.022736
	C189.700226,267.830292 190.687897,269.052948 193.276215,270.058960
	C212.476868,277.521698 231.612366,285.152222 250.761215,292.747955
	C256.941925,295.199646 257.166870,295.881592 253.470062,301.139130
	C239.116760,321.552216 224.819611,342.005798 210.294250,362.295868
	C208.146729,365.295715 208.335342,367.277161 210.338974,370.088379
	C214.952911,376.562012 221.272690,380.130585 228.805008,381.914459
	C229.774384,382.144043 230.977768,381.971771 231.707214,382.495178
	C232.892746,383.345825 233.776138,384.617554 234.787048,385.711548
	C233.411667,386.542694 231.940659,388.215912 230.675995,388.072266
	C220.278305,386.891235 211.722275,382.287170 205.549377,373.621765
	C200.394257,366.385162 200.369186,366.097382 205.443954,358.890198
	C212.052200,349.505066 218.662445,340.121368 225.489883,330.450928
z"/>
<path fill="#F6FAFC" opacity="1.000000" stroke="none"
	d="
M307.118896,190.065063
	C318.448425,186.248367 329.057587,187.127213 339.401123,192.248108
	C342.725098,193.893738 344.355316,196.190521 344.287964,200.083450
	C344.103760,210.732224 344.183533,221.386520 344.243683,232.038132
	C344.266815,236.130692 342.768860,239.031891 338.380005,239.074387
	C333.982666,239.116959 332.383484,236.251389 332.403107,232.143372
	C332.444489,223.488846 332.275177,214.830582 332.485901,206.181000
	C332.565613,202.908340 331.493256,201.167313 328.274017,200.710678
	C326.305908,200.431534 324.363220,199.906815 322.388672,199.750443
	C311.978790,198.926071 310.226135,200.527634 310.226074,210.656998
	C310.226044,217.813797 310.160034,224.971420 310.251282,232.127060
	C310.303223,236.198349 308.989929,239.103394 304.483734,239.174484
	C300.016296,239.244980 298.421692,236.410400 298.418945,232.349838
	C298.411591,221.365005 298.516632,210.378860 298.375061,199.395844
	C298.299530,193.533844 302.735931,192.136063 307.118896,190.065063
z"/>
<path fill="#EBA52B" opacity="1.000000" stroke="none"
	d="
M271.071869,191.959198
	C276.595428,191.615021 281.727509,191.076218 286.836151,191.245117
	C288.186462,191.289749 289.464661,193.516083 290.775970,194.740631
	C289.211426,195.512772 287.707855,196.758194 286.071014,196.968979
	C281.010773,197.620575 275.883514,197.740814 270.814789,198.341568
	C267.935852,198.682785 266.837952,200.461349 266.938202,203.589554
	C267.194244,211.577560 267.111053,219.580795 266.969635,227.574509
	C266.927551,229.952728 268.330597,233.799973 264.231415,233.831528
	C259.796295,233.865677 261.230835,229.828629 261.185699,227.295547
	C261.040253,219.135345 261.521210,210.945770 260.996796,202.816803
	C260.637329,197.244507 262.886261,194.411285 267.838470,192.983215
	C268.794983,192.707367 269.727844,192.349426 271.071869,191.959198
z"/>
<path fill="#EDA62A" opacity="1.000000" stroke="none"
	d="
M229.018616,211.034729
	C229.018051,216.523041 229.241226,221.532883 228.883850,226.500977
	C228.773163,228.039658 227.084625,229.464828 226.118500,230.941971
	C225.074097,229.531555 223.170258,228.147842 223.125488,226.706390
	C222.846603,217.726273 222.822189,208.727814 223.122803,199.749146
	C223.181259,198.003403 224.628708,195.617508 226.142181,194.724091
	C233.298767,190.499451 241.196411,189.655258 249.256165,191.216919
	C250.588684,191.475098 251.583069,193.478455 252.733719,194.675293
	C251.298859,195.451538 249.914505,196.762299 248.419662,196.903519
	C243.985489,197.322449 239.484833,197.048019 235.053574,197.484848
	C230.721542,197.911926 228.011765,200.023727 228.965179,205.057159
	C229.300003,206.824783 229.018616,208.709137 229.018616,211.034729
z"/>
<path fill="#0171BA" opacity="1.000000" stroke="none"
	d="
M851.809448,287.844910
	C866.032837,273.241089 883.108398,268.825378 901.883911,274.408783
	C921.196838,280.151978 931.986206,294.839050 935.805603,314.033630
	C940.065247,335.440887 934.638855,354.715485 917.808960,369.135162
	C891.159973,391.967651 850.781433,377.992584 841.346558,342.804413
	C836.090698,323.202179 838.373413,304.509521 851.809448,287.844910
z"/>
<path fill="#0171BA" opacity="1.000000" stroke="none"
	d="
M1009.072571,310.528290
	C1016.355164,284.307281 1037.394043,269.413971 1063.004272,271.959442
	C1083.163696,273.963165 1103.056396,294.222046 1103.884155,313.828400
	C1102.780273,313.935272 1101.662720,314.137299 1100.545166,314.137970
	C1071.428955,314.155426 1042.312744,314.161102 1013.196472,314.133209
	C1011.102173,314.131226 1008.064026,315.093689 1009.072571,310.528290
z"/>
</svg>