import * as React from 'react'
import './frame-landscape-big.css'

export const FrameLandscapeBigIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" className="frame-landscape" viewBox="0 0 116.574 91.949">
    <g transform="translate(0 -33.331)">
        <path id="Path_12111" d="M114.727 33.331H1.847A1.847 1.847 0 0 0 0 35.178v88.255a1.847 1.847 0 0 0 1.847 1.847h112.88a1.846 1.846 0 0 0 1.847-1.847V35.178a1.846 1.846 0 0 0-1.847-1.847zm-9.235 77.224L80.09 84.249a.756.756 0 0 0-1.043-.042L61.429 99.714 38.91 71.986a.741.741 0 0 0-.6-.279.755.755 0 0 0-.59.3l-26.638 35.745V44.413h94.41z" className="frame-landscape-big-1" data-name="Path 12111"/>
        <path id="Path_12112" d="M194.592 104.615a9.062 9.062 0 1 0-9.061-9.062 9.063 9.063 0 0 0 9.061 9.062z"
        className="frame-landscape-big-1" data-name="Path 12112" transform="translate(-116.997 -33.523)"/>
    </g>
</svg>
