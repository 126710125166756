import * as React from 'react'
import './main-schedule.css'

export const MainSchedule = (props: any) =>
<svg className="panel-icon" xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} viewBox="0 0 600 600">
<path className="main-schedule-1" d="M463.51,67.22a7.29,7.29,0,0,0-8.45-5.83,11.7,11.7,0,0,1,.21,1.32c-25,6.69-24.62,16.23-50.23,12.5-11-1.62.43-11.66-2.45-22.42-1-3.9-9.73-10.59-13.66-9.54-25,6.69-25.73,3.9-50.74,10.6-3.89,1,1.41,11.69,2.43,15.5,2.86,10.72,11.14,20.23.9,24.52-75.06,31.47-77.46,28-158.21,38.18-11,1.4-10.21-6.39-13.08-17.11-1-3.89.91-14.57-3-13.53-25.12,6.72-23.61,12.37-48.71,19.09-3.9,1-13.83,7.82-12.79,11.7,2.87,10.72,24.45,15.24,15.71,22.09-20.46,16-26,8.79-51,15.5-3.89,1.05-5.25-.31-4.23,3.52,51.64,193,52.43,192.76,104.06,385.72,1,3.9,2.21,7.77,6.11,6.73C369.27,514.14,368,509.31,560.89,457.7c3.9-1.05,1.4.59.36-3.31C509.73,261.52,515.09,260.09,463.51,67.22Z"/>
<path className="main-schedule-2" d="M464.36,358.4c-13.16-25.1-8-26.57-15.33-54s-5.81-27.76-13.13-55.12,4.26-35.63-20.42-49.39c-25.56-14.23-27-1.41-55.26,6.17s-27.59,10-55.85,17.53S277.2,235.21,249,242.77s-27.51,10.29-55.75,17.85-42.33-14.42-57.35,10.65c-14.57,24.31,9.56,26.45,16.88,53.82s4.94,28,12.26,55.35,2.49,28.65,9.81,56-2.74,34.84,21.94,48.65c25.54,14.24,25-4.71,53.24-12.26s28.59-6.27,56.85-13.83,27.65-9.76,55.89-17.32,28.86-5.26,57.1-12.81,31.68-2.77,46.7-27.85C481.17,376.74,478.69,385.73,464.36,358.4Z"/>
<path className="main-schedule-3" d="M328.79,219.59,135.91,271.2l55.43,207.16,271.44-72.63L407.35,198.57Zm-46.17,27.67,50-13.38,13.37,50-50,13.38Zm80.57,100.88-50,13.38-13.38-50,50-13.38ZM235.55,328.71l50-13.38,13.38,50-50,13.38Zm-17.2-64.29,50-13.37,13.38,50-50,13.38Zm-64.29,17.21,50-13.38,13.38,50-50,13.38Zm17.21,64.28,50-13.37,13.38,50-50,13.38Zm80.57,100.88-50,13.38-13.38-50,50-13.38Zm64.28-17.2-50,13.38-13.38-50,50-13.38Zm64.29-17.2-50,13.37-13.37-50,50-13.38Zm64.29-17.21-50,13.38-13.38-50,50-13.37ZM427.5,330.9l-50,13.37-13.38-50,50-13.38ZM396.93,216.64l13.37,50-50,13.38-13.38-50Z"/>
<path className="main-schedule-3" d="M428.18,47.56l-50,13.38-5.76-21.51a7.28,7.28,0,0,0-8.92-5.15l-50,13.38a7.28,7.28,0,0,0-5.15,8.91L314.08,78,156.83,120.08l-5.74-21.43a7.3,7.3,0,0,0-8.93-5.15L91.94,106.94a7.28,7.28,0,0,0-5.15,8.91l5.74,21.44L42.32,150.72a7.3,7.3,0,0,0-5.16,8.94L140.42,545.59a7.3,7.3,0,0,0,8.94,5.17L535.18,447.52a7.3,7.3,0,0,0,5.16-8.93L437.13,52.86a7.3,7.3,0,0,0-8.83-5.31ZM324.38,60l35.69-9.55,11.49,42.92L335.87,103ZM102.94,119.29l35.69-9.55,11.48,42.92-35.69,9.55Zm-49.5,43.86,42.85-11.47L102,173.12a7.28,7.28,0,0,0,8.93,5.14l50.11-13.41a7.23,7.23,0,0,0,2.55-1.25,7.25,7.25,0,0,0,2.61-7.66L160.5,134.5,317.75,92.42l5.73,21.44a7.27,7.27,0,0,0,3.4,4.42,7.33,7.33,0,0,0,5.52.73l50-13.38a7.4,7.4,0,0,0,2.55-1.25,7.52,7.52,0,0,0,1.87-2.14,7.33,7.33,0,0,0,.73-5.52l-5.74-21.44,42.86-11.46,17.18,64.24L70.61,227.39Zm99.38,371.44L74.46,241.71,445.9,142.32,524.26,435.2Z"/>
</svg>
