import * as React from 'react'
import './menu-template.css'

export const MenuTemplate = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Component_393_2" width="27.914" height="28.43" data-name="Component 393 – 2" viewBox="0 0 27.914 28.43">
    <path id="Path_12249" d="M552.727 278.29a9.331 9.331 0 0 0 .106-1.437 8.375 8.375 0 0 1 .056-1.425 13.329 13.329 0 0 1 .348-1.448c.112-.4.231-.817.336-1.228l.289-2.737-.005-.13a10.345 10.345 0 0 1 .017-1.473.26.26 0 0 1 .012-.057.341.341 0 0 1 .016-.077.313.313 0 0 1 .4-.175c.05.02.1.039.153.055l.056.016a.411.411 0 0 1 .042.01 6.284 6.284 0 0 0 1.279.047 3.144 3.144 0 0 1 .642.05.522.522 0 0 1 .221.081l.052-.489-3.065-.324-1.245 11.8.1.011c.016-.118.047-.237.067-.343.045-.242.087-.484.123-.727z" className="menu-template-1" data-name="Path 12249" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12250" d="M572.392 274.463a14.1 14.1 0 0 0-.22-3.622 13.853 13.853 0 0 0-.7-1.681 15.274 15.274 0 0 0-.5-1.793 14.637 14.637 0 0 0-.949-1.6c-.369-.488-1.116-.632-1.544-1.067a12.056 12.056 0 0 0-2.637-2.5c-.523-.307-1.238-.183-1.8-.42a13.157 13.157 0 0 0-1.651-.666c-.579-.161-1.094-.721-1.688-.8s-1.22.311-1.834.311-1.254-.489-1.848-.416a14.132 14.132 0 0 0-1.728.7 7.444 7.444 0 0 0-4.79 2.476 14.461 14.461 0 0 0-1.554 1c-.428.43-.547 1.142-.917 1.63a13.822 13.822 0 0 0-1.123 1.432 14.383 14.383 0 0 0-.548 1.751 13.368 13.368 0 0 0-.636 1.685 13.746 13.746 0 0 0-.585 1.741 14.6 14.6 0 0 0 .046 1.836c0 .624-.419 1.275-.341 1.875a14.429 14.429 0 0 0 .439 1.822c.157.6.686 1.057.919 1.631s.082 1.344.382 1.874a14.617 14.617 0 0 0 .99 1.6c.372.489 1.245.534 1.673.969s.506 1.283.985 1.657 1.205.407 1.731.716a13.607 13.607 0 0 0 1.663.7c.557.234 1.011.789 1.6.949a13.845 13.845 0 0 0 1.8.369c.593.079 1.222-.377 1.837-.377a13.879 13.879 0 0 0 1.8.117 13.5 13.5 0 0 0 1.766-.377 12.029 12.029 0 0 1 3.3-1.446 13.76 13.76 0 0 0 1.579-.9 14.2 14.2 0 0 0 1.216-1.366c.427-.435 1.257-.527 1.626-1.015a14.884 14.884 0 0 0 .814-1.7 492.922 492.922 0 0 0 1.543-3.381 14.876 14.876 0 0 0 .232-1.858c.078-.602-.348-1.232-.348-1.856zm-7.382 6.626a.409.409 0 0 1-.452.363l-12.616-1.331a.4.4 0 0 1-.358-.443l1.331-12.616a.41.41 0 0 1 .451-.364l12.616 1.332a.393.393 0 0 1 .359.44z" className="menu-template-1" data-name="Path 12250" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12251" d="M556.985 273.451l.347.036c.046-.325.088-.65.123-.977q.215-1.89.283-3.788a.305.305 0 0 1 .3-.273.3.3 0 0 1 .221-.066l.483.071c1.319.194 2.636.385 3.955.547q.993.123 1.988.228l.5.052a.754.754 0 0 1 .246.03l.055-.516-7.917-.836z" className="menu-template-1" data-name="Path 12251" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12252" d="M556.319 279.759l.282.03c0-.02 0-.04.007-.059l.044-.274.1-.624c.065-.408.128-.819.18-1.228a16.98 16.98 0 0 0 .147-1.829v-.575a.365.365 0 0 1 .051-.235.224.224 0 0 1 .034-.041.313.313 0 0 1 .322-.184c.214.035.43.083.637.135s.381.1.577.152l.309.084.144.038h.011l2.623.277c.382.021.764.041 1.146.064l1.25.072a1.149 1.149 0 0 1 .335.052.522.522 0 0 1 .189.12l.044.005.067-.639-7.917-.835z" className="menu-template-1" data-name="Path 12252" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12253" d="M556.474 268.281a3.144 3.144 0 0 0-.642-.05 6.284 6.284 0 0 1-1.279-.047.411.411 0 0 0-.042-.01l-.056-.016c-.053-.016-.1-.035-.153-.055a.313.313 0 0 0-.4.175.341.341 0 0 0-.016.077.26.26 0 0 0-.012.057 10.345 10.345 0 0 0-.017 1.473l.005.13-.289 2.737c-.1.411-.224.823-.336 1.228a13.329 13.329 0 0 0-.348 1.448 8.375 8.375 0 0 0-.056 1.425 9.331 9.331 0 0 1-.106 1.437c-.036.243-.078.485-.122.727-.02.106-.051.225-.067.343l2.965.313 1.192-11.311a.522.522 0 0 0-.221-.081z" className="menu-template-1" data-name="Path 12253" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12254" d="M556.474 268.281a3.144 3.144 0 0 0-.642-.05 6.284 6.284 0 0 1-1.279-.047.411.411 0 0 0-.042-.01l-.056-.016c-.053-.016-.1-.035-.153-.055a.313.313 0 0 0-.4.175.341.341 0 0 0-.016.077.26.26 0 0 0-.012.057 10.345 10.345 0 0 0-.017 1.473l.005.13-.289 2.737c-.1.411-.224.823-.336 1.228a13.329 13.329 0 0 0-.348 1.448 8.375 8.375 0 0 0-.056 1.425 9.331 9.331 0 0 1-.106 1.437c-.036.243-.078.485-.122.727-.02.106-.051.225-.067.343l2.965.313 1.192-11.311a.522.522 0 0 0-.221-.081z" className="menu-template-2" data-name="Path 12254" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12255" d="M564.683 269.229q-.994-.105-1.988-.228a167.641 167.641 0 0 1-3.955-.547l-.483-.071a.3.3 0 0 0-.221.066.305.305 0 0 0-.3.273q-.067 1.9-.283 3.788c-.035.327-.077.652-.123.977l7.57.8.525-4.975a.754.754 0 0 0-.246-.03z" className="menu-template-1" data-name="Path 12255" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12256" d="M564.683 269.229q-.994-.105-1.988-.228a167.641 167.641 0 0 1-3.955-.547l-.483-.071a.3.3 0 0 0-.221.066.305.305 0 0 0-.3.273q-.067 1.9-.283 3.788c-.035.327-.077.652-.123.977l7.57.8.525-4.975a.754.754 0 0 0-.246-.03z" className="menu-template-3" data-name="Path 12256" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12257" d="M564.516 275.617a1.149 1.149 0 0 0-.335-.052l-1.25-.072c-.382-.023-.764-.043-1.146-.064l-2.623-.277h-.011l-.144-.038-.309-.084c-.2-.053-.384-.1-.577-.152s-.423-.1-.637-.135a.313.313 0 0 0-.322.184.224.224 0 0 0-.034.041.365.365 0 0 0-.051.235V275.775c0 .2-.012.4-.025.6-.026.41-.07.82-.122 1.229s-.115.82-.18 1.228l-.1.624-.044.274c0 .019 0 .039-.007.059l7.636.8.512-4.852-.044-.005a.522.522 0 0 0-.187-.115z" className="menu-template-1" data-name="Path 12257" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12258" d="M564.516 275.617a1.149 1.149 0 0 0-.335-.052l-1.25-.072c-.382-.023-.764-.043-1.146-.064l-2.623-.277h-.011l-.144-.038-.309-.084c-.2-.053-.384-.1-.577-.152s-.423-.1-.637-.135a.313.313 0 0 0-.322.184.224.224 0 0 0-.034.041.365.365 0 0 0-.051.235V275.775c0 .2-.012.4-.025.6-.026.41-.07.82-.122 1.229s-.115.82-.18 1.228l-.1.624-.044.274c0 .019 0 .039-.007.059l7.636.8.512-4.852-.044-.005a.522.522 0 0 0-.187-.115z" className="menu-template-4" data-name="Path 12258" transform="translate(-544.836 -260.202)"/>
    <path id="Path_12259" d="M565.983 268.03l-12.616-1.33a.41.41 0 0 0-.451.364l-1.331 12.616a.4.4 0 0 0 .358.443l12.616 1.331a.409.409 0 0 0 .452-.363l1.332-12.619a.393.393 0 0 0-.359-.44zm-13.445 11.33l-.1-.011 1.245-11.8 3.065.324-.052.489-1.196 11.311zm12.211-3.618l-.512 4.852-7.636-.8-.282-.03.58-5.491 7.917.835zm.678-6.431l-.525 4.975-7.57-.8-.347-.036.58-5.492 7.917.836z" className="menu-template-5" data-name="Path 12259" transform="translate(-544.836 -260.202)"/>
</svg>
