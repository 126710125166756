import * as React from 'react'
import './emailSent.css'

export const EmailSent = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth ? props.svgwidth : '105'}
  height={props.svgheight ? props.svgheight : '105'} viewBox="0 0 105 105">
    <g id="Group_6221" data-name="Group 6221" transform="translate(-668 -284)">
        <g id="Component_386_1" data-name="Component 386 – 1" transform="translate(668 284)">
            <g id="Ellipse_9" className="emailSent-1" data-name="Ellipse 9">
                <path id="Path_344" d="M1240.5 236.38c2.31 0 4.665-1.44 6.9-1.15 2.283.3 4.17 2.362 6.35 2.943a49.466 49.466 0 0 1 6.207 2.46c2.115.875 4.8.418 6.764 1.551s3.537 2.892 5.338 4.274 2.972 3.372 4.581 4.977 4.417 2.14 5.807 3.943a53.346 53.346 0 0 1 3.569 5.893c1.137 1.959 1.019 4.514 1.9 6.623a50.449 50.449 0 0 1 2.643 6.208 51.417 51.417 0 0 1 .828 13.38c0 2.3 1.6 4.631 1.313 6.855a54.116 54.116 0 0 1-.873 6.864c-.593 2.212-2.036 4.2-2.9 6.288a52.436 52.436 0 0 1-2.9 6.209c-1.144 1.973-1.676 4.487-3.061 6.285s-4.51 2.144-6.119 3.749-2.767 3.66-4.575 5.046-3.962 2.185-5.94 3.326-4.268 1.327-6.382 2.2c-2.09.865-3.8 2.548-6.02 3.138a51.291 51.291 0 0 1-6.642 1.392c-2.231.29-4.469-.433-6.779-.433s-4.674 1.684-6.9 1.394a53.271 53.271 0 0 1-6.782-1.364c-2.217-.591-3.924-2.64-6.015-3.506a51.622 51.622 0 0 1-6.254-2.6c-1.978-1.142-4.707-1.262-6.509-2.644s-2.1-4.514-3.709-6.119-4.9-1.774-6.294-3.578a54.829 54.829 0 0 1-3.723-5.9c-1.135-1.958-.561-4.813-1.438-6.923-.867-2.086-2.864-3.813-3.456-6.025a53.088 53.088 0 0 1-1.652-6.729c-.292-2.224 1.281-4.622 1.281-6.926s-.465-4.557-.174-6.781c.3-2.277 1.618-4.257 2.2-6.432a49.032 49.032 0 0 1 2.39-6.222c.877-2.109.924-4.51 2.06-6.468s2.838-3.493 4.223-5.291 1.84-4.414 3.449-6.021 4.036-2.3 5.844-3.688 3.315-3.173 5.292-4.313a52.5 52.5 0 0 1 6.026-3.3c2.09-.865 4.481-.947 6.7-1.538 2.18-.582 4.217-2.273 6.5-2.569 2.221-.284 4.625 1.522 6.932 1.522z" className="emailSent-2" data-name="Path 344" transform="translate(-1187.731 -234.832)"/>
            </g>
            <g id="Group_6217" data-name="Group 6217" transform="rotate(-10.02 101.378 -116.902)">
                <path id="Path_12121" d="M62.387 106.762l-1.707-1.132v.271L41.832 96.4l-16.7 9.515v-.283l-3.042 2.017-1.924 1.1v23.225h2.919l-1.05.7h41.74l-1.05-.7h2.92v-23.568z" className="emailSent-2" data-name="Path 12121" transform="translate(-17.999 -71.368)"/>
                <path id="Path_12122" d="M48.706 35.814V60.8a.807.807 0 0 1-.807.807H.807A.807.807 0 0 1 0 60.8V35.814a.8.8 0 0 1 .361-.672l6.218-4.123V17.237a.807.807 0 0 1 .807-.807h33.933a.807.807 0 0 1 .807.807v13.781l6.219 4.124a.806.806 0 0 1 .361.672zm-1.613 23.479V37.155L28.541 47zm-4.967-24.286h3.1l-3.1-2.053zM3.483 59.989h41.74l-20.87-13.834zm38.643-22.025l2.531-1.343h-2.531zm-1.613.855V18.043H8.193v20.776l13.5 7.162 2.212-1.467a.809.809 0 0 1 .892 0l2.212 1.467zm-38.9 20.474L20.164 47l-18.55-9.84zm4.966-21.33v-1.342H4.048zm0-2.956v-2.052l-3.1 2.052z" className="emailSent-3" data-name="Path 12122"/>
                <path id="Path_12123" d="M108.49 31.43v20.776l-13.5 7.162-2.214-1.468a.809.809 0 0 0-.892 0l-2.212 1.467-13.5-7.162V31.43zm-8.926 12.725v-.6a7.234 7.234 0 0 0-14.468 0v.245a7.242 7.242 0 0 0 7.233 7.233h6.428a.807.807 0 0 0 0-1.613h-6.428a5.626 5.626 0 0 1-5.62-5.62v-.239a5.62 5.62 0 0 1 11.241 0v.6a1.217 1.217 0 0 1-2.434 0v-1.13a3.489 3.489 0 1 0-1.189 2.618 2.829 2.829 0 0 0 5.236-1.488z" className="emailSent-4" data-name="Path 12123" transform="translate(-67.977 -13.387)"/>
                <path id="Path_12124" d="M173.628 84.183v.6a2.829 2.829 0 0 1-5.236 1.488 3.483 3.483 0 1 1 1.189-2.618v1.13a1.217 1.217 0 0 0 2.434 0v-.6a5.62 5.62 0 0 0-11.241 0v.239a5.626 5.626 0 0 0 5.62 5.62h6.428a.807.807 0 1 1 0 1.613h-6.428a7.242 7.242 0 0 1-7.233-7.233v-.239a7.234 7.234 0 0 1 14.468 0zm-5.661-.535a1.871 1.871 0 1 0-1.87 1.87 1.873 1.873 0 0 0 1.87-1.869z" data-name="Path 12124" transform="translate(-142.041 -54.011)"/>
                <path id="Path_12125" d="M208.121 121.83a1.871 1.871 0 1 1-1.871 1.872 1.873 1.873 0 0 1 1.871-1.872z" className="emailSent-4" data-name="Path 12125" transform="translate(-184.066 -94.063)"/>
            </g>
        </g>
        <g id="Group_6220" data-name="Group 6220" transform="translate(-532 -15)">
            <g id="Group_5321" data-name="Group 5321" transform="translate(3 -240)">
                <g id="Ellipse_121" className="emailSent-5" data-name="Ellipse 121" transform="translate(1268 541)">
                    <circle cx="14" cy="14" r="14" className="emailSent-6"/>
                    <circle cx="14" cy="14" r="12.5" className="emailSent-7"/>
                </g>
                <path id="checked" d="M.172 9.3a.554.554 0 0 1 0-.8l.8-.8a.554.554 0 0 1 .8 0l.057.057 3.152 3.381a.277.277 0 0 0 .4 0l7.68-7.966h.057a.554.554 0 0 1 .8 0l.8.8a.554.554 0 0 1 0 .8l-9.17 9.514a.554.554 0 0 1-.8 0L.287 9.476.172 9.3z" className="emailSent-4" transform="translate(1275 546)"/>
            </g>
        </g>
    </g>
</svg>
