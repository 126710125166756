import * as React from 'react'
import './domain-authentication.css'

export const DomainAuthentication = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" className="panel-icon" viewBox="0 0 67.298 58.981" width={props.svgwidth} height={props.svgheight}>

    <g id="Group_6288" data-name="Group 6288">
        <g id="Group_6290" data-name="Group 6290">
            <g id="Group_6288-2" data-name="Group 6288">
                <g id="Path_12121" data-name="Path 12121" transform="translate(2.416 11.515)">
                    <path id="Path_12266" d="M1413.9 2194.332l-1.875-.818.047.267-20.194-6.075-14.79 12.268-.049-.278-2.645 2.514-1.7 1.415 4.037 22.855 2.873-.508-.913.868 41.082-7.257-1.156-.5 2.871-.506-4.1-23.2z" className="domain-authentication-icon-1" data-name="Path 12266" transform="translate(-1372.69 -2187.706)"/>
                </g>
                <g id="Path_12122" data-name="Path 12122">
                    <path id="Path_12267" d="M1419.391 2199.557l4.345 24.587a.807.807 0 0 1-.656.934l-46.349 8.189a.809.809 0 0 1-.936-.654l-4.343-24.588a.8.8 0 0 1 .24-.723l5.4-5.14-2.4-13.563a.808.808 0 0 1 .656-.936l33.4-5.9a.806.806 0 0 1 .933.653l2.4 13.564 6.839 2.978a.806.806 0 0 1 .471.599zm2.494 23.388l-3.85-21.789-16.545 12.92zm-9.111-23.038l3.047-.54-3.405-1.483zm-33.69 31.306l41.08-7.259-22.945-9.987zm34.2-28.4l2.256-1.763-2.49.441zm-1.44 1.122l-3.612-20.448-31.809 5.619 3.612 20.448 14.533 4.7 1.922-1.829a.807.807 0 0 1 .878-.154l2.432 1.059zm-34.725 26.915l16.12-15.321-19.97-6.468zm1.181-21.85l-.234-1.323-2.491.441zm-.514-2.91l-.356-2.019-2.692 2.556z" className="domain-authentication-icon-2" data-name="Path 12267" transform="translate(-1371.44 -2181.75)"/>
                </g>
                <g id="Path_12123" data-name="Path 12123" transform="translate(4.986 1.743)">
                    <path id="Path_12268" d="M1405.824 2182.652l3.614 20.449-12.044 9.393-2.427-1.057a.811.811 0 0 0-.88.154l-1.922 1.829-14.535-4.7-3.612-20.447zm-6.568 14.074l-.1-.586a7.23 7.23 0 1 0-14.24 2.515l.042.234a7.239 7.239 0 0 0 8.378 5.864l6.327-1.119a.806.806 0 0 0-.281-1.587l-6.326 1.117a5.626 5.626 0 0 1-6.5-4.548l-.042-.244a5.618 5.618 0 0 1 11.064-1.955l.1.588a1.216 1.216 0 1 1-2.4.423l-.2-1.114a3.485 3.485 0 1 0-.715 2.784 2.828 2.828 0 0 0 4.9-2.375z" className="domain-authentication-icon-3" data-name="Path 12268" transform="translate(-1374.019 -2182.652)"/>
                </g>
                <g id="Path_12124" data-name="Path 12124" transform="translate(15.764 9.26)">
                    <path id="Path_12269" d="M1393.945 2192.513l.1.588a2.828 2.828 0 0 1-4.9 2.374 3.48 3.48 0 1 1 .715-2.782l.2 1.112a1.216 1.216 0 0 0 2.4-.424l-.1-.586a5.617 5.617 0 1 0-11.064 1.953l.041.234a5.624 5.624 0 0 0 6.5 4.548l6.334-1.118a.806.806 0 1 1 .282 1.587l-6.328 1.118a7.235 7.235 0 0 1-8.374-5.853l-.042-.236a7.231 7.231 0 0 1 14.241-2.515zm-5.665.46a1.87 1.87 0 1 0-1.516 2.166 1.873 1.873 0 0 0 1.516-2.166z" data-name="Path 12269" transform="translate(-1379.593 -2186.54)"/>
                </g>
                <g id="Path_12125" data-name="Path 12125" transform="translate(20.737 14.147)">
                    <path id="Path_12270" d="M1383.709 2189.1a1.87 1.87 0 1 1-1.516 2.167 1.871 1.871 0 0 1 1.516-2.167z" className="domain-authentication-icon-3" data-name="Path 12270" transform="translate(-1382.165 -2189.067)"/>
                </g>
            </g>
            <g id="Group_6289" data-name="Group 6289" transform="translate(29.492 21.865)">
                <g id="Path_344-2" data-name="Path 344-2">
                    <path id="Path_12271" d="M1409.014 2194c.824.145 1.748-.209 2.523.033s1.338 1.085 2.078 1.427a17.968 17.968 0 0 1 2.056 1.247c.708.439 1.688.447 2.31.965a18.778 18.778 0 0 1 1.64 1.827 18.575 18.575 0 0 1 1.323 2.023c.472.661 1.439 1.025 1.821 1.74a18.51 18.51 0 0 1 .9 2.28 18.971 18.971 0 0 1 .269 2.428 10.388 10.388 0 0 1 .027 7.05c-.141.8.286 1.717.045 2.473a7.946 7.946 0 0 1-2.142 4.35 18.372 18.372 0 0 1-1.415 1.984 19.164 19.164 0 0 1-1.477 2c-.6.539-1.736.466-2.405.922a7.624 7.624 0 0 1-4.256 2.262 16.574 16.574 0 0 0-4.739 1.083 18.839 18.839 0 0 1-2.448.068 19.726 19.726 0 0 1-2.384-.576c-.822-.145-1.767.294-2.539.053a19.422 19.422 0 0 1-2.328-.9c-.752-.346-1.23-1.168-1.922-1.6a18.94 18.94 0 0 1-2.065-1.3c-.634-.522-1.6-.737-2.15-1.33s-.47-1.706-.943-2.366-1.634-.926-2.017-1.644a19.029 19.029 0 0 1-.97-2.291c-.282-.756.106-1.711-.083-2.506a8.633 8.633 0 0 1-1.033-4.762c.033-.793.739-1.531.882-2.334a18.5 18.5 0 0 1 .356-2.377 18.021 18.021 0 0 1 1.18-2.105 17.206 17.206 0 0 1 1.234-2.018 18.215 18.215 0 0 1 1.131-2.127 18.706 18.706 0 0 1 1.827-1.579c.6-.54.926-1.423 1.6-1.878a20.047 20.047 0 0 1 2.3-.92 18.47 18.47 0 0 1 2.146-1.172 18.7 18.7 0 0 1 2.345-.764 16.919 16.919 0 0 0 4.948-.6c.811.008 1.553.789 2.375.934z" className="domain-authentication-icon-4" data-name="Path 12271" transform="translate(-1386.693 -2193.059)"/>
                </g>
            </g>
            <g id="www" className="domain-authentication-icon-5" transform="translate(33.531 37.605)">
                <g id="Group_6287" className="domain-authentication-icon-5" data-name="Group 6287">
                    <path id="Path_12272" d="M1388.782 2201h1.784l1.079 5.829 1.288-5.61h1.509l1.3 5.61 1.068-5.829h1.718l-1.872 8.047h-1.756l-1.255-5.259-1.277 5.259h-1.751z" className="domain-authentication-icon-3" data-name="Path 12272" transform="translate(-1388.782 -2200.996)"/>
                    <path id="Path_12273" d="M1394.248 2201h1.784l1.079 5.829 1.289-5.61h1.509l1.3 5.61 1.068-5.829h1.718l-1.872 8.047h-1.751l-1.257-5.259-1.276 5.259h-1.751z" className="domain-authentication-icon-3" data-name="Path 12273" transform="translate(-1384.216 -2200.996)"/>
                    <path id="Path_12274" d="M1399.714 2201h1.784l1.079 5.829 1.288-5.61h1.509l1.3 5.61 1.068-5.829h1.718l-1.872 8.047h-1.751l-1.255-5.259-1.277 5.259h-1.751z" className="domain-authentication-icon-3" data-name="Path 12274" transform="translate(-1379.65 -2200.996)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
