import * as React from 'react'
import './menu-images-text.css'

export const MenuImagesText = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Component_387_4" width="28.021" height="28.534" data-name="Component 387 – 4" viewBox="0 0 28.021 28.534">
    <path id="Path_12308" d="M570.981 361.84a14.237 14.237 0 0 0-.221-3.634 14.023 14.023 0 0 0-.707-1.688 14.731 14.731 0 0 0-.505-1.8 14.465 14.465 0 0 0-.952-1.6c-.369-.49-1.12-.635-1.549-1.071a12.13 12.13 0 0 0-2.648-2.515c-.524-.307-1.241-.184-1.8-.421a12.847 12.847 0 0 0-1.657-.669c-.584-.157-1.1-.72-1.694-.8a1.161 1.161 0 0 0-.166-.01c-.545 0-1.115.322-1.675.322s-1.169-.423-1.727-.423a.892.892 0 0 0-.127.007 14.222 14.222 0 0 0-1.734.7 13.539 13.539 0 0 0-1.789.418 9.837 9.837 0 0 0-3.02 2.074 14.658 14.658 0 0 0-1.561 1c-.43.436-.55 1.145-.92 1.636a13.734 13.734 0 0 0-1.129 1.434 14.151 14.151 0 0 0-.549 1.758 13.18 13.18 0 0 0-.638 1.691 13.688 13.688 0 0 0-.588 1.748 14.359 14.359 0 0 0 .047 1.843c0 .625-.42 1.276-.343 1.881a14.665 14.665 0 0 0 .441 1.828c.159.6.688 1.07.923 1.638s.081 1.351.384 1.882a14.892 14.892 0 0 0 .993 1.6c.368.491 1.251.536 1.681.974s.507 1.286.989 1.663 1.21.407 1.738.717a13.438 13.438 0 0 0 1.668.708c.559.235 1.014.792 1.606.951a13.933 13.933 0 0 0 1.81.372 1.114 1.114 0 0 0 .141.008c.554 0 1.135-.388 1.7-.388a14.025 14.025 0 0 0 1.809.119 13.569 13.569 0 0 0 1.773-.378 12.1 12.1 0 0 1 3.311-1.452 13.965 13.965 0 0 0 1.585-.909 14.429 14.429 0 0 0 1.221-1.371c.429-.436 1.263-.53 1.633-1.019a15.376 15.376 0 0 0 .816-1.709 14.2 14.2 0 0 0 .774-1.687 14.433 14.433 0 0 0 .775-1.709 14.622 14.622 0 0 0 .232-1.864c.077-.595-.351-1.228-.351-1.855zm-5.665 6.691a1 1 0 0 1-1 1h-9a1 1 0 0 1-.922-.611 1.014 1.014 0 0 1-.079-.39v-5h-4a1 1 0 0 1-.922-.611 1.014 1.014 0 0 1-.079-.39v-8a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v6h4a1 1 0 0 1 1 1z" className="menu-images-text-1" data-name="Path 12308" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12309" d="M560.315 354.53v6-6a1 1 0 0 0-.611-.922 1 1 0 0 1 .611.922z" className="menu-images-text-2" data-name="Path 12309" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12310" d="M559.315 354.03h-9a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h4v-1.5a.97.97 0 0 1 .17-.523H551.3a.112.112 0 0 1-.112-.112v-5.365a.112.112 0 0 1 .112-.112h6.862a.112.112 0 0 1 .112.112v5h1.544v-6a.5.5 0 0 0-.503-.5z" className="menu-images-text-2" data-name="Path 12310" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12311" d="M550.315 363.53a1 1 0 0 1-.921-.61 1 1 0 0 0 .922.611z" className="menu-images-text-2" data-name="Path 12311" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12312" d="M551.859 359.942l1.618-2.173a.044.044 0 0 1 .036-.018.042.042 0 0 1 .037.017l1.37 1.688 1.071-.943a.045.045 0 0 1 .063 0l1.544 1.6v-4.026h-5.739zm4.043-3.33a.551.551 0 1 1-.551.551.551.551 0 0 1 .549-.551z" className="menu-images-text-2" data-name="Path 12312" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12313" d="M550.315 363.53h4v-.5h-4a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v6h.5v-6a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1v8a1.014 1.014 0 0 0 .079.39 1 1 0 0 0 .921.61z" className="menu-images-text-3" data-name="Path 12313" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12314" d="M558.159 355.418H551.3a.112.112 0 0 0-.112.112v5.365a.112.112 0 0 0 .112.112h3.188a.984.984 0 0 1 .83-.477h2.956v-5a.112.112 0 0 0-.115-.112zm-.561 4.7l-1.544-1.6a.045.045 0 0 0-.063 0l-1.071.943-1.37-1.688a.042.042 0 0 0-.037-.017.044.044 0 0 0-.036.018l-1.618 2.173v-3.855h5.741z" className="menu-images-text-4" data-name="Path 12314" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12315" d="M555.9 357.714a.551.551 0 1 0-.551-.551.551.551 0 0 0 .551.551z" className="menu-images-text-4" data-name="Path 12315" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12316" d="M564.315 361.03h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5zm-6.556 6.864v-4.676h-1.891v-1.165h5.105v1.165h-1.883v4.676zm5.93-2.993h-1.205v2.994h-.853v-2.994h-1.211v-.746h3.269z" className="menu-images-text-5" data-name="Path 12316" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12317" d="M564.705 360.609a1 1 0 0 1 .61.921v7a1 1 0 0 1-1 1h-9a1 1 0 0 1-.921-.61 1 1 0 0 0 .922.611h9a1 1 0 0 0 1-1v-7a1 1 0 0 0-.611-.922z" className="menu-images-text-5" data-name="Path 12317" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12318" d="M555.315 369.53h9a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-9a.984.984 0 0 0-.83.477.97.97 0 0 0-.17.523v7a1.014 1.014 0 0 0 .079.39 1 1 0 0 0 .921.61zm9.5-1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5z" className="menu-images-text-6" data-name="Path 12318" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12319" d="M559.09 363.218h1.883v-1.165h-5.1v1.165h1.891v4.676h1.331z" className="menu-images-text-7" data-name="Path 12319" transform="translate(-543.322 -347.531)"/>
    <path id="Path_12320" d="M560.42 364.9h1.209v2.994h.853V364.9h1.2v-.746h-3.262z" className="menu-images-text-7" data-name="Path 12320" transform="translate(-543.322 -347.531)"/>
</svg>
