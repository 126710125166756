import * as React from 'react'
import './instagram.css'

export const InstagramIcon = (props: any) =>
<svg id="instagram_2_"
  data-name="instagram (2)"
  xmlns="http://www.w3.org/2000/svg"
  className={props.className || 'instagram-icon'}
  viewBox="0 0 15.548 15.548"
>
  <defs>
    <linearGradient id="instagram-linear-gradient" x1="0.5" y1="0.008" x2="0.5" y2="0.998" gradientUnits="objectBoundingBox">
      <stop offset="0" stopColor="#e09b3d"/>
      <stop offset="0.3" stopColor="#c74c4d"/>
      <stop offset="0.6" stopColor="#c21975"/>
      <stop offset="1" stopColor="#7024c4"/>
    </linearGradient>
    <linearGradient id="instagram-linear-gradient-2" y1="-0.451" y2="1.462" href="#instagram-linear-gradient"/>
    <linearGradient id="instagram-linear-gradient-3" y1="-1.396" y2="6.586" href="#instagram-linear-gradient"/>
  </defs>
  <path id="Path_438" data-name="Path 438" className="insta-1" d="M7.908,0H3.355A3.359,3.359,0,0,0,0,3.355V7.908a3.359,3.359,0,0,0,3.355,3.355H7.908a3.359,3.359,0,0,0,3.355-3.355V3.355A3.359,3.359,0,0,0,7.908,0ZM10.13,7.908A2.222,2.222,0,0,1,7.908,10.13H3.355A2.222,2.222,0,0,1,1.133,7.908V3.355A2.222,2.222,0,0,1,3.355,1.133H7.908A2.222,2.222,0,0,1,10.13,3.355V7.908Z"/>
  <path id="Path_439" data-name="Path 439" className="insta-2" d="M135.913,133a2.913,2.913,0,1,0,2.913,2.913A2.916,2.916,0,0,0,135.913,133Zm0,4.693a1.78,1.78,0,1,1,1.78-1.78A1.78,1.78,0,0,1,135.913,137.693Z" transform="translate(-130.281 -130.281)"/>
  <ellipse id="Ellipse_10" data-name="Ellipse 10" className="insta-3" cx="0.698" cy="0.698" rx="0.698" ry="0.698" transform="translate(7.852 2.042)"/>
</svg>
