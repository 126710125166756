import * as React from 'react'
import './dailystats.css'

export const DailyStats = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgweight} height={props.svgheight} className="panel-icon" viewBox="0 0 27.288 22.173">
    <g id="Group_6287" data-name="Group 6287" transform="translate(-43.455 -612.634)">
        <g id="Group_6268" data-name="Group 6268">
            <g id="Group_6260" data-name="Group 6260" transform="translate(-350 -31)">
                <g id="Component_388_1" data-name="Component 388 1" transform="translate(397.479 644.617)">
                    <path id="Path_12085" d="M651.894 416.26a.332.332 0 0 0-.3-.356l-.006.061c-1.177 0-1.274.425-2.357-.039-.466-.2.156-.509.156-1.016 0-.183-.3-.579-.489-.579-1.178 0-1.178-.13-2.357-.129-.184 0-.075.531-.075.711 0 .505.252 1.021-.248 1.09-3.672.5-3.737.321-7.41-.177-.5-.068-.374-.4-.374-.906 0-.184.211-.63.028-.63-1.185 0-1.185.267-2.368.268-.184 0-.7.182-.7.364 0 .505.9.957.432 1.156-1.088.466-1.246.082-2.428.083-.184 0-.228-.076-.228.1.007 9.1.045 9.1.051 18.194 0 .184.006.369.19.369 9.094-.008 9.094-.235 18.188-.243.184 0 .054.043.054-.141-.006-9.088.247-9.088.241-18.18z" className="dailystats-1" data-name="Path 12085" transform="translate(-632.048 -413.637)"/>
                    <path id="Path_12086" d="M648.416 428.646a7.686 7.686 0 0 1-.042-2.554c0-1.292.07-1.29.069-2.579s.606-1.518-.32-2.414c-.957-.925-1.169-.378-2.5-.377s-1.331.115-2.664.116-1.33.19-2.662.191-1.331.13-2.663.131-1.694-1.132-2.649-.2c-.926.9.11 1.276.111 2.565s-.111 1.29-.11 2.579-.227 1.291-.225 2.579-.53 1.5.394 2.4c.956.926 1.155.086 2.486.085s1.331.06 2.664.059 1.331-.1 2.663-.106 1.331.107 2.663.106 1.426.25 2.382-.677c.927-.899.713-.533.403-1.904z" className="dailystats-2" data-name="Path 12086" transform="translate(-631.95 -413.201)"/>
                    <path id="Path_11666" d="M644.055 420.923l-9.093.007.008 9.765 12.8-.01-.008-9.766zm-2.357.675h2.357v2.357h-2.357zm2.361 5.384h-2.357v-2.357h2.357zm-5.387-2.353h2.356v2.357h-2.357zm0-3.03h2.357v2.357h-2.357zm-3.03 0h2.357v2.357h-2.357zm0 3.03H638v2.357h-2.357zm2.358 5.387h-2.357v-2.357H638zm3.03 0h-2.356v-2.357h2.357zm3.031 0h-2.357v-2.357h2.357zm3.03 0h-2.357v-2.357h2.357zm0-3.031h-2.357v-2.357h2.357zm0-5.385v2.357h-2.357V421.6zm.006 8.417h-11.459V421.6h11.456z" className="dailystats-3" data-name="Path 11666" transform="translate(-631.925 -413.176)"/>
                    <path id="Path_11665" d="M650.642 415.016h-2.357V414a.331.331 0 0 0-.332-.331h-2.357a.332.332 0 0 0-.331.331v1.011l-7.413.005v-1.01a.334.334 0 0 0-.333-.332h-2.367a.33.33 0 0 0-.331.332v1.01h-2.367a.333.333 0 0 0-.332.332l.015 18.194a.332.332 0 0 0 .332.332l18.188-.014a.333.333 0 0 0 .332-.332l-.014-18.184a.333.333 0 0 0-.327-.338zm-4.713-.669h1.682v2.023h-1.683zm-10.439.009h1.682v2.024h-1.682zm-2.69 1.344h2.019v1.011a.332.332 0 0 0 .332.331h2.363a.332.332 0 0 0 .332-.33V415.7l7.413-.005v1.01a.33.33 0 0 0 .331.331h2.357a.332.332 0 0 0 .331-.332v-1.01h2.021v3.028l-17.5.014zm.013 17.51l-.011-13.806 17.511-.014.011 13.806z" className="dailystats-3" data-name="Path 11665" transform="translate(-632.12 -413.673)"/>
                </g>
                <path id="Rectangle_2110" d="M0 0h11.456v8.408H0z" className="dailystats-2" data-name="Rectangle 2110" transform="translate(401.192 653.043)"/>
                <g id="Path_12150" className="dailystats-4" data-name="Path 12150">
                    <path d="M629.16 430.816a1.393 1.393 0 0 1-.983-2.375l7.9-7.9c.262-.262.612-.407.983-.407.372 0 .721.145.984.408l2.963 2.963 8.507-8.554h-.605a1.393 1.393 0 0 1-1.391-1.39c0-.768.624-1.392 1.39-1.392h3.967l.028.002c.751.02 1.345.63 1.347 1.386v3.953c0 .767-.624 1.39-1.391 1.39a1.393 1.393 0 0 1-1.391-1.39v-.578l-9.472 9.524c-.263.264-.614.41-.986.41s-.72-.145-.984-.407l-2.966-2.966-6.915 6.915a1.379 1.379 0 0 1-.985.408z" className="dailystats-5" transform="translate(-233.912 231.869)"/>
                    <path d="M629.16 430.412a.982.982 0 0 0 .7-.29l7.2-7.2 3.251 3.251a.986.986 0 0 0 1.4-.002l10.16-10.218v1.557a.987.987 0 1 0 1.975 0v-3.952a.986.986 0 0 0-.96-.984l-.027-.001h-3.95a.987.987 0 1 0 0 1.975h1.576l-9.477 9.529-3.25-3.25a.985.985 0 0 0-1.396 0l-7.9 7.899a.988.988 0 0 0 .698 1.686m0 .808a1.797 1.797 0 0 1-1.269-3.065l7.9-7.899c.34-.339.79-.526 1.27-.526.479 0 .93.187 1.269.526l2.676 2.677 7.568-7.61a1.798 1.798 0 0 1-1.46-1.763c0-.99.805-1.795 1.795-1.795h3.982l.024.002c.973.03 1.737.814 1.739 1.79v3.953c0 .99-.805 1.795-1.795 1.795-.865 0-1.59-.616-1.758-1.432l-8.818 8.867c-.34.342-.793.53-1.273.53-.48 0-.93-.187-1.269-.525l-2.681-2.681-6.63 6.63a1.78 1.78 0 0 1-1.27.526z" className="dailystats-6" transform="translate(-233.912 231.869)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
