import * as React from 'react'
import './clicks.css'

export const Clicks = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgweight} height={props.svgheight} className="panel-icon" viewBox="0 0 20.986 31.231">
    <g id="Group_6288" data-name="Group 6288" transform="translate(-44.678 -538.769)">
        <g id="Group_6267" data-name="Group 6267">
            <g id="Group_6257" data-name="Group 6257" transform="translate(46.788 544.835)">
                <path id="Path_12147" d="M648.582 364.432a1.17 1.17 0 0 0-1.119-1.215h-.035a1.172 1.172 0 0 0-1.134 1.208v.977a.4.4 0 1 1-.806 0v-1.8a1.114 1.114 0 1 0-2.217 0v1.913a.4.4 0 1 1-.806 0v-2.438a1.177 1.177 0 0 0-1.132-1.216h-.033a1.143 1.143 0 0 0-1.1 1.18v2.728a.4.4 0 1 1-.806 0v-8.99a1.133 1.133 0 1 0-2.259 0l-.007 10.335a.4.4 0 0 1-.715.255l-1.234-1.5a1.829 1.829 0 0 0-1.29-.683 1.785 1.785 0 0 0-1.344.483l-.015.013-.21.175 4.259 8.185a3.744 3.744 0 0 0 3.288 2.081h4.946a3.936 3.936 0 0 0 3.77-4.068V369.2c.003-1.931.004-2.642-.001-4.768z" className="clicks-1" data-name="Path 12147" transform="translate(-630.556 -351.345)"/>
                <path id="Path_12148" d="M649.362 363.312a2.074 2.074 0 0 0-2.021-2.12h-.036a1.941 1.941 0 0 0-1.307.5 2.039 2.039 0 0 0-1.893-1.382 1.965 1.965 0 0 0-1.4.6 2.01 2.01 0 0 0-1.793-1.148h-.038a1.853 1.853 0 0 0-1.154.379v-4.866a2.031 2.031 0 1 0-4.058 0l-.01 9.666-.546-.664a2.758 2.758 0 0 0-1.949-1.024 2.715 2.715 0 0 0-2.035.719l-.475.4a.422.422 0 0 0-.105.521l4.627 8.9a4.772 4.772 0 0 0 4.2 2.64h5.19a4.977 4.977 0 0 0 4.8-5.114v-2.993c.008-2.034.009-2.781.003-5.014zm-.844 5v3a4.131 4.131 0 0 1-3.958 4.269h-5.19a3.927 3.927 0 0 1-3.45-2.185l-4.47-8.59.221-.184.015-.014a1.876 1.876 0 0 1 1.411-.506 1.918 1.918 0 0 1 1.353.716l1.3 1.576a.423.423 0 0 0 .75-.268l.007-10.846a1.189 1.189 0 1 1 2.371 0v9.434a.423.423 0 0 0 .846 0v-2.862a1.2 1.2 0 0 1 1.154-1.239h.038a1.235 1.235 0 0 1 1.188 1.275v2.559a.423.423 0 1 0 .846 0v-2.007a1.17 1.17 0 1 1 2.327 0v1.893a.423.423 0 1 0 .846 0v-1.025a1.229 1.229 0 0 1 1.19-1.268h.036a1.228 1.228 0 0 1 1.175 1.275c-.003 2.229-.004 2.976-.005 4.997z" className="clicks-2" data-name="Path 12148" transform="translate(-630.49 -351.258)"/>
            </g>
            <g id="Group_6262" data-name="Group 6262">
                <path id="Path_404-2" d="M.533 0A.792.792 0 0 0 0 .178c-.008.357.174.357.174.714s.061.357.061.713.153.356.153.713-.016.356-.016.713-.134.357-.134.713-.179.356-.179.714-.059.358-.041.715a.9.9 0 0 0 .6.169c.147-.012.147-.105.174-.172.012-.356.15-.356.15-.712S.813 4.1.813 3.745s.068-.356.068-.712S.8 2.676.8 2.319s.262-.357.262-.713S.983 1.249.983.892 1.111.534 1.1.178A.916.916 0 0 0 .533 0z" className="clicks-3" data-name="Path 404-2" transform="rotate(33 -883.757 374.952)"/>
                <path id="Path_404-2-2" d="M.533 0A.783.783 0 0 0 0 .182c-.008.363.174.364.174.727s.061.363.061.727.153.363.153.727-.016.363-.016.727-.134.364-.134.727-.179.364-.179.728S0 4.91.018 5.273a.885.885 0 0 0 .6.172c.147-.013.147-.107.174-.175.012-.363.15-.363.15-.726s-.129-.363-.129-.727.068-.363.068-.726S.8 2.728.8 2.364s.262-.364.262-.727S.983 1.274.983.91 1.111.545 1.1.181A.9.9 0 0 0 .533 0z" className="clicks-3" data-name="Path 404-2" transform="rotate(-31 1000.74 190.783)"/>
                <path id="Path_405-2" d="M.381 0C.19 0 0 .3 0 .561c0 .864.08.864.08 1.729s.02.867.02 1.732-.013.866-.011 1.732c.006.257.11.4.3.389a.385.385 0 0 0 .374-.389c0-.864-.019-.864-.019-1.729S.66 3.158.66 2.293.765 1.426.765.561C.765.3.593.01.4.01.373 0 .381 0 .381 0z" className="clicks-3" data-name="Path 405-2" transform="translate(53.648 538.769)"/>
            </g>
        </g>
    </g>
</svg>
