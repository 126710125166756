import * as React from 'react'
import './linkedin.css'

export const LinkedInIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg"
  className={props.className || 'linkedin-icon'}
  viewBox="0 0 16.48 16.48"
  width={props.svgwidth || 'auto'} height={props.svgheight || 'auto'}
>
  <path id="linkedin" className="linkedinIcon" d="M14.989,0H1.491A1.491,1.491,0,0,0,0,1.491v13.5a1.491,1.491,0,0,0,1.491,1.491h13.5a1.491,1.491,0,0,0,1.491-1.491V1.491A1.491,1.491,0,0,0,14.989,0ZM5.1,14.229a.434.434,0,0,1-.434.434H2.819a.434.434,0,0,1-.434-.434V6.488a.434.434,0,0,1,.434-.434H4.666a.434.434,0,0,1,.434.434Zm-1.357-8.9A1.754,1.754,0,1,1,5.5,3.571,1.754,1.754,0,0,1,3.742,5.325Zm11.008,8.94a.4.4,0,0,1-.4.4H12.37a.4.4,0,0,1-.4-.4V10.633c0-.542.159-2.374-1.416-2.374-1.221,0-1.469,1.254-1.519,1.817v4.188a.4.4,0,0,1-.4.4H6.721a.4.4,0,0,1-.4-.4V6.453a.4.4,0,0,1,.4-.4H8.638a.4.4,0,0,1,.4.4v.675a2.72,2.72,0,0,1,2.559-1.2c3.173,0,3.155,2.964,3.155,4.593v3.747Z" transform="translate(0 -0.001)"/>
</svg>
