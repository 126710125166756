import * as React from 'react'
import './lightedit.css'

export const EditButtonLight = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
  <g id="Component_253_1" data-name="Component 253 – 1" transform="translate(0 0)">
    <g id="Group_5223" data-name="Group 5223" className="editbtn-1" transform="translate(0 -0.443)">
      <rect id="Rectangle_1935" data-name="Rectangle 1935"
      className="editbtn-2" width="30" height="30" rx="3" transform="translate(0 0.443)"/>
      <g id="edit_5_" data-name="edit (5)" transform="translate(7.093 7.146)">
        <path id="Path_11671" data-name="Path 11671" className="editbtn-3" d="M15.288,47.909a.412.412,0,0,0-.413.413v3.663a1.239,1.239,0,0,1-1.238,1.238H2.063A1.239,1.239,0,0,1,.825,51.984V41.234A1.239,1.239,0,0,1,2.063,40H5.725a.413.413,0,1,0,0-.825H2.063A2.065,2.065,0,0,0,0,41.234v10.75a2.065,2.065,0,0,0,2.063,2.063H13.638A2.065,2.065,0,0,0,15.7,51.985V48.322A.412.412,0,0,0,15.288,47.909Zm0,0" transform="translate(0 -37.567)"/>
        <path id="Path_11672" data-name="Path 11672" className="editbtn-3" d="M119.263.8a1.856,1.856,0,0,0-2.625,0l-7.36,7.36a.412.412,0,0,0-.106.182L108.2,11.84a.412.412,0,0,0,.508.508l3.494-.968a.412.412,0,0,0,.182-.106l7.359-7.36a1.858,1.858,0,0,0,0-2.625Zm-9.086,7.628L116.2,2.409l1.943,1.943-6.023,6.023Zm-.388.779,1.552,1.552-2.147.595Zm9.375-5.88-.437.437-1.943-1.943.438-.437a1.031,1.031,0,0,1,1.458,0l.484.484A1.033,1.033,0,0,1,119.164,3.331Zm0,0" transform="translate(-103.727 -0.261)"/>
      </g>
    </g>
  </g>
</svg>
