import * as React from 'react'
import './sources.css'
import './fonteva.css'

export const FontevaIcon = (props: any) =>
<svg className={`system-icon ${props.className}`} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		 width={props.width || '100%'} viewBox="0 0 899 243" enableBackground="new 0 0 899 243">
<path className="r-fonteva-icon" fill="#FFFFFF" opacity="1.000000" stroke="none"
	d="
M593.000000,244.000000
	C395.376495,244.000000 198.252975,244.000000 1.064730,244.000000
	C1.064730,163.060928 1.064730,82.121788 1.064730,1.091323
	C300.666656,1.091323 600.333313,1.091323 899.966309,1.091323
	C899.966309,82.080330 899.966309,163.040176 899.966309,244.000000
	C797.788452,244.000000 695.644226,244.000000 593.000000,244.000000
M695.869202,152.344193
	C696.068298,120.865852 671.046631,101.079803 645.249878,101.593697
	C613.962219,102.216980 591.392090,129.874969 597.526245,161.154831
	C605.578796,202.217667 649.914673,210.249680 676.039185,192.302612
	C681.959534,188.235458 686.671204,183.058029 689.480591,175.721664
	C683.936096,174.132767 678.884277,172.541367 673.742004,171.336319
	C672.749573,171.103760 670.872253,172.065292 670.302185,173.015366
	C665.166931,181.575378 657.361694,185.064041 647.764587,185.358429
	C633.106750,185.808044 619.439209,172.777908 620.423096,158.943939
	C645.193665,158.943939 669.990662,158.943939 694.948120,158.943939
	C695.280334,156.844330 695.564209,155.050247 695.869202,152.344193
M259.630981,154.514679
	C259.630981,151.090439 259.630981,147.666183 259.630981,143.810043
	C278.124268,143.810043 295.872681,143.810043 313.775574,143.810043
	C313.775574,137.480621 313.775574,131.621628 313.775574,125.246628
	C295.671570,125.246628 277.931122,125.246628 260.054352,125.246628
	C260.054352,112.878204 260.054352,101.036369 260.054352,88.599854
	C281.849365,88.599854 303.226868,88.599854 324.550262,88.599854
	C324.550262,81.917267 324.550262,75.840355 324.550262,69.779617
	C295.530060,69.779617 266.840271,69.779617 238.215973,69.779617
	C238.215973,113.068802 238.215973,155.946304 238.215973,198.774628
	C245.470566,198.774628 252.356705,198.774628 259.630524,198.774628
	C259.630524,184.108383 259.630524,169.810379 259.630981,154.514679
M389.550751,198.156433
	C413.497284,189.102310 426.221375,166.909866 421.850342,141.821823
	C417.748718,118.280212 398.464050,101.958572 374.093719,101.809952
	C353.430603,101.683929 337.432831,110.401909 328.702698,129.341278
	C319.818573,148.614792 321.727386,167.634735 335.700317,183.998734
	C349.827881,200.543839 368.293457,203.917694 389.550751,198.156433
M834.837280,117.617195
	C848.937073,119.012672 855.503418,128.094666 853.581482,143.680511
	C850.672180,142.985855 847.788696,142.223755 844.873352,141.613739
	C830.627502,138.632843 816.746155,139.212570 803.728821,146.426758
	C788.363525,154.942200 784.614746,175.288818 795.766174,188.940323
	C805.367249,200.693893 822.578369,204.734238 838.053162,198.054916
	C844.751221,195.163895 850.775452,190.711807 857.052002,187.000626
	C857.244751,187.833054 857.502869,189.460892 858.001038,191.011627
	C860.325439,198.247299 867.052063,201.376801 876.892639,199.753342
	C884.405579,198.513901 879.568298,192.594376 880.821167,188.981766
	C881.181519,187.942703 881.043762,186.632034 880.804810,185.516464
	C880.626282,184.682632 880.034790,183.408279 879.427246,183.280396
	C875.222900,182.395432 875.681335,179.090637 875.661499,176.190079
	C875.582825,164.699524 875.728821,153.207016 875.604614,141.717270
	C875.423523,124.962852 869.931458,111.034439 852.998535,104.873802
	C832.105530,97.272392 812.960876,102.433197 794.791748,114.233368
	C797.106445,119.094521 799.210144,123.512627 801.408081,128.128601
	C803.903320,126.659447 805.868164,125.425766 807.901978,124.318565
	C816.032043,119.892570 824.477722,116.644722 834.837280,117.617195
M497.137482,128.692825
	C498.088379,133.899307 499.735687,139.089325 499.859253,144.315369
	C500.225433,159.804764 499.990631,175.308350 499.990875,190.806396
	C499.990906,193.414856 499.990906,196.023315 499.990906,198.702332
	C507.306000,198.702332 514.041443,198.702332 520.808655,198.702332
	C520.808655,175.766846 521.307983,153.120026 520.497437,130.520203
	C520.261169,123.932243 517.504456,116.655281 513.771179,111.136108
	C506.247742,100.013573 494.301605,100.374489 482.620972,102.503754
	C471.708038,104.493088 462.581940,109.942612 454.761414,119.778091
	C454.761414,113.385132 454.761414,108.350700 454.761414,103.236580
	C448.102417,103.236580 442.006348,103.236580 435.701508,103.236580
	C435.701508,135.216354 435.701508,166.949799 435.701508,198.577454
	C442.919861,198.577454 449.805115,198.577454 456.873840,198.577454
	C456.873840,181.823654 457.328156,165.484085 456.730865,149.183044
	C456.248474,136.016968 463.417847,128.372269 473.793060,122.940598
	C483.740204,117.733032 491.532928,119.804512 497.137482,128.692825
M68.828445,101.497482
	C68.828445,73.719162 68.828445,45.940838 68.828445,17.653011
	C48.073399,18.779711 32.746208,27.731880 23.901993,45.805195
	C16.017818,61.916656 16.720219,78.436317 26.224224,93.909889
	C35.800247,109.500717 50.190136,117.029106 68.828362,117.839592
	C68.828362,112.434868 68.828362,107.465172 68.828445,101.497482
M20.737074,189.590973
	C27.443964,214.296677 52.490528,227.183090 68.439507,225.126114
	C68.439507,192.109558 68.439507,159.113968 68.439507,125.822815
	C53.917492,126.211647 41.758343,131.176743 32.269077,141.642700
	C20.008760,155.164948 16.361473,171.096054 20.737074,189.590973
M174.866470,32.151482
	C175.707504,27.648733 176.548553,23.145987 177.450562,18.316795
	C143.519363,18.316795 110.344246,18.316795 77.173111,18.316795
	C76.917297,43.103920 94.529388,63.607738 120.156433,67.487015
	C143.072998,70.956001 166.550552,57.587818 174.866470,32.151482
M94.633354,105.095207
	C83.469490,115.249596 77.281998,127.628517 77.108574,142.873947
	C110.734840,142.873947 143.762650,142.873947 176.978500,142.873947
	C176.725464,130.072815 172.603149,119.055542 164.379532,109.838585
	C147.630890,91.066864 117.691978,86.653229 94.633354,105.095207
M571.161621,180.927551
	C566.371155,179.456879 564.945618,175.724747 564.905151,171.259247
	C564.822205,162.099075 564.878113,152.937637 564.877563,143.776718
	C564.877014,136.003372 564.877441,128.230042 564.877441,119.987488
	C571.828003,119.987488 578.246704,119.987488 584.748535,119.987488
	C584.748535,114.147766 584.748535,108.790733 584.748535,102.910400
	C577.957886,102.910400 571.554626,102.910400 564.702087,102.910400
	C564.702087,92.462776 564.702087,82.544708 564.702087,72.521225
	C557.364685,72.521225 550.507690,72.521225 543.192261,72.521225
	C543.192261,82.828880 543.192261,92.745041 543.192261,103.145203
	C538.989319,103.145203 535.234009,103.145203 531.339111,103.145203
	C531.339111,109.020729 531.339111,114.392426 531.339111,120.187851
	C535.453918,120.187851 539.220825,120.187851 543.370911,120.187851
	C543.370911,122.416656 543.370911,124.222534 543.370972,126.028412
	C543.371216,143.184311 543.272400,160.340958 543.402161,177.495880
	C543.510498,191.825760 550.939453,199.721420 565.246948,200.749802
	C574.109375,201.386810 582.328003,198.643036 590.260681,195.032944
	C591.020874,194.687012 591.864014,193.136322 591.693604,192.358505
	C590.610413,187.413498 589.253174,182.528519 588.156372,178.282272
	C582.339539,179.273636 577.167358,180.155121 571.161621,180.927551
M715.104980,158.426010
	C719.486877,169.909912 724.048096,181.330093 728.163025,192.908875
	C729.822937,197.579391 731.996582,200.090881 737.371155,199.244476
	C740.783447,198.707108 744.379822,198.854187 747.838013,199.220062
	C751.832092,199.642624 753.614380,198.151779 755.023376,194.369095
	C763.372375,171.954742 772.047058,149.661880 780.570801,127.312401
	C783.565735,119.459503 786.443726,111.561966 789.583679,103.124138
	C783.788391,103.124138 778.794861,103.363358 773.837585,103.042412
	C770.369751,102.817894 768.868713,104.113876 767.802795,107.318687
	C763.976501,118.823280 759.870422,130.235001 755.854187,141.676239
	C751.535278,153.979599 747.198425,166.276642 742.549805,179.484573
	C733.411987,153.408722 724.630615,128.349930 715.880249,103.379700
	C708.599060,103.379700 701.673279,103.379700 694.192871,103.379700
	C701.192627,121.752098 708.037354,139.717606 715.104980,158.426010
z"/>
<path fill="#6C7078" opacity="1.000000" stroke="none"
	d="
M695.858643,152.800171
	C695.564209,155.050247 695.280334,156.844330 694.948120,158.943939
	C669.990662,158.943939 645.193665,158.943939 620.423096,158.943939
	C619.439209,172.777908 633.106750,185.808044 647.764587,185.358429
	C657.361694,185.064041 665.166931,181.575378 670.302185,173.015366
	C670.872253,172.065292 672.749573,171.103760 673.742004,171.336319
	C678.884277,172.541367 683.936096,174.132767 689.480591,175.721664
	C686.671204,183.058029 681.959534,188.235458 676.039185,192.302612
	C649.914673,210.249680 605.578796,202.217667 597.526245,161.154831
	C591.392090,129.874969 613.962219,102.216980 645.249878,101.593697
	C671.046631,101.079803 696.068298,120.865852 695.858643,152.800171
M645.500000,143.128113
	C654.906799,143.128113 664.313660,143.128113 673.770935,143.128113
	C672.218933,128.526825 659.567871,116.686096 644.640442,117.755127
	C630.733948,118.751038 619.326599,129.406235 619.188477,143.128189
	C627.622253,143.128189 636.061096,143.128189 645.500000,143.128113
z"/>
<path fill="#6D7179" opacity="1.000000" stroke="none"
	d="
M259.630737,155.013519
	C259.630524,169.810379 259.630524,184.108383 259.630524,198.774628
	C252.356705,198.774628 245.470566,198.774628 238.215973,198.774628
	C238.215973,155.946304 238.215973,113.068802 238.215973,69.779617
	C266.840271,69.779617 295.530060,69.779617 324.550262,69.779617
	C324.550262,75.840355 324.550262,81.917267 324.550262,88.599854
	C303.226868,88.599854 281.849365,88.599854 260.054352,88.599854
	C260.054352,101.036369 260.054352,112.878204 260.054352,125.246628
	C277.931122,125.246628 295.671570,125.246628 313.775574,125.246628
	C313.775574,131.621628 313.775574,137.480621 313.775574,143.810043
	C295.872681,143.810043 278.124268,143.810043 259.630981,143.810043
	C259.630981,147.666183 259.630981,151.090439 259.630737,155.013519
z"/>
<path fill="#6C7078" opacity="1.000000" stroke="none"
	d="
M389.173248,198.286407
	C368.293457,203.917694 349.827881,200.543839 335.700317,183.998734
	C321.727386,167.634735 319.818573,148.614792 328.702698,129.341278
	C337.432831,110.401909 353.430603,101.683929 374.093719,101.809952
	C398.464050,101.958572 417.748718,118.280212 421.850342,141.821823
	C426.221375,166.909866 413.497284,189.102310 389.173248,198.286407
M381.402985,181.351532
	C394.942139,177.163345 403.263550,161.575455 400.526245,145.529266
	C398.397552,133.050949 390.167114,123.138962 380.124481,120.959282
	C364.827454,117.639175 352.164886,125.649879 347.419983,141.878281
	C340.596466,165.216019 357.671478,187.165146 381.402985,181.351532
z"/>
<path fill="#6D7179" opacity="1.000000" stroke="none"
	d="
M834.416748,117.591629
	C824.477722,116.644722 816.032043,119.892570 807.901978,124.318565
	C805.868164,125.425766 803.903320,126.659447 801.408081,128.128601
	C799.210144,123.512627 797.106445,119.094521 794.791748,114.233368
	C812.960876,102.433197 832.105530,97.272392 852.998535,104.873802
	C869.931458,111.034439 875.423523,124.962852 875.604614,141.717270
	C875.728821,153.207016 875.582825,164.699524 875.661499,176.190079
	C875.681335,179.090637 875.222900,182.395432 879.427246,183.280396
	C880.034790,183.408279 880.626282,184.682632 880.804810,185.516464
	C881.043762,186.632034 881.181519,187.942703 880.821167,188.981766
	C879.568298,192.594376 884.405579,198.513901 876.892639,199.753342
	C867.052063,201.376801 860.325439,198.247299 858.001038,191.011627
	C857.502869,189.460892 857.244751,187.833054 857.052002,187.000626
	C850.775452,190.711807 844.751221,195.163895 838.053162,198.054916
	C822.578369,204.734238 805.367249,200.693893 795.766174,188.940323
	C784.614746,175.288818 788.363525,154.942200 803.728821,146.426758
	C816.746155,139.212570 830.627502,138.632843 844.873352,141.613739
	C847.788696,142.223755 850.672180,142.985855 853.581482,143.680511
	C855.503418,128.094666 848.937073,119.012672 834.416748,117.591629
M854.124634,161.671432
	C853.520874,159.795273 853.359375,156.623032 852.245178,156.242767
	C840.675049,152.294479 828.923584,150.718307 817.479858,156.653793
	C811.074036,159.976303 808.823425,165.011124 809.953796,171.947144
	C810.869324,177.564957 815.942200,182.605240 821.800598,184.053467
	C828.754028,185.772415 835.451172,184.617859 841.707642,181.766541
	C849.723328,178.113510 855.690063,172.672943 854.124634,161.671432
z"/>
<path fill="#6C7078" opacity="1.000000" stroke="none"
	d="
M496.969604,128.345978
	C491.532928,119.804512 483.740204,117.733032 473.793060,122.940598
	C463.417847,128.372269 456.248474,136.016968 456.730865,149.183044
	C457.328156,165.484085 456.873840,181.823654 456.873840,198.577454
	C449.805115,198.577454 442.919861,198.577454 435.701508,198.577454
	C435.701508,166.949799 435.701508,135.216354 435.701508,103.236580
	C442.006348,103.236580 448.102417,103.236580 454.761414,103.236580
	C454.761414,108.350700 454.761414,113.385132 454.761414,119.778091
	C462.581940,109.942612 471.708038,104.493088 482.620972,102.503754
	C494.301605,100.374489 506.247742,100.013573 513.771179,111.136108
	C517.504456,116.655281 520.261169,123.932243 520.497437,130.520203
	C521.307983,153.120026 520.808655,175.766846 520.808655,198.702332
	C514.041443,198.702332 507.306000,198.702332 499.990906,198.702332
	C499.990906,196.023315 499.990906,193.414856 499.990875,190.806396
	C499.990631,175.308350 500.225433,159.804764 499.859253,144.315369
	C499.735687,139.089325 498.088379,133.899307 496.969604,128.345978
z"/>
<path fill="#03A7E4" opacity="1.000000" stroke="none"
	d="
M68.828400,101.996483
	C68.828362,107.465172 68.828362,112.434868 68.828362,117.839592
	C50.190136,117.029106 35.800247,109.500717 26.224224,93.909889
	C16.720219,78.436317 16.017818,61.916656 23.901993,45.805195
	C32.746208,27.731880 48.073399,18.779711 68.828445,17.653011
	C68.828445,45.940838 68.828445,73.719162 68.828400,101.996483
z"/>
<path fill="#03A7E4" opacity="1.000000" stroke="none"
	d="
M20.566813,189.215225
	C16.361473,171.096054 20.008760,155.164948 32.269077,141.642700
	C41.758343,131.176743 53.917492,126.211647 68.439507,125.822815
	C68.439507,159.113968 68.439507,192.109558 68.439507,225.126114
	C52.490528,227.183090 27.443964,214.296677 20.566813,189.215225
z"/>
<path fill="#01A6E4" opacity="1.000000" stroke="none"
	d="
M174.729248,32.530720
	C166.550552,57.587818 143.072998,70.956001 120.156433,67.487015
	C94.529388,63.607738 76.917297,43.103920 77.173111,18.316795
	C110.344246,18.316795 143.519363,18.316795 177.450562,18.316795
	C176.548553,23.145987 175.707504,27.648733 174.729248,32.530720
z"/>
<path fill="#01A6E4" opacity="1.000000" stroke="none"
	d="
M94.889214,104.844040
	C117.691978,86.653229 147.630890,91.066864 164.379532,109.838585
	C172.603149,119.055542 176.725464,130.072815 176.978500,142.873947
	C143.762650,142.873947 110.734840,142.873947 77.108574,142.873947
	C77.281998,127.628517 83.469490,115.249596 94.889214,104.844040
z"/>
<path fill="#6D7179" opacity="1.000000" stroke="none"
	d="
M571.578369,180.982071
	C577.167358,180.155121 582.339539,179.273636 588.156372,178.282272
	C589.253174,182.528519 590.610413,187.413498 591.693604,192.358505
	C591.864014,193.136322 591.020874,194.687012 590.260681,195.032944
	C582.328003,198.643036 574.109375,201.386810 565.246948,200.749802
	C550.939453,199.721420 543.510498,191.825760 543.402161,177.495880
	C543.272400,160.340958 543.371216,143.184311 543.370972,126.028412
	C543.370911,124.222534 543.370911,122.416656 543.370911,120.187851
	C539.220825,120.187851 535.453918,120.187851 531.339111,120.187851
	C531.339111,114.392426 531.339111,109.020729 531.339111,103.145203
	C535.234009,103.145203 538.989319,103.145203 543.192261,103.145203
	C543.192261,92.745041 543.192261,82.828880 543.192261,72.521225
	C550.507690,72.521225 557.364685,72.521225 564.702087,72.521225
	C564.702087,82.544708 564.702087,92.462776 564.702087,102.910400
	C571.554626,102.910400 577.957886,102.910400 584.748535,102.910400
	C584.748535,108.790733 584.748535,114.147766 584.748535,119.987488
	C578.246704,119.987488 571.828003,119.987488 564.877441,119.987488
	C564.877441,128.230042 564.877014,136.003372 564.877563,143.776718
	C564.878113,152.937637 564.822205,162.099075 564.905151,171.259247
	C564.945618,175.724747 566.371155,179.456879 571.578369,180.982071
z"/>
<path fill="#6D7078" opacity="1.000000" stroke="none"
	d="
M714.993530,158.054565
	C708.037354,139.717606 701.192627,121.752098 694.192871,103.379700
	C701.673279,103.379700 708.599060,103.379700 715.880249,103.379700
	C724.630615,128.349930 733.411987,153.408722 742.549805,179.484573
	C747.198425,166.276642 751.535278,153.979599 755.854187,141.676239
	C759.870422,130.235001 763.976501,118.823280 767.802795,107.318687
	C768.868713,104.113876 770.369751,102.817894 773.837585,103.042412
	C778.794861,103.363358 783.788391,103.124138 789.583679,103.124138
	C786.443726,111.561966 783.565735,119.459503 780.570801,127.312401
	C772.047058,149.661880 763.372375,171.954742 755.023376,194.369095
	C753.614380,198.151779 751.832092,199.642624 747.838013,199.220062
	C744.379822,198.854187 740.783447,198.707108 737.371155,199.244476
	C731.996582,200.090881 729.822937,197.579391 728.163025,192.908875
	C724.048096,181.330093 719.486877,169.909912 714.993530,158.054565
z"/>
<path className="r-fonteva-icon-letter" fill="#FEFEFF" opacity="1.000000" stroke="none"
	d="
M645.000000,143.128143
	C636.061096,143.128189 627.622253,143.128189 619.188477,143.128189
	C619.326599,129.406235 630.733948,118.751038 644.640442,117.755127
	C659.567871,116.686096 672.218933,128.526825 673.770935,143.128113
	C664.313660,143.128113 654.906799,143.128113 645.000000,143.128143
z"/>
<path className="r-fonteva-icon-letter" fill="#FEFFFF" opacity="1.000000" stroke="none"
	d="
M380.984375,181.417801
	C357.671478,187.165146 340.596466,165.216019 347.419983,141.878281
	C352.164886,125.649879 364.827454,117.639175 380.124481,120.959282
	C390.167114,123.138962 398.397552,133.050949 400.526245,145.529266
	C403.263550,161.575455 394.942139,177.163345 380.984375,181.417801
z"/>
<path className="r-fonteva-icon-letter" fill="#FEFEFE" opacity="1.000000" stroke="none"
	d="
M854.124695,162.133911
	C855.690063,172.672943 849.723328,178.113510 841.707642,181.766541
	C835.451172,184.617859 828.754028,185.772415 821.800598,184.053467
	C815.942200,182.605240 810.869324,177.564957 809.953796,171.947144
	C808.823425,165.011124 811.074036,159.976303 817.479858,156.653793
	C828.923584,150.718307 840.675049,152.294479 852.245178,156.242767
	C853.359375,156.623032 853.520874,159.795273 854.124695,162.133911
z"/>
</svg>