import React from 'react'
import { AccountAndBilling } from './account-and-billing'
import { AddArticle } from './addArticle'
import { AddContacts } from './addcontacts'
import { AddSource } from './addSource'
import { AllContacts } from './allcontacts'
import { Articles } from './articles'
import { Books } from './books'
import { BulkArchive } from './bulk-archive'
import { BulkUnsubscribe } from './bulk-unsubscribe'
import { Clicks } from './clicks'
import { ContentHistory } from './contentHistory'
import { ContentTool } from './contentTool'
import { DailyStats } from './dailystats'
import { DayAndTime } from './day-and-time'
import { DomainAuthentication } from './domain-authentication'
import { EmailSent } from './emailSent'
import { Filters } from './filters'
import { Images } from './images';
import { Integrations } from './integrations'
import { LanguageIcon } from './language'
import { MainSchedule } from './main-schedule'
import { NewScheduledContentIcon } from './new-scheduled-content'
import { NewUpcomingSchedule } from './new-upcoming-schedule'
import { Newsletters } from './newsletters'
import { ProfileSettings } from './profilesettings'
import { Reports } from './reports'
import { Reserved } from './reserved'
import { SendSources } from './sendSources'
import { SmallPaintBrush } from './smallbrush'
import { Sources } from './sources'
import { TeamIcon } from './team'
import { TRexIcon } from './t-rex'
import { Topics } from './topics'
import { AiKnowledge } from './aiknowledge'

export const HeaderIcon = (props) => {
  switch (props.name) {
    case 'accountAndBilling':
      return <AccountAndBilling svgwidth={props.width} svgheight={props.height} />
    case 'add':
      return <AddContacts svgwidth={props.width} svgheight={props.height} />
    case 'addArticle':
      return <AddArticle svgwidth={props.width} svgheight={props.height} />
    case 'addNewSource':
      return <AddSource svgwidth={props.width} svgheight={props.height} />
    case 'aiKnowledge':
      return <AiKnowledge svgwidth={props.width} svgheight={props.height} />
    case 'allContacts':
      return <AllContacts svgwidth={props.width} svgheight={props.height} />
    case 'archive':
      return <BulkArchive svgwidth={props.width} svgheight={props.height} />
    case 'articles':
      return <Articles svgwidth={props.width} svgheight={props.height} />
    case 'books':
      return <Books svgwidth={props.width} svgheight={props.height} />
    case 'clicks':
      return <Clicks svgwidth={props.width} svgheight={props.height} />
    case 'contentHistory':
      return <ContentHistory svgwidth={props.width} svgheight={props.height} />
    case 'contentTool':
      return <ContentTool svgwidth={props.width} svgheight={props.height} />
    case 'dailyStats':
      return <DailyStats svgwidth={props.width} svgheight={props.height} />
    case 'dayAndTime':
      return <DayAndTime svgwidth={props.width} svgheight={props.height} />
    case 'design':
      return <SmallPaintBrush svgwidth={props.width} svgheight={props.height} />
    case 'domainAuthentication':
      return <DomainAuthentication svgwidth={props.width} svgheight={props.height} />
    case 'emailSent':
      return <EmailSent svgwidth={props.width} svgheight={props.height} />
    case 'filters':
      return <Filters svgwidth={props.width} svgheight={props.height} />
    case 'images':
      return <Images svgwidth={props.width} svgheight={props.height} />
    case 'integrations':
      return <Integrations svgwidth={props.width} svgheight={props.height} />
    case 'language':
      return <LanguageIcon svgwidth={props.width} svgheight={props.height} />
    case 'newsletters':
      return <Newsletters width={props.width} height={props.height} />
    case 'profileSettings':
      return <ProfileSettings svgwidth={props.width} svgheight={props.height} />
    case 'schedule':
      return <MainSchedule svgwidth={props.width} svgheight={props.height} />
    case 'scheduledContent':
      return <NewScheduledContentIcon svgwidth={props.width} svgheight={props.height} />
    case 'sources':
      return <Sources svgwidth={props.width} svgheight={props.height} />
    case 'team':
        return <TeamIcon svgwidth={props.width} svgheight={props.height} />
    case 'topics':
      return <Topics svgwidth={props.width} svgheight={props.height} />
    case 'upcomingSchedule':
      return <NewUpcomingSchedule svgwidth={props.width} svgheight={props.height} />
    case 'reports':
      return <Reports svgwidth={props.width} svgheight={props.height} />
    case 'reserved':
      return <Reserved svgwidth={props.width} svgheight={props.height} />
    case 'sendSources':
      return <SendSources svgwidth={props.width} svgheight={props.height} />
    case 'interests':
      return <AddContacts svgwidth={props.width} svgheight={props.height} />
    case 'unsubscribe':
      return <BulkUnsubscribe svgwidth={props.width} svgheight={props.height} />
    case 'trex':
    case 't-rex':
        return <TRexIcon svgwidth={props.width} svgheight={props.height} />
    default:
      return <div/>
  }
}
