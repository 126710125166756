import * as React from 'react'
import './lightening3.css'

export const LighteningEmpty = (props: any) =>
<svg id="Component_85_1" data-name="Component 85 – 1" xmlns="http://www.w3.org/2000/svg" width="14.255" height="25.112" viewBox="0 0 14.255 25.112">

  <g id="Group_3222" data-name="Group 3222">
    <path id="Path_2653" data-name="Path 2653" className="ltl-1" d="M111.121,24.112a.314.314,0,0,1-.209-.042.418.418,0,0,1-.167-.5l4.688-11.175h-4.311a.5.5,0,0,1-.377-.209.38.38,0,0,1,0-.419L117.023-.791A.44.44,0,0,1,117.4-1h6.7a.44.44,0,0,1,.377.209.38.38,0,0,1,0,.419l-4.9,7.743h4.939a.4.4,0,0,1,.377.251.449.449,0,0,1-.042.46l-13.393,15.9A.475.475,0,0,1,111.121,24.112Zm.67-12.556h4.269a.517.517,0,0,1,.335.167.407.407,0,0,1,.042.377l-3.851,9.208,11.049-13.1H118.78A.44.44,0,0,1,118.4,8a.38.38,0,0,1,0-.419l4.939-7.743H117.65Z" transform="translate(-110.682 1)"/>
  </g>
</svg>
