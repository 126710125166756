import * as React from 'react'
import './openlink.css'

export const OpenLinkIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="foreign_2_" width="12.306" height="12.306" data-name="foreign (2)" viewBox="0 0 12.306 12.306">
    <g id="Group_5077" data-name="Group 5077" transform="translate(4.475)">
        <g id="Group_5076" data-name="Group 5076">
            <path id="Path_11932" d="M193.454 0h-4.475a.559.559 0 1 0 0 1.119h3.121l-5.757 5.757a.559.559 0 1 0 .791.791l5.757-5.757v3.124a.559.559 0 1 0 1.119 0V.559a.559.559 0 0 0-.556-.559z" className="open-link-1" data-name="Path 11932" transform="translate(-186.182)"/>
        </g>
    </g>
    <g id="Group_5079" data-name="Group 5079" transform="translate(0 2.237)">
        <g id="Group_5078" data-name="Group 5078">
            <path id="Path_11933" d="M9.509 96.447a.559.559 0 0 0-.559.559v5.034H1.119v-7.83h5.034a.559.559 0 1 0 0-1.119H.559A.559.559 0 0 0 0 93.65v8.95a.559.559 0 0 0 .559.559h8.95a.559.559 0 0 0 .559-.559v-5.594a.559.559 0 0 0-.559-.559z" className="open-link-1" data-name="Path 11933" transform="translate(0 -93.091)"/>
        </g>
    </g>
</svg>
