import * as React from 'react'
import './lightening1.css'

export const LighteningFull = (props: any) =>
<svg id="Component_82_1" data-name="Component 82 – 1" xmlns="http://www.w3.org/2000/svg" width="15.021" height="26.3" viewBox="0 0 15.021 26.3">
  <g id="Group_3756" data-name="Group 3756" transform="translate(-23.219 -2.97)">
    <g id="Group_3222" data-name="Group 3222" transform="translate(23.219 2.97)">
      <path id="Path_2650" data-name="Path 2650" className="lt-1" d="M133.425,16.176h-5.9l5.47-8.643H126.1L119.642,20.5h5.083l-5.083,12.1Z" transform="translate(-118.405 -6.299)"/>
      <path id="Path_2653" data-name="Path 2653" className="lt-2" d="M111.121,24.112a.314.314,0,0,1-.209-.042.418.418,0,0,1-.167-.5l4.688-11.175h-4.311a.5.5,0,0,1-.377-.209.38.38,0,0,1,0-.419L117.023-.791A.44.44,0,0,1,117.4-1h6.7a.44.44,0,0,1,.377.209.38.38,0,0,1,0,.419l-4.9,7.743h4.939a.4.4,0,0,1,.377.251.449.449,0,0,1-.042.46l-13.393,15.9A.475.475,0,0,1,111.121,24.112Zm.67-12.556h4.269a.517.517,0,0,1,.335.167.407.407,0,0,1,.042.377l-3.851,9.208,11.049-13.1H118.78A.44.44,0,0,1,118.4,8a.38.38,0,0,1,0-.419l4.939-7.743H117.65Z" transform="translate(-110.682 1)"/>
    </g>
  </g>
</svg>
