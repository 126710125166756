import * as React from 'react'
import './lightening2.css'

export const LighteningActive = (props: any) =>
<svg id="Component_81_1" data-name="Component 81 – 1" xmlns="http://www.w3.org/2000/svg" width="60.203" height="32.617" viewBox="0 0 60.203 32.617">

  <g id="Group_4791" data-name="Group 4791">
    <g id="Group_3222" data-name="Group 3222" transform="translate(23.219 2.97)">
      <path id="Path_2650" data-name="Path 2650" className="lts-1" d="M133.425,16.176h-5.9l5.47-8.643H126.1L119.642,20.5h5.083l-5.083,12.1Z" transform="translate(-118.405 -6.299)"/>
      <path id="Path_2653" data-name="Path 2653" className="lts-2" d="M111.121,24.112a.314.314,0,0,1-.209-.042.418.418,0,0,1-.167-.5l4.688-11.175h-4.311a.5.5,0,0,1-.377-.209.38.38,0,0,1,0-.419L117.023-.791A.44.44,0,0,1,117.4-1h6.7a.44.44,0,0,1,.377.209.38.38,0,0,1,0,.419l-4.9,7.743h4.939a.4.4,0,0,1,.377.251.449.449,0,0,1-.042.46l-13.393,15.9A.475.475,0,0,1,111.121,24.112Zm.67-12.556h4.269a.517.517,0,0,1,.335.167.407.407,0,0,1,.042.377l-3.851,9.208,11.049-13.1H118.78A.44.44,0,0,1,118.4,8a.38.38,0,0,1,0-.419l4.939-7.743H117.65Z" transform="translate(-110.682 1)"/>
    </g>
    <g id="Group_476" data-name="Group 476">
      <g id="Group_469" data-name="Group 469" transform="translate(8.796)">
        <path id="Path_218" data-name="Path 218" className="lts-3" d="M840.561,92.19c-.49-.526-.748-1.275-1.252-1.787s-1.148-.887-1.666-1.385-1.163-.857-1.695-1.34-1.054-.97-1.6-1.438c-.425-.365-.988.183-.56.55.545.468,1.173.843,1.7,1.326s.91,1.13,1.427,1.628,1.2.833,1.7,1.345.982,1.052,1.472,1.578C840.477,93.08,840.944,92.6,840.561,92.19Z" transform="translate(-833.636 -86.12)"/>
      </g>
      <g id="Group_470" data-name="Group 470" transform="translate(0 15.797)">
        <path id="Path_219" data-name="Path 219" className="lts-3" d="M812.689,143.063c-.682-.025-1.371.153-2.053.128s-1.367.035-2.048.01-1.357-.243-2.038-.268-1.364-.028-2.046-.053-1.38.386-2.061.361c-.562-.021-.544.36.018.38.682.025,1.352.357,2.034.382s1.366-.011,2.047.014,1.363.055,2.045.08,1.356.261,2.038.286,1.379-.382,2.061-.357C813.248,144.049,813.25,143.084,812.689,143.063Z" transform="translate(-802.03 -142.879)"/>
      </g>
      <g id="Group_471" data-name="Group 471" transform="translate(14.279 24.407)">
        <path id="Path_220" data-name="Path 220" className="lts-3" d="M856.782,174.018c-.3.589-.557,1.2-.857,1.787s-.481,1.238-.781,1.826-.574,1.19-.874,1.778-.577,1.189-.877,1.777c-.255.5.458.922.714.42.3-.588.593-1.18.893-1.769s.39-1.284.69-1.872.556-1.2.856-1.788.861-1.044,1.161-1.632C857.961,174.046,857.037,173.517,856.782,174.018Z" transform="translate(-853.337 -173.816)"/>
      </g>
    </g>
    <g id="Group_477" data-name="Group 477" transform="translate(41.744)">
      <g id="Group_475" data-name="Group 475">
        <g id="Group_472" data-name="Group 472" transform="translate(2.641 25.561)">
          <path id="Path_221" data-name="Path 221" className="lts-3" d="M1122.022,178.8c-.384-.413-.961.169-.578.58.49.526.9,1.119,1.409,1.631s1,1.034,1.514,1.532,1.157.861,1.689,1.344.814,1.249,1.359,1.717c.425.365,1.047-.253.62-.62-.545-.468-.868-1.189-1.4-1.671s-1.2-.816-1.72-1.314-1.077-.958-1.581-1.47S1122.511,179.327,1122.022,178.8Z" transform="translate(-1121.318 -178.66)"/>
        </g>
        <g id="Group_473" data-name="Group 473" transform="translate(7.386 15.181)">
          <path id="Path_222" data-name="Path 222" className="lts-3" d="M1149.03,141.652c-.682-.025-1.365-.005-2.047-.03s-1.357-.233-2.038-.258-1.372.186-2.054.161-1.376.293-2.058.268-1.356-.25-2.038-.275c-.562-.021-.566.963,0,.984.682.025,1.372-.185,2.053-.16s1.365,0,2.047.028,1.355.289,2.036.314,1.363.065,2.044.09,1.363.062,2.045.087C1149.58,142.88,1149.591,141.673,1149.03,141.652Z" transform="translate(-1138.372 -141.362)"/>
        </g>
        <g id="Group_474" data-name="Group 474">
          <path id="Path_223" data-name="Path 223" className="lts-3" d="M1115.3,87.019c-.3.588-.31,1.325-.61,1.913s-.836,1.056-1.136,1.645-.578,1.188-.878,1.776-.5,1.226-.8,1.815c-.255.5.592.99.848.488.3-.588.282-1.339.582-1.927s.663-1.145.963-1.733.5-1.227.8-1.815.78-1.085,1.08-1.673C1116.406,87.007,1115.559,86.517,1115.3,87.019Z" transform="translate(-1111.829 -86.808)"/>
        </g>
      </g>
    </g>
  </g>
</svg>
