import * as React from 'react'
import './higherLogic.scss'

export const HigherLogicIcon = (props: any) =>
<svg
	className={`system-icon r-higher-logic ${props.className}`}
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 717.7 108.1"
>
	<g>
		<path className="st0" d="M139.3,13.6h16.1v28.6c3.7-4.8,8.5-9.1,16.6-9.1c12.2,0,19.3,8.1,19.3,21.1V91h-16.1V59.3c0-7.6-3.6-11.6-9.8-11.6c-6.1,0-10.1,3.9-10.1,11.6V91h-16.1V13.6z"/>
		<path className="st0" d="M200.3,13.6h17v14.3h-17V13.6z M200.7,34.2h16.1V91h-16.1V34.2z"/>
		<path className="st0" d="M227,102.2l5.5-12.1c5.8,3.3,11.7,5.2,19.2,5.2c11,0,16.2-5.3,16.2-15.5v-2.8c-4.8,5.7-10,9.2-18.6,9.2c-13.3,0-25.2-9.6-25.2-26.5v-0.2c0-17,12.2-26.5,25.2-26.5c8.8,0,14,3.7,18.3,8.5v-7.4h16.1v44c0,10.2-2.4,17.6-7.2,22.4c-5.3,5.3-13.5,7.6-24.5,7.6C242.9,108.1,234.2,106,227,102.2z M267.9,59.8v-0.2c0-7.7-6-13.1-13.9-13.1c-7.8,0-13.8,5.4-13.8,13.1v0.2c0,7.8,5.9,13.1,13.8,13.1C261.9,72.9,267.9,67.5,267.9,59.8z"/>
		<path className="st0" d="M293,13.6h16.1v28.6c3.7-4.8,8.5-9.1,16.6-9.1c12.2,0,19.3,8.1,19.3,21.1V91h-16.1V59.3c0-7.6-3.6-11.6-9.8-11.6c-6.1,0-10.1,3.9-10.1,11.6V91H293V13.6z"/>
		<path className="st0" d="M350.9,62.9v-0.2c0-16.2,11.6-29.6,28.1-29.6c19,0,27.7,14.7,27.7,30.8c0,1.3-0.1,2.8-0.2,4.2h-39.5c1.6,7.3,6.7,11.1,13.9,11.1c5.4,0,9.3-1.7,13.8-5.8l9.2,8.2c-5.3,6.6-12.9,10.6-23.2,10.6C363.6,92.2,350.9,80.3,350.9,62.9zM391,58.1c-1-7.2-5.2-12.1-12-12.1c-6.7,0-11,4.8-12.3,12.1H391z"/>
		<path className="st0" d="M413.4,34.2h16.1v11.4c3.3-7.8,8.6-12.9,18.1-12.5v16.9h-0.8c-10.7,0-17.3,6.5-17.3,20v21h-16.1V34.2z"/>
		<path className="st0" d="M482.8,13.6h16.1V91h-16.1V13.6z"/>
		<path className="st0" d="M506.3,62.9v-0.2c0-16.3,13.1-29.6,30.8-29.6c17.6,0,30.6,13,30.6,29.4v0.2c0,16.3-13.1,29.6-30.8,29.6C519.3,92.2,506.3,79.2,506.3,62.9z M551.8,62.9v-0.2c0-8.4-6-15.7-14.9-15.7c-9.2,0-14.7,7.1-14.7,15.5v0.2c0,8.4,6,15.7,14.9,15.7C546.3,78.4,551.8,71.3,551.8,62.9z"/>
		<path className="st0" d="M574.6,102.2l5.5-12.1c5.8,3.3,11.7,5.2,19.2,5.2c11,0,16.2-5.3,16.2-15.5v-2.8c-4.8,5.7-10,9.2-18.6,9.2c-13.3,0-25.2-9.6-25.2-26.5v-0.2c0-17,12.2-26.5,25.2-26.5c8.8,0,14,3.7,18.3,8.5v-7.4h16.1v44c0,10.2-2.4,17.6-7.2,22.4c-5.3,5.3-13.5,7.6-24.5,7.6C590.5,108.1,581.8,106,574.6,102.2z M615.5,59.8v-0.2c0-7.7-6-13.1-13.9-13.1c-7.8,0-13.8,5.4-13.8,13.1v0.2c0,7.8,5.9,13.1,13.8,13.1C609.5,72.9,615.5,67.5,615.5,59.8z"/>
		<path className="st0" d="M641.1,13.6h17v14.3h-17V13.6z M641.5,34.2h16.1V91h-16.1V34.2z"/>
		<path className="st0" d="M664.9,62.9v-0.2c0-16.2,12.4-29.6,29.8-29.6c10.7,0,17.4,3.6,22.7,9.5l-9.9,10.6c-3.6-3.8-7.2-6.3-12.9-6.3c-8.1,0-13.8,7.1-13.8,15.5v0.2c0,8.7,5.6,15.7,14.4,15.7c5.4,0,9.1-2.3,13-6l9.4,9.5c-5.5,6-11.9,10.4-23.2,10.4C677.4,92.2,664.9,79.1,664.9,62.9z"/>
	</g>
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-1.5967" y1="102.2902" x2="99.0968" y2="1.5967">
		<stop  offset="0" className="stop0" />
		<stop  offset="1" className="stop1"/>
	</linearGradient>
	<path className="st1" d="M97.5,0v58.4h-13V13H39.1V0H97.5z M58.4,103.9v-13H13V45.5H0v58.4H58.4z M58.4,26.4v19.1H39.1V26.4h-13v51.2h13V58.5h19.2v19.1h13V26.4H58.4z"/>
</svg>
