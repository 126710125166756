import * as React from 'react'
import './sources.css'

export const ImisIcon = (props: any) =>
<svg className={`system-icon ${props.className}`} version="1.1" id="imis-icon" xmlns="http://www.w3.org/2000/svg"
     x="0px" y="0px" width="64px" height="64px"
     viewBox="0 0 64 64"
     enable-background="new 0 0 64 64"
>
    <image id="image0" width="64" height="64" x="0" y="0"
    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAM
0ElEQVR42u2bTawlx1XHf+dU97333fvem/HMeMaT2BoyMY5jHBSTCAnLRCRBiZUQJSFOFihKJEBi
wYIVCxCLLBASmyiwwwIhCwEbR7EIkRwkGxNNEmJEAsk4/og9M8bE45nxfL13P7u76rCo7vv1vu7X
iChQUr37+t6uU+f8+9T5qmr4//Z/u8l+P378yVfvaWfhk97kg+3Cvx1jY+GZzGbgRg6+52CJttdT
fSUVnmql+pUnHj710twAfOgfLxzLvP1RJw+/5Y31tRSOJMp6KtRm5XFKYGN/AGQ3VuYEJDNo58a1
ItDLwQntzZr+1WYt+eOvPHzXmzMB8Lv/cvE9/3al/5i38HOHasrplnKiAWuqpDPyY5XwZlFws9kV
QCSCUQovc4KQG/RC4FIfznUCNzNIlOd+6UTj81966OS/7wvAp5589YGrA3uyndnxt20o71xXGgoG
bBdwrQi0c8jDPtKUkpoFLBgWPFjAQtgJ0LiAIogIog5EEacjrZgBhFSF9VJTN5IoWD/A823P+W2j
lcrl29fcw49/+K7v7QrAr3711ds6efhWHrj3vkPCO1oOgHYReLlrvNE3Bp79FXlMeELAfI4VBebL
HsLutkAEUUVcEnuSxq46YnEGEASoO7ijIdzdFNYTBeDFjueHN41UeeFQqg8++bFT16sxSfVPEewL
uXHv6fUovBlczAI/2Ar0CnACyUzaaJgZwedYPsAP+ljW5zfffSf3HGvhh9pjQ+GiFgjiFNSROMd/
Xh3w5fPtEQgzroQ8wIWOcWlgvGsTTtaUd7Qcfe8517F7e8G+APzehAZ87Guv/uyVfvjeekrrwSMJ
NYE3ssB3rweCgc5h+MwC5gtCNsD3OvjuNr67zV//xi/znlPHZyQEz7x2kz/49iXEOUR0boNY8f0L
tyl31JTM4FvXCto5naMNfeBrHz31IwAFuD4InwRab2sqdYWOh+e2ouFKJN50YDcrP0FDQIocBj1C
dwu/fYNerzeXAIM8oMFHeuP0Z+yJxNX23JbR8VBXON1UgNZWbr9ezaMAhckHWqlwohHXzCvdQM9D
4iTCOHOPFE0MM0/wOSHrU/S2CD6fCwAwTCqJ5uUj9sQJPR/lATjeUJqpUATeX81S2YC7N1OhodAu
4MrAKO3HrEtvookBIUBpByzrx+s5Wj2JPqDqi7ZEozztAloJHE6FbmF3TwDghM11Bw64UUDmQXXB
ic2QshNKb1AUYLMDcK2b8dSFG6jI0gBAlOdGAZsJtBwobE4AAAyDnE4RGV120irysxCiW5whEAL4
9oXLfPE7r/F6kaK1xsj4LRgmV6OiXDGYG6eUVDdJ+U9hy6vdcLzZzLQGecFffPMF/u6HV9DmBq6R
oM7NHQnuxU9Ret1pfkoABIn2sLxBFgLApigM/bu4fZ/g869f5U+fPsvzNz2uuYGrr6EuneBrmUcy
LrSgCKPlONSA3QYtN2HJvsYobzcAzODvn32RR589Tz9pkLY20VoDTWqIc6BaauYKssQ9ZNsVgJUs
AQEtw1uX1FB1E/e8dm2LLz19ljM/3satbZA2mmhaR5N0THhZifCyz3UEYMzii5TXC84rpQ9WVdQl
uLRGWFuLQpXt62cv8OdnXuRaSEk3DkWVT2uIK+N/HU+ElpZ/Qh4prycBOACx+WeMWZ2kKVpfIwme
9WaTdj/jz575Af/wwmVco0nSbOJqjZj4uGQX4Vej+gdrQHlRpSeLLgFBIg0RNElwtQastXBJwrP/
fZ0vnnmJH10fkK4fQtNaVHd1cb7SYxDCCASzlS0BYeSJdwIgOvalxmUw97xWDUcdkBhJ3RBRrMh5
7PsxsUmaG8OnLQgEH9NkFwsnoi7OrXJLbICU8k4AMCiDhHLexZaAjJ6YqKJJAtRBBEtrE3UA8x4r
8lgYCYF66jh9dJ07Nte4PDBe3i4QEkw1htVLgjAulwgUfsoN/tdWzuFUOFpTuoXiDVIdaUWVws8S
zAmCiYA61EW1tiInVEWRoiDkGaHIONJw/Np9b+VD73wrP3NkHRXBm/E3Z9/kb1/aKg2Wm3spVIJW
IwYWGHjoZMYbPc+F7ZEk4wURupnnjY7ntY7QTKCRKA0n1BMhFR0mSMMnaTtBsVIbRMpww4zgPZZn
hEEfP+jSEs8n3nUXn/nFe7h9ozkx3onwufuP8eylDq+0I0WtbMsugk7XSrxBYYEsh743eoXRLQJ5
0zjhoJ9P5mWlG4zmSwRUhBCgV0C3CEP1SURJHdScUHNQUyF14ERxU0xYCLEWGDw+68OgS+i1SfMB
H7nnOJ998D7uOnpozyeoIrz39jXOb7Ujb7JzXVaCFkUshGbeyAojC5CHWMip5Awh0hQxoqORKQDG
np4QM8HpKlBeotop03op03+nEYREwYnFAooFpMiwrIf1twmdLR66c4Pfed97ufctx2ZS46NrjnZW
YF4IAh7wIWpqJbwPcXmO12iH6i8xu42IHuQFODgWkN2+BIoABXGNmVlMe4sCy/uEXhvfvknYvs5n
Pv7AzMIDmAk/budIIojaaGlN8STEeuWsbddkSFGiwhhi8Vpnp1lxDMjQ2IfCY1kGvS7W2aLfH8xF
TjGcSZm8LFcaUUCG9QiZkG5HJFgtt8WmMsCiXw8eihzyPjboxus52yoqQkM6Mnk9CcAqcoFxdxBr
YmAF+Dz2OSpCQzJikZclM2IZ86Iz5wILo25VVSBGdpQ7Q3MzzWo1YK/rGAeYjKykgdgCUw73OyT2
QGmeBQs2c0lskqaM+hJQSFyZo1jCpt3glGldLBcYg3eChpVhzPwIyDQviy6BqbHjsu00gqzQ8CxJ
Z9W8TAAyDYDsM2BZ5hdLrW+NDZimN7e7/2lrMRCSKTexIhsw/GpBFVilDRh387rTBlSbcH4YeS1T
hK52A8b/LkpLhtws4QWgLK97QCekS/YesHgTfnJtwHQbusHSY8UoacklMKG6S6jvqpYAlHLZaIlX
TcfpjhdFf5rabEVRCcTwzZXX805TBjti5fiYVEh1+GluWKWko2URc3G1jKOifCJuZ1F0R6TEam3A
/xaNSQBGFztzgT0mXgUALEHrVkWC421HJLgCe7MSL7BKAKbl2sUGTN1chQXztPHTb9XxvupzUT84
fuqJBekAMpWN70yGdNxESVmJXWyyWBJnaPhkwbByFJGOzhksbATHfJ2U8k4CMIWQhsmqyUxtTAPE
drlehHEb9WWaTmvA9L7ACJ/JnGDRttv6XzYaXLbtJddKd4f3AuAnIRQ+YF9Ah78mUiUO8zYb65Xl
WhaCVVjTOCoZe8qyS1ncCotf1p0tfkZwbMJln1zUxtWcE1SNcgEUpshYfa4qi9/ITI97lFYSSDXg
TReauKpfVtvRKoIuYL2zIgxPvS6TDBmQSKCVBDxKZoooN4bgAKjwcrcQ8iA01Dhc8wRbEvmh+2Kh
CsuNgV8iJR3hFQwO1z0NNfIgdAtBhZcnAHDwVB6UrSJukR1v5NRdIIzxPnPX6rN6CcItdODx3I3B
cGdYhrHFfD0AdRc4Xs+BwFah5EFx8NQEACbyBCJbVwd1ihCPzN/ZylEVwq42/aCu8XCkJvHMX1rf
cUxuX+GvdfnX13uRxrDGpnPxEIhnFO9s5dQViqBcHdRBZMtEnpgA4MynT55zwqN9L1wa1DGDzcRz
aq1PXY1Q7i8fjHr5tFx88q5WxzXWSJobZAZZ4ell+Y7e6Wdc7/S58OY2X33uIr//T+fZ9uVRG6dz
PXUEAkpdjVNrfTYTjxlcGtTpe8EJj5759Mlz48sEgPc9fvEQcMbg/hONjNvr8SBAFoQ3BzW2Mkdh
svf2RvW+UPBY8IRBj6K3TdG+ie/cxPc7hCLDfDE6SyTle0JpDVdv4pqbJM2NeFy2tjb53tCM7wwl
YmzWPMfqGTWNPF0ZpFzq1xA4Czz0jUdO3twBQAnC/QJfD8ZbjtYzjtdzEg0YShaEbuHIAvi9ts7K
1+TMe0Ke4bM+vt+l6HcJ+YBQFMMXqoYvSqkiLsXVGrj6Gq7ewNVHx2VnPSnmxKgpNBNPTQ0hUATl
8iDl6qCGCq8bfPgbj5w8Ow3aRPuVxy/+PPBYQN/dUM+ResZ6UpAK6HgQvZsqlFoQyiMyocjxeR4/
i3z41piZDZOkuGRceao0RZMaOjSc1VsbBwAw9nMwJTdoFwnXBjX6waGE/wA+/8wjJ7+/z9BRe//j
F29D9A8D/DbG4VQDjcTTEEP1gH3+6mXJEDCL7w2a96PTIzACoGShOkwt6sousws/1kJw9E3oF448
KAg3FP4SC3/yz4+cvL7bmH2pf+DLl04DnzD4oBlvR7jtoDHjmlABEQ9NGBPbsiMWLKanI0u2xFFZ
w7guwisSXd0TT3/qxLn9BtzKArDs8f/ezO9/fUva/wAME0Ru1cH6nAAAACV0RVh0ZGF0ZTpjcmVh
dGUAMjAyMS0xMC0wNVQyMTo0NDo1NCswMzowMC9arysAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEt
MTAtMDVUMjE6NDQ6NTQrMDM6MDBeBxeXAAAAAElFTkSuQmCC" />
</svg>
