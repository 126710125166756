import * as React from 'react'
import './excludefilter.css'

export const ExcludeFilter = (props: any) =>
  <div className="excludefilter">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.976 76.976">
<g id="Group_6250" data-name="Group 6250" transform="translate(-108.056 -141.056)">
        <g id="Group_6249" data-name="Group 6249">
            <g id="Component_390_1" data-name="Component 390 – 1" transform="translate(108.056 141.056)">
                <g id="Group_6225" data-name="Group 6225" transform="rotate(18.01 1072.664 -1322.283)">
                    <g id="Group_6223" data-name="Group 6223" transform="translate(489.494 270.795)">
                        <path id="Path_12126" d="M104.7 70.712a2.6 2.6 0 0 0-2.6 2.6v-3.467a2.6 2.6 0 1 0-5.2 0v-3.468a2.6 2.6 0 0 0-5.2 0v3.467a2.6 2.6 0 1 0-5.2 0v18.2l-6.019-3.01a4.335 4.335 0 0 0-1.938-.458 2.444 2.444 0 0 0-2.444 2.444v.01a2.444 2.444 0 0 0 .716 1.734l11.21 11.21a12.552 12.552 0 0 0 8.878 3.672 10.4 10.4 0 0 0 10.4-10.4V73.312a2.6 2.6 0 0 0-2.603-2.6z" className="cls-1" data-name="Path 12126" transform="translate(-73.585 -60.572)"/>
                        <g id="Group_6222" data-name="Group 6222" transform="translate(16.47 1.543)">
                            <path id="Path_12127" d="M223.991 73.937a.867.867 0 0 0 .867-.867V56.41a2.543 2.543 0 0 0-.867 1.924z" className="cls-2" data-name="Path 12127" transform="translate(-223.991 -56.41)"/>
                            <path id="Path_12128" d="M277.886 106.4a.867.867 0 0 0 .867-.867V92.339a2.544 2.544 0 0 0-.867 1.924z" className="cls-2" data-name="Path 12128" transform="translate(-272.685 -88.872)"/>
                            <path id="Path_12129" d="M331.781 130.193v8.668a.867.867 0 0 0 .867-.867v-9.726a2.543 2.543 0 0 0-.867 1.925z" className="cls-2" data-name="Path 12129" transform="translate(-321.379 -121.335)"/>
                        </g>
                        <path id="Path_12130" d="M74.991 82.028A13.329 13.329 0 0 1 65.5 78.1L54.29 66.89a3.318 3.318 0 0 1 2.34-5.664 5.227 5.227 0 0 1 2.325.548l4.766 2.384v-16.8a3.467 3.467 0 0 1 5.2-3v-.466a3.467 3.467 0 0 1 6.935 0v.466a3.467 3.467 0 0 1 5.2 3v.466a3.467 3.467 0 0 1 5.2 3V70.76a11.283 11.283 0 0 1-11.265 11.268zm-18.359-19.07a1.585 1.585 0 0 0-1.116 2.7l11.211 11.21a11.6 11.6 0 0 0 8.264 3.422 9.547 9.547 0 0 0 9.535-9.535V50.823a1.734 1.734 0 0 0-3.467 0v7.8a.867.867 0 1 1-1.734 0V47.355a1.734 1.734 0 0 0-3.467 0v11.269a.867.867 0 1 1-1.734 0V43.888a1.734 1.734 0 1 0-3.467 0v14.736a.867.867 0 1 1-1.734 0V47.355a1.734 1.734 0 0 0-3.467 0v18.2a.867.867 0 0 1-1.255.775l-6.026-3.01a3.482 3.482 0 0 0-1.543-.362z" className="cls-3" data-name="Path 12130" transform="translate(-53.32 -40.421)"/>
                    </g>
                    <g id="Group_6224" data-name="Group 6224" transform="translate(479 261)">

                        <path id="Path_12131" d="M-96.551 9.214a25.074 25.074 0 0 0-4.73 14.795 25.6 25.6 0 0 0 25.527 25.525h.106a25.081 25.081 0 0 0 14.693-4.723l1.436-1.029-36-36z" className="cls-4" data-name="Path 12131" transform="translate(106.293 6.542)"/>
                        <path id="Path_12132" d="M-72.636-4.643h-.1a25.071 25.071 0 0 0-14.7 4.73l-1.435 1.03 36 36 1.029-1.435a25.07 25.07 0 0 0 4.728-14.79A25.6 25.6 0 0 0-72.636-4.643z" className="cls-4" data-name="Path 12132" transform="translate(103.185 9.652)"/>
                        <path id="Path_12133" d="M-77.425-11.326a30.579 30.579 0 0 0-30.543 30.544 30.578 30.578 0 0 0 30.544 30.544 30.579 30.579 0 0 0 30.544-30.54 30.631 30.631 0 0 0-30.545-30.548zm14.8 51.354a25.081 25.081 0 0 1-14.693 4.723h-.106a25.6 25.6 0 0 1-25.527-25.526 25.074 25.074 0 0 1 4.73-14.795l1.021-1.435 36 36zm6.009-6.017l-1.029 1.435-36-36 1.435-1.03a25.071 25.071 0 0 1 14.7-4.73h.1A25.6 25.6 0 0 1-51.892 19.22a25.07 25.07 0 0 1-4.728 14.791z" className="cls-5" data-name="Path 12133" transform="translate(107.968 11.326)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</div>
