import * as React from 'react'
import './menu-footer.css'

export const MenuFooter = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Component_390_4" width="27.516" height="27.521" data-name="Component 390 – 4" viewBox="0 0 27.516 27.521">
    <g id="Group_6237" data-name="Group 6237" transform="translate(-485.031 -285.022)">
        <path id="Path_12133" d="M512.195 298.826a13.447 13.447 0 0 0-.217-3.511 13.055 13.055 0 0 0-.693-1.627 14.312 14.312 0 0 0-.5-1.736 13.9 13.9 0 0 0-.936-1.545c-.364-.474-1.1-.613-1.518-1.033a11.732 11.732 0 0 0-2.6-2.424c-.514-.3-1.218-.177-1.773-.406a12.815 12.815 0 0 0-1.626-.644c-.569-.152-1.066-.693-1.664-.771s-1.2.3-1.809.3-1.234-.474-1.817-.4a12.145 12.145 0 0 1-3.464 1.077 9.837 9.837 0 0 0-2.966 1.995 14.371 14.371 0 0 0-1.53.975c-.421.421-.541 1.106-.9 1.578a4.891 4.891 0 0 0-1.646 3.081 12.83 12.83 0 0 0-.626 1.63 13.518 13.518 0 0 0-.576 1.681 13.892 13.892 0 0 0 .046 1.777c0 .6-.412 1.234-.336 1.815a14.056 14.056 0 0 0 .433 1.763c.156.58.678 1.032.906 1.579s.08 1.3.38 1.814a14.375 14.375 0 0 0 .976 1.547c.365.474 1.228.517 1.649.938s.5 1.241.972 1.6 1.187.394 1.708.693a13.5 13.5 0 0 0 1.639.681c.548.227 1 .764 1.576.919a14 14 0 0 0 1.777.358c.584.076 1.2-.365 1.809-.365a14.257 14.257 0 0 0 1.776.114 13.43 13.43 0 0 0 1.74-.364 13.179 13.179 0 0 0 1.578-.823 9.918 9.918 0 0 0 3.226-1.446 13.888 13.888 0 0 0 1.2-1.323c.421-.42 1.24-.511 1.6-.982a14.5 14.5 0 0 0 .8-1.647 13.7 13.7 0 0 0 .759-1.627 5.969 5.969 0 0 0 .988-3.451c.078-.576-.341-1.187-.341-1.79z" className="menu-footer-1" data-name="Path 12133"/>
        <g id="Group_6235" data-name="Group 6235" transform="translate(491.038 289.536)">
            <path id="Path_12134" d="M492.068 305.158c-.027-.039-.172-.071-.163-.118l.76-4.137c-.214 1.086-.464 2.39-.785 4.133a.445.445 0 0 0 .067.206c.028.039.137.045.183.053 3.445.634 4.933.865 6.7 1.155-1.756-.337-3.233-.624-6.678-1.258-.052-.01-.052.008-.084-.034z" className="menu-footer-2" data-name="Path 12134" transform="translate(-491.388 -290.347)"/>
            <path id="Path_12135" d="M507.351 292.634c.008-.034.009-.06 0-.063-2.991-.55-4.505-.794-6.008-1.035 1.5.265 3.008.532 6 1.083.002.001.005.009.008.015z" className="menu-footer-2" data-name="Path 12135" transform="translate(-491.871 -289.869)"/>
            <path id="Path_12136" d="M494.9 290.554c.006-.027.011-.065.018-.1a.334.334 0 0 0-.018.1z" className="menu-footer-2" data-name="Path 12136" transform="translate(-491.542 -289.814)"/>
            <path id="Path_12137" d="M494.889 290.562c0 .012-.006.022-.008.022-.064 0-.1-.039-.121-.024a.482.482 0 0 0 .121.027s.005-.012.008-.025z" className="menu-footer-2" data-name="Path 12137" transform="translate(-491.535 -289.819)"/>
            <path id="Path_12138" d="M504.946 307.719a.083.083 0 0 0-.025.047c.052.051.128.171.16.148s-.043-.134-.067-.2a.108.108 0 0 0-.068.005z" className="menu-footer-2" data-name="Path 12138" transform="translate(-492.054 -290.695)"/>
            <path id="Path_12139" d="M504.349 306.831a.1.1 0 0 1-.009-.038c1.283-6.968 1.479-6.932 2.762-13.9.009-.047-.259-.022-.287-.062s.037-.171.054-.254c0-.006-.007-.014-.012-.015-2.991-.55-4.5-.818-6-1.083a229.745 229.745 0 0 1-6.088-1.048s0 .009-.008.019c-.007.038-.012.076-.018.1 0 .013-.006.025-.008.025a.483.483 0 0 1-.121-.027.059.059 0 0 0-.017.039c-.962 5.226-1.288 6.517-1.932 9.776-.2 1.088-.439 2.4-.76 4.137-.009.047.137.079.163.118s.035.024.083.033c3.445.634 4.922.921 6.678 1.258 1.326.216 2.807.466 5.4.943.009 0 .02.014.03.025a.083.083 0 0 1 .025-.047.107.107 0 0 1 .065.001z" className="menu-footer-3" data-name="Path 12139" transform="translate(-491.389 -289.813)"/>
            <path id="Path_12140" d="M507.356 292.407c-.126-.183-.192-.474-.41-.514-6.035-1.11-6.058-.987-12.093-2.1a.734.734 0 0 0-.877.655c-1.283 6.969-1.258 6.973-2.54 13.941-.041.219-.139.441-.012.625a1.034 1.034 0 0 0 .543.461c6.034 1.111 6.062.965 12.1 2.075.052.009.1-.127.153-.127.167 0 .423.238.564.141.184-.126.093-.475.134-.695 1.283-6.969 1.479-6.933 2.762-13.9.035-.218-.198-.378-.324-.562zM504.389 307c-.032.023-.108-.1-.16-.148-.01-.01-.022-.023-.03-.025-2.59-.477-4.071-.727-5.4-.943-1.764-.289-3.252-.521-6.7-1.155-.047-.009-.156-.014-.183-.053a.445.445 0 0 1-.067-.206c.321-1.742.57-3.047.785-4.133.644-3.259.97-4.55 1.932-9.776a.059.059 0 0 1 .017-.039c.021-.015.057.024.121.024 0 0 0-.009.008-.022.006-.028.011-.076.018-.1 0-.01 0-.02.008-.019 3.044.56 4.56.8 6.088 1.048 1.5.241 3.017.485 6.008 1.035.013 0 .011.028 0 .063-.017.083-.073.227-.054.254s.3.015.287.062c-1.283 6.968-1.479 6.932-2.762 13.9a.1.1 0 0 0 .009.038c.032.058.107.169.075.195z" className="menu-footer-4" data-name="Path 12140" transform="translate(-491.361 -289.779)"/>
        </g>
        <g id="Group_6236" data-name="Group 6236" transform="translate(493.145 292.069)">
            <rect id="Rectangle_2249" width="9.49" height="3.796" className="menu-footer-5" data-name="Rectangle 2249" rx=".577" transform="rotate(10.43 -43.117 7.732)"/>
            <path id="Path_12141" d="M504.541 293.931l-8-1.472a.68.68 0 0 0-.246 1.338l8 1.472a.68.68 0 0 0 .246-1.338z" className="menu-footer-6" data-name="Path 12141" transform="translate(-493.693 -292.448)"/>
            <rect id="Rectangle_2250" width="9.49" height="1.36" className="menu-footer-6" data-name="Rectangle 2250" rx=".68" transform="rotate(10.43 -13.225 10.55)"/>
            <rect id="Rectangle_2251" width="9.49" height="1.36" className="menu-footer-6" data-name="Rectangle 2251" rx=".68" transform="rotate(10.43 -28.15 9.189)"/>
        </g>
    </g>
</svg>
