import * as React from 'react'
import './menu-header-logo.css'

export const HeaderLogo = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Component_391_2" width="28.998" height="29.003" data-name="Component 391 – 2" viewBox="0 0 28.998 29.003">
    <path id="Path_12142" d="M505.293 258.951l-1.416 2.319 1.416-2.316z" className="header-logo-1" data-name="Path 12142" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12143" d="M515.415 257.883l.141.138-.145-.142-9.941.023v.005z" className="header-logo-1" data-name="Path 12143" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12144" d="M516.392 264.2l.007-1.18-.006 1.176-.015 2.607.041.484-1 .963-11.31.019-.957-.981-.024-1.6.025 1.6.957.981 11.309-.019 1-.96-.041-.484z" className="header-logo-1" data-name="Path 12144" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12145" d="M504.882 258.037h.649-.647a1.43 1.43 0 0 0-1.345 1.344 8.086 8.086 0 0 1-.4 2.492v.013a8.131 8.131 0 0 0 .4-2.5 1.43 1.43 0 0 1 1.343-1.349z" className="header-logo-1" data-name="Path 12145" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12146" d="M509.361 258.256h-.005c.335.011.715.016 1.176.019 2.668 0 2.822-.071 5.2-.079l.681.669-.035 3.545.035-3.551-.68-.668c-2.38.009-2.533.08-5.2.08-.456.001-.833-.004-1.172-.015z" className="header-logo-1" data-name="Path 12146" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12147" d="M516.915 263.663l-.007-.942-.018-.6.039-3.259-.04 3.255.018.6.007.949.02 2.632-.02-2.63z" className="header-logo-1" data-name="Path 12147" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12148" d="M505.641 255.594l.322-.657.691-.191-.7.193-.321.657-.221 1.744z" className="header-logo-1" data-name="Path 12148" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12149" d="M517.386 266.13l.1-.034.123-.244-.124.239-.1.038-.1.038z" className="header-logo-1" data-name="Path 12149" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12150" d="M517.793 266.469l.539-.972.985-8.381-.687-.846.687.846-.985 8.381-.542.973-.4.164-.464.188-.033.462-1.478 1.509-11.309-.025-1.508-1.485-.007-1.225v.006l.007 1.225 1.508 1.484 11.309.026 1.478-1.512.033-.462.464-.188v.424-.424z" className="header-logo-1" data-name="Path 12150" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12151" d="M517.429 259.377v.246a6.95 6.95 0 0 0 .376 1.7 6.949 6.949 0 0 1-.376-1.7v-.247a1.345 1.345 0 0 0-1.24-1.354 1.347 1.347 0 0 1 1.24 1.355z" className="header-logo-1" data-name="Path 12151" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12152" d="M517.493 266.972l.029.179a1.319 1.319 0 0 0 .921-.31 1.318 1.318 0 0 1-.918.306z" className="header-logo-1" data-name="Path 12152" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12153" d="M516.392 264.192c-.976.833-1.772.683-3.538 1.72 1.766-1.037 2.564-.886 3.538-1.72z" className="header-logo-1" data-name="Path 12153" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12154" d="M505.294 260.018l.431.7-.431-.7z" className="header-logo-1" data-name="Path 12154" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12155" d="M504.108 268.266l11.31-.019 1-.963-.041-.484.015-2.608c-.974.834-1.772.683-3.538 1.72a1.616 1.616 0 0 1-1.775-.226c-.114-.1-.213-.187-.305-.271l-.623-.051-2.268-1.979-.157-.128h-.16a1.587 1.587 0 0 0-.6.235c-1.7 1.24-2.067 4.267-3.386 2.628a5.939 5.939 0 0 1-.365-.5l-.088.064.024 1.6z" className="header-logo-2" data-name="Path 12155" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12156" d="M505.641 255.594l-.225 1.746h.211a3.642 3.642 0 0 0 .522-1.26 1.293 1.293 0 0 1 1.323-1.194l.013.211c2.812.313 2.826.189 5.636.512 2.586.3 2.794.345 5 .6l-.185-.234-11.28-1.228-.691.191z" className="header-logo-2" data-name="Path 12156" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12157" d="M504.882 258.032h.647l-.056-.126v-.006h-.648l-.718-.025-.959.991-.016 3a8.086 8.086 0 0 0 .4-2.492 1.43 1.43 0 0 1 1.35-1.342z" className="header-logo-2" data-name="Path 12157" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12158" d="M515.56 258.024c-1.959.006-2.176.033-4.52.033a6.694 6.694 0 0 0-1.679.2c.334.011.716.016 1.174.016 2.666 0 2.819-.071 5.2-.08l-.173-.17z" className="header-logo-2" data-name="Path 12158" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12159" d="M524.981 261.872a14.174 14.174 0 0 0-.229-3.7 13.626 13.626 0 0 0-.73-1.715 7.2 7.2 0 0 0-1.51-3.457c-.385-.5-1.159-.646-1.6-1.089a12.385 12.385 0 0 0-2.739-2.556c-.542-.313-1.284-.187-1.868-.427a13.577 13.577 0 0 0-1.714-.679c-.6-.16-1.123-.73-1.754-.813s-1.269.317-1.906.317-1.3-.5-1.915-.42a12.836 12.836 0 0 1-3.651 1.136 10.367 10.367 0 0 0-3.126 2.1 15.092 15.092 0 0 0-1.612 1.027c-.443.444-.569 1.166-.953 1.663a14.387 14.387 0 0 0-1.166 1.461 14.429 14.429 0 0 0-.569 1.786 13.62 13.62 0 0 0-.66 1.718 14.129 14.129 0 0 0-.607 1.772 14.645 14.645 0 0 0 .049 1.873c0 .636-.434 1.3-.355 1.913a14.851 14.851 0 0 0 .456 1.858c.164.61.716 1.087.955 1.664s.084 1.371.4 1.912a15.252 15.252 0 0 0 1.029 1.63c.384.5 1.294.545 1.737.987s.528 1.309 1.025 1.69 1.251.415 1.8.731a14.463 14.463 0 0 0 1.726.718c.578.239 1.05.8 1.661.967a14.443 14.443 0 0 0 1.873.377c.616.08 1.269-.384 1.907-.384a15.015 15.015 0 0 0 1.872.12 14.192 14.192 0 0 0 1.834-.385 14.028 14.028 0 0 0 1.663-.866 14.264 14.264 0 0 0 1.759-.605 14.471 14.471 0 0 0 1.64-.919 14.645 14.645 0 0 0 1.265-1.394c.443-.444 1.306-.539 1.689-1.035a15.289 15.289 0 0 0 .846-1.736 14.609 14.609 0 0 0 .8-1.716 15.14 15.14 0 0 0 .8-1.736 15.021 15.021 0 0 0 .241-1.9c.08-.61-.363-1.252-.363-1.888zm-6.077 4.117a1.331 1.331 0 0 1-1.386 1.165l-.029-.179-.06.053-.01.008-.025.021v-.424l-.464.188-.033.462-1.478 1.512-11.309-.026-1.508-1.484-.007-1.225v-.641l.006-6.565 1.511-1.471.785-.019.2-1.837.55-.986 1.077-.348 11.284 1.3.623.767.007.007-.006-.007.116.012a1.294 1.294 0 0 1 1.175 1.337c-.481 4.191-.546 4.191-1.023 8.38z" className="header-logo-2" data-name="Path 12159" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12160" d="M516.915 263.669v-.006l.02 2.629.352-.128.1-.039v-.506-.457c0-.869.009-1.483.017-2.123l-.008.021c-.172.24-.33.44-.481.609z" className="header-logo-2" data-name="Path 12160" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12161" d="M517.421 259.338v.038l.008.22v-.219a1.347 1.347 0 0 0-1.24-1.355h-.089l.169.169.006.008a1.183 1.183 0 0 1 1.146 1.139z" className="header-logo-2" data-name="Path 12161" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12162" d="M506.966 263.492a1.587 1.587 0 0 1 .6-.235l-1.148.018-3.2 2.348a5.939 5.939 0 0 0 .365.5c1.317 1.636 1.687-1.391 3.383-2.631z" className="header-logo-3" data-name="Path 12162" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12163" d="M517.43 259.623v-.023l-.008-.22v-.038a1.183 1.183 0 0 0-1.149-1.139l.656.656-.039 3.259.017.6.007.942v.006c.151-.173.309-.372.478-.606l.008-.021a2 2 0 0 0 .478-1.276 2.1 2.1 0 0 0-.073-.444 6.95 6.95 0 0 1-.375-1.696z" className="header-logo-3" data-name="Path 12163" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12164" d="M506.365 262.731l.086-.061.17-.042.11-.046h.16l.123-.015.106-.037.036-.008.175.056.11.02.191.009.085.067.2.061.07.065.26.166 2.265 1.967 1.361.112 4.506-2.634.035-3.545-.681-.669c-2.378.008-2.532.079-5.2.079-.461 0-.841-.008-1.176-.019-.445-.015-.8-.037-1.122-.062-.8-.061-1.416-.138-2.7-.158h-.649a1.43 1.43 0 0 0-1.345 1.345 8.131 8.131 0 0 1-.4 2.5v.015l-.023 3.147v.022l3.026-2.173zm-2.488-1.461l1.416-2.319 1.413 2.313h-2.832z" className="header-logo-3" data-name="Path 12164" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12165" d="M512.854 265.912c1.766-1.037 2.562-.887 3.538-1.72v-1.176l-4.241 2.513-1.382-.114c.092.084.191.172.305.271a1.616 1.616 0 0 0 1.78.226z" className="header-logo-3" data-name="Path 12165" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12166" d="M505.294 260.018l-.43.7h.86z" className="header-logo-4" data-name="Path 12166" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12167" d="M511.04 258.057c2.344 0 2.561-.027 4.52-.033l-.141-.138-9.94.023.056.126c1.287.02 1.9.1 2.7.158.325.025.677.047 1.122.062h.005a6.694 6.694 0 0 1 1.678-.198z" className="header-logo-5" data-name="Path 12167" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12168" d="M518.74 256.275l-.116-.012.006.007.687.846-.985 8.381-.539.972-.4.164v.424l.025-.021.01-.008.06-.053.028.178a1.323 1.323 0 0 0 1.383-1.161c.473-4.192.538-4.184 1.011-8.377a1.294 1.294 0 0 0-1.17-1.34z" className="header-logo-5" data-name="Path 12168" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12169" d="M504.106 268.767l11.309.025 1.478-1.509.033-.462.464-.188.4-.164.542-.973.985-8.381-.687-.846-.007-.007-.623-.767-11.284-1.3-1.077.348-.55.986-.2 1.837-.785.019-1.511 1.471-.006 6.565v.635l.007 1.225zm11.31-.517l-11.309.019-.957-.981-.025-1.6.088-.064 3.2-2.348 1.148-.018h.16l.157.128 2.268 1.979.623.051 1.382.114 4.241-2.513-.006 1.18-.013 2.61.041.484zm1.985-5.208c-.008.64-.016 1.254-.017 2.123V266.128l.1-.037.124-.239-.123.244-.1.034-.1.037-.352.128-.02-2.631-.007-.949-.017-.6.04-3.256-.656-.656-.006-.008-.169-.169h.093a1.345 1.345 0 0 1 1.24 1.354v.247a6.949 6.949 0 0 0 .376 1.7 2.1 2.1 0 0 1 .073.444 2 2 0 0 1-.48 1.271zm-11.769-7.442l.321-.658.7-.192 11.28 1.228.185.234c-2.2-.254-2.412-.3-5-.6-2.81-.323-2.824-.2-5.636-.512l-.013-.211a1.293 1.293 0 0 0-1.323 1.194 3.642 3.642 0 0 1-.522 1.26h-.216zm-2.523 9.447l.023-3.147v-.028l.016-3 .959-.991.718.025h.648l9.941-.023.145.143.173.17.68.668-.035 3.551-4.506 2.634-1.361-.112-2.265-1.967-.26-.166-.07-.065-.2-.061-.085-.067-.191-.009-.11-.02-.175-.056-.036.008-.106.037-.123.015h-.16l-.11.046-.17.042-.086.061-.23.161-3.026 2.173z" className="header-logo-5" data-name="Path 12169" transform="translate(-496.355 -247.324)"/>
    <path id="Path_12170" d="M506.708 261.267l-1.413-2.313-1.416 2.316h2.83zm-1.845-.549l.43-.7.431.7z" className="header-logo-5" data-name="Path 12170" transform="translate(-496.355 -247.324)"/>
</svg>
