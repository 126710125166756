import * as React from 'react'
import './filters.css'

export const Filters = (props: any) =>
<svg width={props.svgwidth} height={props.svgheight}  viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="44" height="44" fill="white"/>
  <path d="M22 8.40442H7.41669V11.3456L19.375 21.9792V34.6789L24.625 37.5711V21.9792L36.5834 11.3456V8.40442H22Z"
        fill="#F6D5D2"/>
  <path d="M22 35.3626L23.9063 36.3438V21.7797L35.8647 11.0901V9.09975H29.8855V11.3479L22.0105 21.1672L22 35.3626Z"
        fill="#F6D5D2"/>
  <path d="M22 8.40442H7.41669V11.3456L19.375 21.9792V34.6789L24.625 37.5711V21.9792L36.5834 11.3456V8.40442H22Z"
        stroke="#1E4E69" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>