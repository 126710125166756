import * as React from 'react'
import './includefilter.css'

export const IncludeFilter = (props: any) =>
  <div className="includefilter">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.615 57.615">
  <g id="Group_6251" data-name="Group 6251" transform="translate(-119 -401.018)">
    <g id="Ellipse_9" data-name="Ellipse 9" opacity=".1" transform="translate(119 401.018)">
      <path id="Path_344" d="M1216.688 235.682c1.267 0 2.56-.79 3.784-.631 1.253.163 2.288 1.3 3.484 1.615a27.14 27.14 0 013.406 1.35c1.16.48 2.635.229 3.712.851a28.737 28.737 0 012.929 2.345 28.381 28.381 0 012.514 2.731c.884.881 2.424 1.174 3.187 2.163a29.257 29.257 0 011.958 3.234c.624 1.075.559 2.477 1.04 3.634a27.7 27.7 0 011.451 3.407 28.213 28.213 0 01.454 7.342c0 1.264.88 2.541.72 3.761a29.673 29.673 0 01-.479 3.766 29.049 29.049 0 01-1.593 3.45 28.767 28.767 0 01-1.59 3.407 30.606 30.606 0 01-1.68 3.449c-.763.99-2.475 1.176-3.358 2.057a28.844 28.844 0 01-2.51 2.769 28.683 28.683 0 01-3.259 1.825 28.33 28.33 0 01-3.5 1.208c-1.147.474-2.087 1.4-3.3 1.722a28.143 28.143 0 01-3.645.764 29.723 29.723 0 01-3.72-.238c-1.267 0-2.565.924-3.788.765a29.227 29.227 0 01-3.721-.748c-1.217-.325-2.153-1.449-3.3-1.924a28.315 28.315 0 01-3.432-1.426c-1.085-.626-2.583-.693-3.572-1.451s-1.152-2.477-2.035-3.358-2.691-.974-3.453-1.963a30.086 30.086 0 01-2.043-3.24c-.623-1.074-.308-2.641-.789-3.8s-1.572-2.092-1.9-3.306a29.131 29.131 0 01-.906-3.692c-.16-1.221.7-2.536.7-3.8a29.21 29.21 0 01-.1-3.721 27.92 27.92 0 011.208-3.53 26.905 26.905 0 011.312-3.414c.481-1.157.507-2.475 1.13-3.549a28.249 28.249 0 012.317-2.9c.763-.99 1.01-2.422 1.893-3.3s2.215-1.263 3.207-2.024a20.526 20.526 0 016.21-4.175 29.248 29.248 0 013.675-.844c1.2-.319 2.314-1.247 3.567-1.41 1.227-.163 2.548.829 3.815.829z" className="cls-2" data-name="Path 344" transform="translate(-1187.731 -234.832)"/>
    </g>
    <g id="Group_6229" data-name="Group 6229" transform="translate(-372.47 274.665)">
      <g id="Component_391_1" data-name="Component 391 – 1" transform="translate(500.393 130.859)">
        <g id="Group_6228" data-name="Group 6228" transform="rotate(-30 27.459 7.357)">
          <path id="Path_12134" fill="#fbe57f" d="M20.012 14.58a2.152 2.152 0 012.151 2.079v.048a2.158 2.158 0 01-2.228 2.08h-.717a2.158 2.158 0 012.228 2.08 2.158 2.158 0 01-2.228 2.08h-1.13a2.158 2.158 0 012.229 2.08v.046a2.157 2.157 0 01-2.222 2.08c-4.019.009-12.09 0-18.093 0V13.944h.007C.64 12.4 3 11.008 4.353 10.092a4.509 4.509 0 00.657-.547 4.971 4.971 0 001.432-3.117L6.932 0l1.187.185A3.7 3.7 0 0111.35 3.79l-.183 6.619 9.31.01A2.158 2.158 0 0122.7 12.5a2.158 2.158 0 01-2.228 2.081z" data-name="Path 12134" transform="translate(6.457 1.51)"/>
          <path id="Path_12135" d="M5.639 16.693v1.682H0V0h5.639z" className="cls-2" data-name="Path 12135" transform="translate(.818 11.974)"/>
          <path id="Path_12136" fill="#1e4e69" d="M29.429 13.03a2.692 2.692 0 00-2.756-2.611l-8.766-.009.168-6.074v-.025A4.228 4.228 0 0014.388.19l-1.18-.184a.53.53 0 00-.608.485l-.49 6.428a4.4 4.4 0 01-.787 2.195 4.464 4.464 0 01-.492.588 3.966 3.966 0 01-.579.482c-.174.118-.377.244-.578.377A18.439 18.439 0 006.7 12.829v-1.834a.531.531 0 00-.506-.555H.556a.574.574 0 00-.556.555v18.376a.532.532 0 00.556.506h5.639a.489.489 0 00.506-.506V28.22c4.776 0 8.659.007 11.7.007h5.9a2.709 2.709 0 002.77-2.611v-.046a2.532 2.532 0 00-.69-1.71 2.624 2.624 0 001.8-2.455 2.552 2.552 0 00-.814-1.832 2.586 2.586 0 001.494-2.328v-.055a2.523 2.523 0 00-.836-1.892 2.607 2.607 0 001.404-2.268zM5.639 28.816H1.061V11.5h4.578zM26.67 14.553h-4.218a.531.531 0 000 1.061h3.743a1.633 1.633 0 011.6 1.576v.048a1.611 1.611 0 01-1.667 1.56H22.8a.531.531 0 000 1.061h2.613a1.565 1.565 0 110 3.118h-2.467a.531.531 0 000 1.061h1.335A1.635 1.635 0 0126 25.564v.046a1.654 1.654 0 01-1.718 1.55c-3.068.007-8.83.007-17.586 0V14.608c.531-1.167 2.278-2.317 3.556-3.162.205-.136.406-.264.584-.385a5.044 5.044 0 00.737-.611A5.489 5.489 0 0013.165 7l.447-5.855.62.1h.014a3.185 3.185 0 012.767 3.077l-.183 6.606a.53.53 0 00.53.545l9.31.01a1.62 1.62 0 011.7 1.537 1.619 1.619 0 01-1.7 1.533z" data-name="Path 12136"/>
        </g>
      </g>
    </g>
  </g>
</svg>
</div>
