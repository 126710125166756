import * as React from 'react'

export const DeskeraIcon = (props: any) =>
<svg
  viewBox="3.700000000000003 1.6 158.5 62.6"
  xmlns="http://www.w3.org/2000/svg"
  className={`system-icon ${props.className}`}
>
  <path clipRule="evenodd" d="M131.8 44.2c0-4.1 1.9-7.8 9.7-7.8V41h-.2c-3.2 0-4.9 1.5-4.9 4.9v10.4h-4.6zm30.4 12.1h-4.6v-2.8a7.4 7.4 0 0 1-6.5 3.4c-6.2 0-9.8-4.8-9.8-10.3 0-6.1 4.4-10.2 9.8-10.2a7.4 7.4 0 0 1 6.5 3.4V37h4.6zm-16.3-9.6c0 3.2 2.5 5.8 5.7 5.9h.2c2.2.1 4.2-1.1 5.1-3.1.4-.8.7-1.8.7-2.7s-.1-1.9-.5-2.7c-.9-2.1-3.1-3.5-5.4-3.4-3.2 0-5.8 2.6-5.9 5.8.1 0 .1.1.1.2zm-20.8 3.9c-1 1.5-2.7 2.4-4.5 2.3-2.9.2-5.3-1.9-5.6-4.8h15.6c.5-3.2-.6-6.5-2.9-8.8-1.9-1.9-4.5-3-7.2-3-5.6 0-10.2 4.6-10.1 10.3v.1c0 5.6 4.5 10.2 10.1 10.2h.1c2 0 3.9-.6 5.5-1.7 1.7-1.1 3-2.7 3.8-4.6zm-4.5-10.3c2.7-.1 5 1.7 5.5 4.4h-11c.4-2.7 2.8-4.6 5.5-4.4zm-63.4 1c1.9-.1 3.5 1.2 3.9 3.1h-8c.5-1.8 2.2-3.1 4.1-3.1zm-28.8 9.5V35.9H32c4.8 0 8 2.9 8 7.4s-3.2 7.4-8 7.4zm38.5-.3c-1.5 4.1-5.2 6.4-9.8 6.4-5.5.1-10.1-4.3-10.2-9.8v-.5c-.1-5.5 4.2-10.1 9.8-10.3h.5c5.5 0 9.9 3.9 10 10.3v1.6H53c.5 2.3 2.1 3.8 4.2 3.8 1.4.1 2.7-.4 3.6-1.5zm16.5-.2c0-6.8-9.7-5.1-9.7-8.2 0-.9.9-1.3 1.6-1.3 1 0 1.8.4 1.8 1.5h5.7c-.4-4.1-3.6-6.1-7.4-6.1-3.7 0-7.5 2.3-7.5 6.4 0 2.7 2.3 4.2 4.6 5.1.8.3 1.7.6 2.5.9 1 .4 2.6.8 2.6 2.2 0 1-1.2 1.5-2 1.5-1.1.1-2.1-.7-2.2-1.8h-5.8c.5 4.3 3.8 6.4 7.9 6.4 3.9 0 7.9-2.3 7.9-6.6zM22 30.4v25.9h10.2c8.5 0 14.2-5.3 14.2-13s-5.6-13-14.2-13H22zm83.1 25.9L96 46.1l8.5-9.2H97l-6.1 6.6v-15h-6.2v27.8h6.2v-7l6.3 7zm-32.7-39c2.3-2.3 5.5-3.6 8.8-3.6 6.9 0 12.4 5.6 12.4 12.4v.1c23.7 3.5 18.7 38-3.4 38H21.6c-9.9 0-17.9-8-17.9-17.9 0-7.8 5.1-14.8 12.6-17.1C17.5 13.6 30.5 1.6 46.1 1.6c11 0 21.1 6 26.3 15.7z" fill="#e2002e" fillRule="evenodd"/>
</svg>
