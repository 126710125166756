import * as React from 'react'
import './contentTool.css'

export const ContentTool = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Component_80_1" data-name="Component 80 – 1" width={props.svgwidth} height={props.svgheight} viewBox="0 0 68.396 82.789">
  <g id="Group_3339" data-name="Group 3339">
    <g id="Group_3336" data-name="Group 3336" transform="translate(0 41.836)">
      <g id="Group_723-2" data-name="Group 723-2">
        <path id="Rectangle_123" data-name="Rectangle 123" className="content-tool-1" d="M139.7,193.569c1.432-1.147,1.9.14,3.707.481s1.677,1,3.482,1.347,1.914-.246,3.716.095,1.738.691,3.54,1.032,1.758.592,3.563.935,2.467-1.145,3.383.448c1.068,1.854-.2,1.9-.6,4.008s-.215,2.139-.614,4.242,1.108,3.239-.564,4.578c-1.432,1.146-2.108-.739-3.911-1.081s-1.859-.045-3.664-.388-1.714-.814-3.515-1.155-1.778-.473-3.583-.815-1.933.325-3.737-.017-2.464.469-3.379-1.124c-1.068-1.854.127-2.027.525-4.128a13.608,13.608,0,0,0,.535-4.183C138.542,195.552,138.025,194.906,139.7,193.569Z" transform="translate(-136.519 -191.681)"/>
        <path id="Rectangle_124" data-name="Rectangle 124" className="content-tool-2" d="M140.9,195.424c1.292-.967,1.636.555,3.224.85s2.35-.918,3.2.454c.928,1.5-.369,1.723-.7,3.45s.725,2.319-.684,3.371c-1.294.966-1.5-.261-3.09-.56s-2.207.75-3.058-.621c-.928-1.5.55-1.623.5-3.382C140.236,197.07,139.494,196.473,140.9,195.424Z" transform="translate(-135.808 -191.087)"/>
        <path id="Path_401" data-name="Path 401" className="content-tool-3" d="M149.9,197.375c1.911.36,1.9.43,3.808.792a.65.65,0,0,0,.709-.582l.141.026a.749.749,0,0,0-.594-.8l-.005.027c-1.912-.359-1.892-.452-3.8-.814a.807.807,0,0,0-.855.588h0l.039.005a.7.7,0,0,0,.559.774l.035,0Z" transform="translate(-132.904 -190.816)"/>
        <path id="Path_402" data-name="Path 402" className="content-tool-3" d="M154.345,198.782c-1.752-.329-1.765-.258-3.515-.591s-1.756-.308-3.506-.64a.8.8,0,0,0-.879.551l.192.034-.113-.019a.645.645,0,0,0,.552.726h0l.019-.1c1.752.329,1.727.458,3.476.792s1.736.42,3.487.752a.79.79,0,0,0,.848-.577l-.06-.012a.727.727,0,0,0-.467-.858C154.319,198.793,154.345,198.782,154.345,198.782Z" transform="translate(-133.748 -190.366)"/>
        <path id="Path_403" data-name="Path 403" className="content-tool-3" d="M153.933,200.9c-1.751-.329-1.716-.512-3.467-.844s-1.794-.1-3.545-.434a.654.654,0,0,0-.817.421l.149.027-.171-.031a.719.719,0,0,0,.616.752l.005-.026-.016.082c1.752.329,1.773.218,3.524.55s1.764.262,3.515.592c.353.066.528-.04.595-.39l.264.111-.031-.067c.067-.351-.275-.579-.627-.647C153.912,201,153.933,200.9,153.933,200.9Z" transform="translate(-133.855 -189.757)"/>
        <path id="Path_404" data-name="Path 404" className="content-tool-3" d="M153.56,202.932l-3.5-.658c-1.752-.331-1.752-.327-3.5-.66-.351-.066-.74.039-.806.389l-.074-.014a.893.893,0,0,0,.638.9l.036-.181-.018.088c1.752.331,1.738.4,3.489.726s1.745.368,3.5.7a.727.727,0,0,0,.735-.646l-.091-.008,0-.009c.067-.353-.039-.784-.39-.852C153.513,202.713,153.561,202.934,153.56,202.932Z" transform="translate(-133.976 -189.165)"/>
        <path id="Path_399" data-name="Path 399" className="content-tool-4" d="M160.467,195.952l-20.793-3.936a.52.52,0,0,0-.611.415l-2.406,12.708a.523.523,0,0,0,.416.611h0l20.8,3.935a.522.522,0,0,0,.61-.416h0l2.406-12.706A.524.524,0,0,0,160.467,195.952Zm-2.92,12.61-19.769-3.743,2.211-11.681,19.769,3.743Z" transform="translate(-136.649 -192.006)"/>
        <path id="Path_400" data-name="Path 400" className="content-tool-4" d="M147.6,195.521l-6.341-1.2a1.044,1.044,0,0,0-1.221.832l-1.309,6.916a1.045,1.045,0,0,0,.831,1.222h0l6.342,1.2a1.045,1.045,0,0,0,1.221-.832l1.309-6.918A1.045,1.045,0,0,0,147.6,195.521Zm-1.5,7.944-6.341-1.2,1.309-6.916,6.341,1.2Z" transform="translate(-136.038 -191.325)"/>
      </g>
    </g>
    <g id="Group_3337" data-name="Group 3337" transform="translate(33.189 14.268)">
      <g id="Group_723-2-2" data-name="Group 723-2">
        <path id="Rectangle_123-2" data-name="Rectangle 123" className="content-tool-1" d="M164.514,202.125c-1.618-2.1.258-2.749.8-5.348s1.5-2.4,2.048-5-.3-2.776.244-5.373,1.051-2.494,1.6-5.091.909-2.525,1.454-5.128-1.584-3.6.748-4.88c2.713-1.491,2.749.343,5.781.981s3.088.375,6.119,1.014,4.718-1.51,6.605.947c1.616,2.105-1.129,3.028-1.676,5.627s-.121,2.687-.669,5.288-1.226,2.455-1.772,5.051-.735,2.561-1.282,5.16.413,2.8-.134,5.405.607,3.577-1.723,4.857c-2.714,1.491-2.929-.244-5.959-.88s-2.935-.889-6.034-.9C167.349,203.853,166.4,204.581,164.514,202.125Z" transform="translate(-161.776 -170.737)"/>
        <path id="Rectangle_124-2" data-name="Rectangle 124" className="content-tool-2" d="M167.242,195.085c-1.36-1.9.85-2.351,1.325-4.639s-1.26-3.427.749-4.617c2.191-1.3,2.481.586,4.972,1.112s3.374-.98,4.857,1.09c1.36,1.9-.421,2.167-.9,4.452s1.023,3.216-.986,4.406c-2.191,1.3-2.331-.841-4.878-.817C169.6,196.1,168.722,197.153,167.242,195.085Z" transform="translate(-160.907 -166.399)"/>
        <path id="Path_401-2" data-name="Path 401" className="content-tool-3" d="M170.336,181.181c.576-2.754.677-2.732,1.255-5.487a.939.939,0,0,0-.82-1.042l.043-.2a1.085,1.085,0,0,0-1.173.836l.04.009c-.577,2.754-.71,2.725-1.288,5.479a1.165,1.165,0,0,0,.826,1.255h0l.01-.056a1,1,0,0,0,1.134-.787l-.005-.049A.178.178,0,0,0,170.336,181.181Z" transform="translate(-160.439 -169.639)"/>
        <path id="Path_402-2" data-name="Path 402" className="content-tool-3" d="M172.5,175.838c-.528,2.525-.424,2.546-.957,5.068s-.5,2.531-1.027,5.054a1.158,1.158,0,0,0,.77,1.286l.056-.276-.032.162a.934.934,0,0,0,1.067-.778h0l-.149-.031c.529-2.525.713-2.485,1.247-5.007s.658-2.5,1.189-5.02a1.142,1.142,0,0,0-.809-1.244l-.019.087a1.05,1.05,0,0,0-1.255.649C172.514,175.876,172.5,175.838,172.5,175.838Z" transform="translate(-159.812 -169.459)"/>
        <path id="Path_403-2" data-name="Path 403" className="content-tool-3" d="M175.56,176.508c-.528,2.525-.791,2.469-1.321,4.992s-.2,2.594-.731,5.117a.945.945,0,0,0,.585,1.192l.044-.214-.049.245a1.038,1.038,0,0,0,1.1-.867l-.036-.009.117.025c.529-2.525.367-2.559.9-5.082s.432-2.544.959-5.069c.106-.507-.041-.763-.547-.87l.168-.38-.1.043c-.507-.108-.846.381-.954.889C175.7,176.543,175.56,176.508,175.56,176.508Z" transform="translate(-158.936 -169.3)"/>
        <path id="Path_404-2" data-name="Path 404" className="content-tool-3" d="M178.483,177.067c-.529,2.525-.528,2.524-1.055,5.049s-.522,2.525-1.055,5.047c-.106.505.034,1.071.538,1.177l-.023.108a1.3,1.3,0,0,0,1.32-.9l-.263-.058.128.03c.529-2.526.623-2.5,1.152-5.028s.582-2.513,1.112-5.037a1.051,1.051,0,0,0-.911-1.081l-.016.131-.013,0c-.507-.106-1.135.034-1.242.54C178.164,177.128,178.483,177.066,178.483,177.067Z" transform="translate(-158.083 -169.066)"/>
        <path id="Path_399-2" data-name="Path 399" className="content-tool-4" d="M168.569,172.1l-6.3,29.965a.756.756,0,0,0,.583.9l18.314,3.848a.755.755,0,0,0,.894-.583h0l6.3-29.97a.756.756,0,0,0-.585-.894h0l-18.312-3.848A.757.757,0,0,0,168.569,172.1Zm18.157,4.59-5.987,28.489-16.833-3.537,5.988-28.489Z" transform="translate(-162.254 -170.511)"/>
        <path id="Path_400-2" data-name="Path 400" className="content-tool-4" d="M167.577,185.92l-1.92,9.138a1.512,1.512,0,0,0,1.168,1.791l9.969,2.095a1.514,1.514,0,0,0,1.791-1.168h0l1.92-9.139a1.511,1.511,0,0,0-1.168-1.79l-9.969-2.095A1.512,1.512,0,0,0,167.577,185.92Zm11.449,2.406-1.92,9.138-9.969-2.095,1.921-9.138Z" transform="translate(-161.256 -166.595)"/>
      </g>
    </g>
    <g id="Group_3338" data-name="Group 3338" transform="translate(7.404)">
      <path id="Path_2666" data-name="Path 2666" className="content-tool-1" d="M200.965,209.662a9.27,9.27,0,0,1-2.378-3.094,44.157,44.157,0,0,0-2-4.1,41.481,41.481,0,0,1-1.89-3.889,9.482,9.482,0,0,1-.753-3.266c-.159-1.75-.309-3.4-3.519-4.959a6.278,6.278,0,0,0-1.336-.493l-.585-1.349a2.452,2.452,0,0,0-3.167-1.255l-41.483,16.883a2.388,2.388,0,0,0-1.321,1.31,2.31,2.31,0,0,0,.016,1.806L153.2,231.834a2.451,2.451,0,0,0,3.023,1.317c.288.176.6.345.942.512a6.436,6.436,0,0,0,2.778.757,5.411,5.411,0,0,0,3.169-1.262,12.489,12.489,0,0,1,2.447-1.373,11.026,11.026,0,0,0,3.714-2.356,8.476,8.476,0,0,1,2.932-1.864c1.754-.713,2.673-1.015,3.567-1.307.929-.3,1.891-.617,3.741-1.37a12.3,12.3,0,0,1,3.472-.92,14.788,14.788,0,0,0,4.114-1.093,22.239,22.239,0,0,0,3.667-1.846,19.649,19.649,0,0,1,3.286-1.654,8.422,8.422,0,0,1,3.531-.411c1.96.048,4.393.111,5.418-2.977C204.1,212.681,202.432,211.076,200.965,209.662Z" transform="translate(-142.361 -151.631)"/>
      <path id="Path_2667" data-name="Path 2667" className="content-tool-1" d="M150.365,183.178a8.519,8.519,0,0,0,.33,2.5,6.06,6.06,0,0,1,.244,1.837,9.821,9.821,0,0,1-.166,1.956,12.545,12.545,0,0,0-.2,2.381c0,1.032-.019,1.561-.04,2.087-.022.566-.043,1.122-.04,2.286a2.081,2.081,0,0,0,.706,1.472,2.7,2.7,0,0,0,1.815.695h0a2.01,2.01,0,0,0,1.924-2.2,12.772,12.772,0,0,1,.133-1.995,15.2,15.2,0,0,0,.157-2.334c0-1.126-.039-1.69-.078-2.253-.036-.52-.071-1.04-.071-2.078,0-1.059.021-1.589.041-2.144s.04-1.1.04-2.191a16.073,16.073,0,0,1,.11-2.036,18.194,18.194,0,0,0,.126-2.3c0-1.13-.039-1.694-.079-2.259-.035-.52-.071-1.041-.075-2.172a2.165,2.165,0,0,0-2.3-1.983,2.315,2.315,0,0,0-2.511,2.082c0,1.125.038,1.688.075,2.249.035.52.07,1.04.07,2.079a17.134,17.134,0,0,1-.106,2.056A19.843,19.843,0,0,0,150.365,183.178Z" transform="translate(-139.991 -155.966)"/>
      <path id="Path_2668" data-name="Path 2668" className="content-tool-1" d="M159.412,166.409c0,1.12.039,1.68.079,2.254.036.512.073,1.027.073,2.048a12,12,0,0,0,.207,2.376,9.4,9.4,0,0,1,.169,1.925c0,1-.009,1.527-.019,2.127-.01.543-.019,1.086-.019,2.174a10.976,10.976,0,0,1-.15,1.961,13.359,13.359,0,0,0-.179,2.344,7.993,7.993,0,0,1-.194,1.9,10.454,10.454,0,0,0-.246,2.41c0,.953-.03,1.471-.057,1.971-.035.6-.067,1.16-.034,2.48l.069.311a2.944,2.944,0,0,0,2.762,1.87l.218-.03a1.987,1.987,0,0,0,1.431-1.921l.043-.366a10.982,10.982,0,0,1,.171-1.922,12.743,12.743,0,0,0,.2-2.394,14.147,14.147,0,0,0-.169-2.333,11.549,11.549,0,0,1-.143-1.968c0-1.02.039-1.532.079-2.051.043-.55.087-1.116.087-2.249a20.813,20.813,0,0,0-.106-2.272,18.875,18.875,0,0,1-.093-2.029,5.222,5.222,0,0,1,.268-1.785,7.761,7.761,0,0,0,.376-2.518,22.09,22.09,0,0,0-.1-2.29,19.234,19.234,0,0,1-.091-2.017,11.877,11.877,0,0,1,.139-1.951,13.611,13.611,0,0,0,.145-2.491l-.075-.334a3.062,3.062,0,0,0-2.557-1.907l-.251.013a2.933,2.933,0,0,0-2.411,2.062l-.039.284a10.273,10.273,0,0,0,.235,2.442A7.92,7.92,0,0,1,159.412,166.409Z" transform="translate(-137.438 -159.729)"/>
      <path id="Path_2669" data-name="Path 2669" className="content-tool-1" d="M168.107,174.983c0,.866.01,1.321.023,1.825.013.439.025.878.025,1.755,0,.845-.006,1.286-.013,1.708-.008.474-.014.927-.01,1.909a2.1,2.1,0,0,0,.664,1.554,1.966,1.966,0,0,0,1.322.507h.139a2.227,2.227,0,0,0,2.218-2.1c0-.91-.013-1.364-.025-1.82-.012-.439-.023-.878-.023-1.752a13.9,13.9,0,0,0-.119-1.955,11.835,11.835,0,0,1-.1-1.624,9.055,9.055,0,0,1,.119-1.6,11.335,11.335,0,0,0,.146-1.838h2.238l-2.866-1.794a2.186,2.186,0,0,0-1.264-.749l-.975-.419-.327.587a2.6,2.6,0,0,0-1.379,2.235,14.863,14.863,0,0,0,.109,1.929A12.359,12.359,0,0,1,168.107,174.983Z" transform="translate(-134.796 -157.105)"/>
      <path id="Path_2670" data-name="Path 2670" className="content-tool-1" d="M176.7,182.624a2.147,2.147,0,0,0,.483,1.55,2.278,2.278,0,0,0,1.589.819h.123l.062-.005a2.236,2.236,0,0,0,2.076-2.272c0-.673.018-1.01.038-1.347.019-.371.04-.743.04-1.493a2.383,2.383,0,0,0-2.4-2.3,2.342,2.342,0,0,0-2.153,2.3,14.117,14.117,0,0,0,.073,1.55A11.929,11.929,0,0,1,176.7,182.624Z" transform="translate(-132.232 -154.443)"/>
    </g>
  </g>
  <g id="Group_729" data-name="Group 729" transform="translate(10.012 1.405)">
    <g id="Group_723" data-name="Group 723">
      <g id="Group_723-2-3" data-name="Group 723-2" transform="translate(0 35.439)">
        <path id="Rectangle_123-3" data-name="Rectangle 123" className="content-tool-1" d="M146.846,207.567c1.156-3.6,3.431-1.924,6.919-3.388s4.026-.183,7.518-1.647,3.008-2.6,6.494-4.065,3.773-.788,7.259-2.253,3.7-.981,7.187-2.447,2.937-4.773,6.325-3.077c3.944,1.973,1.824,3.5,3.531,7.571s2.061,3.921,3.768,7.991,5.59,4.307,4.238,8.509c-1.158,3.6-4.463,1.129-7.951,2.592s-3.248,2.035-6.74,3.5-3.87.55-7.356,2.013-3.6,1.212-7.085,2.677-2.951,2.756-6.443,4.22-3.7,3.609-7.086,1.914c-3.946-1.973-2.087-3.629-3.794-7.7s-1.335-4.159-3.837-7.8C147.117,212.289,145.494,211.768,146.846,207.567Z" transform="translate(-143.785 -187.543)"/>
        <path id="Rectangle_124-3" data-name="Rectangle 124" className="content-tool-2" d="M151.513,206.407c1.123-3.132,3.443-.907,6.509-2.2s3-4.251,6.017-2.862c3.3,1.517,1.323,3.383,2.722,6.728s3.882,3.16,2.657,6.574c-1.124,3.13-2.881,1.262-5.948,2.55s-2.941,3.8-5.962,2.413c-3.3-1.517-.9-3.414-2.993-6.378C152.237,210,150.286,209.816,151.513,206.407Z" transform="translate(-142.371 -184.356)"/>
        <path id="Path_401-3" data-name="Path 401" className="content-tool-3" d="M169.6,198.979c3.7-1.555,3.751-1.419,7.447-2.971a1.342,1.342,0,0,0,.557-1.807l.274-.115a1.544,1.544,0,0,0-1.929-.7l.022.053c-3.7,1.555-3.768,1.375-7.465,2.927a1.662,1.662,0,0,0-.8,1.984h0l.073-.034a1.431,1.431,0,0,0,1.841.693l.054-.047A.281.281,0,0,0,169.6,198.979Z" transform="translate(-137.487 -186.637)"/>
        <path id="Path_402-3" data-name="Path 402" className="content-tool-3" d="M178.858,196.975c-3.386,1.426-3.327,1.564-6.714,2.981s-3.37,1.467-6.754,2.889a1.651,1.651,0,0,0-.884,1.944l.368-.158-.215.093a1.33,1.33,0,0,0,1.776.621h0l-.084-.2c3.387-1.426,3.489-1.177,6.878-2.592s3.46-1.252,6.846-2.674a1.625,1.625,0,0,0,.8-1.955l-.117.048a1.5,1.5,0,0,0-1.777-.946C178.826,197.022,178.859,196.975,178.858,196.975Z" transform="translate(-138.43 -185.542)"/>
        <path id="Path_403-3" data-name="Path 403" className="content-tool-3" d="M180.582,201.054c-3.387,1.426-3.533,1.073-6.92,2.494s-3.2,1.866-6.588,3.287a1.346,1.346,0,0,0-.924,1.651l.286-.122-.327.141a1.477,1.477,0,0,0,1.911.591l-.018-.049.065.157c3.387-1.426,3.295-1.642,6.682-3.064s3.331-1.555,6.718-2.981c.68-.286.861-.668.577-1.347l.582-.109-.13-.08c-.285-.68-1.132-.683-1.815-.4C180.653,201.243,180.582,201.054,180.582,201.054Z" transform="translate(-137.939 -184.336)"/>
        <path id="Path_404-3" data-name="Path 404" className="content-tool-3" d="M182.259,205.067l-6.771,2.852c-3.387,1.426-3.382,1.434-6.77,2.852-.679.285-1.228.907-.944,1.584l-.144.06a1.843,1.843,0,0,0,2.119.82l-.145-.355.07.174c3.387-1.426,3.437-1.3,6.824-2.722s3.417-1.352,6.8-2.774a1.5,1.5,0,0,0,.53-1.944l-.166.088-.014-.013c-.286-.68-.959-1.3-1.64-1.017C181.929,204.744,182.261,205.067,182.259,205.067Z" transform="translate(-137.486 -183.284)"/>
        <path id="Path_399-3" data-name="Path 399" className="content-tool-4" d="M185.254,188.239l-40.219,16.884a1.078,1.078,0,0,0-.578,1.409l10.32,24.579a1.076,1.076,0,0,0,1.409.575h0l40.223-16.89a1.076,1.076,0,0,0,.576-1.408h0L186.666,188.81A1.078,1.078,0,0,0,185.254,188.239Zm9.323,25L156.34,229.288,146.855,206.7l38.237-16.052Z" transform="translate(-144.374 -188.154)"/>
        <path id="Path_400-3" data-name="Path 400" className="content-tool-4" d="M162.985,199.344l-12.266,5.15a2.154,2.154,0,0,0-1.152,2.819l5.618,13.379A2.153,2.153,0,0,0,158,221.846h0l12.266-5.15a2.154,2.154,0,0,0,1.152-2.819L165.8,200.5A2.155,2.155,0,0,0,162.985,199.344Zm6.452,15.366-12.266,5.15-5.618-13.379,12.266-5.15Z" transform="translate(-142.885 -184.89)"/>
      </g>
      <g id="Path_406" data-name="Path 406" className="content-tool-5" transform="translate(10.058 16.467)">
        <path id="Path_403-2-2" data-name="Path 403-2" className="content-tool-4" d="M153.363,173.523c-.469,0-1.158.259-1.158.784,0,2.165.14,2.165.14,4.328s-.213,2.165-.213,4.329.557,2.166.557,4.333-.355,2.168-.355,4.336-.084,2.169-.078,4.337c.014.531.662.922,1.132.907.45-.014.614-.4.627-.907,0-2.165.281-2.165.281-4.329s-.144-2.165-.144-4.331.079-2.167.079-4.333.228-2.17.228-4.337-.144-2.168-.145-4.337a.847.847,0,0,0-.906-.784Z" transform="translate(-152.133 -173.517)"/>
      </g>
      <g id="Path_407" data-name="Path 407" className="content-tool-5" transform="translate(20.89)">
        <path id="Path_404-2-2" data-name="Path 404-2" className="content-tool-4" d="M161.761,160.813a1.556,1.556,0,0,0-1.27,1.076c-.021,2.152.413,2.156.413,4.307s.146,2.152.146,4.3.364,2.15.364,4.3-.038,2.15-.038,4.3-.319,2.153-.319,4.3-.428,2.154-.428,4.307-.137,2.163-.1,4.316a1.534,1.534,0,0,0,1.426,1.017c.35-.075.35-.635.416-1.037.03-2.15.358-2.146.358-4.3s-.3-2.15-.3-4.3.162-2.149.162-4.3-.194-2.15-.194-4.3.625-2.153.625-4.3-.188-2.154-.188-4.307.306-2.161.285-4.314A1.674,1.674,0,0,0,161.761,160.813Z" transform="translate(-160.49 -160.813)"/>
      </g>
      <g id="Path_408" data-name="Path 408" className="content-tool-5" transform="translate(32.1 11.85)">
        <path id="Path_405-2" data-name="Path 405-2" className="content-tool-4" d="M170.076,169.958c-.469,0-.937.626-.937,1.156,0,1.786.2,1.786.2,3.574s.047,1.79.047,3.579-.029,1.789-.023,3.579c.016.53.27.82.739.8a.88.88,0,0,0,.919-.8c0-1.786-.047-1.786-.047-3.574s-.209-1.79-.209-3.579.259-1.79.259-3.579c0-.53-.424-1.138-.893-1.138C170.057,169.945,170.076,169.958,170.076,169.958Z" transform="translate(-169.139 -169.955)"/>
      </g>
      <g id="Path_409" data-name="Path 409" className="content-tool-5" transform="translate(42.979 23.128)">
        <path id="Path_406-2" data-name="Path 406-2" className="content-tool-4" d="M178.463,178.656a1.028,1.028,0,0,0-.931,1.015c0,1.418.13,1.418.13,2.835a.893.893,0,0,0,.8.98h0a.932.932,0,0,0,.849-.98c0-1.417.075-1.417.075-2.835A1.027,1.027,0,0,0,178.463,178.656Z" transform="translate(-177.532 -178.656)"/>
      </g>
    </g>
  </g>
</svg>