import * as React from 'react'
import './day-and-time.css'

export const DayAndTime = (props: any) =>
  <svg  viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight}>
  <path d="M36.75 14.3957V11.8332C36.75 10.927 36.39 10.058 35.7493 9.41722C35.1085 8.77647 34.2395 8.4165 33.3333 8.4165H9.41667C8.51051 8.4165 7.64147 8.77647 7.00072 9.41722C6.35997 10.058 6 10.927 6 11.8332V35.7498C6 36.656 6.35997 37.525 7.00072 38.1658C7.64147 38.8065 8.51051 39.1665 9.41667 39.1665H15.3958" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M28.2083 5V11.8333" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M14.5417 5V11.8333" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M6 18.6665H14.5417" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M28.2083 39.1665C33.8693 39.1665 38.4583 34.5774 38.4583 28.9165C38.4583 23.2556 33.8693 18.6665 28.2083 18.6665C22.5474 18.6665 17.9583 23.2556 17.9583 28.9165C17.9583 34.5774 22.5474 39.1665 28.2083 39.1665Z" fill="#FBE57F" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M30.7708 31.4792L28.2083 29.4292V25.5" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>