import * as React from 'react'
import './mastercard.css'

export const MastercardIcon = (props: any) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="33.386" height="23.847" viewBox="0 0 33.386 23.847" className="mastercard-icon">
      <g id="Group_6425" data-name="Group 6425" transform="translate(-92 -93.153)">
          <path id="Path_11939" d="M36.386 29.667a3.18 3.18 0 0 1-3.18 3.18H6.18A3.18 3.18 0 0 1 3 29.667V12.18A3.179 3.179 0 0 1 6.18 9h27.026a3.179 3.179 0 0 1 3.18 3.18z" className="cls-1" data-name="Path 11939" transform="translate(89 84.153)"/>
          <path id="Path_11940" d="M3 14h33.386v4.769H3z" className="cls-2" data-name="Path 11940" transform="translate(89 83.128)"/>
          <path id="Path_11941" d="M30 26.435h5.564V32.1H30z" className="cls-3" data-name="Path 11941" transform="translate(83.462 80.577)"/>
          <path id="Path_11942" d="M36.282 25a3.958 3.958 0 0 0-2.782 1.141 3.964 3.964 0 0 1 0 5.668A3.973 3.973 0 1 0 36.282 25z" className="cls-4" data-name="Path 11942" transform="translate(82.744 80.871)"/>
          <g id="Group_6424" data-name="Group 6424">
              <path id="Path_11943" d="M30.564 28.974a3.959 3.959 0 0 1 1.192-2.834 3.974 3.974 0 1 0 0 5.668 3.959 3.959 0 0 1-1.192-2.834z" className="cls-5" data-name="Path 11943" transform="translate(84.488 80.871)"/>
          </g>
      </g>
  </svg>
