import * as React from 'react'
import './contentHistory.css'

export const ContentHistory = (props: any) =>
  <svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" fill="white"/>
      <path
        d="M22.7 36.6C31.15 36.6 38 29.75 38 21.3C38 12.85 31.15 6 22.7 6C14.2501 6 7.40002 12.85 7.40002 21.3C7.40002 29.75 14.2501 36.6 22.7 36.6Z"
        fill="#FBE57F"/>
      <path
        d="M11.8812 32.1188C14.3844 34.622 17.6781 36.1797 21.2011 36.5265C24.7241 36.8733 28.2584 35.9878 31.2016 34.0207C34.1448 32.0537 36.3148 29.1269 37.3419 25.7391C38.369 22.3514 38.1895 18.7123 36.8341 15.442C35.4787 12.1718 33.0312 9.47269 29.9087 7.80477C26.7863 6.13686 23.182 5.60332 19.7103 6.29507C16.2385 6.98683 13.114 8.86107 10.8693 11.5984C8.62454 14.3357 7.39848 17.7668 7.40003 21.3068V24.7"
        stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 21.3L7.4 24.7L10.8 21.3M21 14.5V23H29.5" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
  </svg>