import * as React from 'react'
import './frame-landscape.css'

export const FrameLandscapeIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgWidth || 50} height={props.svgHeight || 50} viewBox="0 0 50 50">
    <g id="Group_6192" data-name="Group 6192" transform="translate(-928 -392)">
        <path id="Rectangle_2233" d="M0 0h50v50H0z"
        className="frameLandscapeIcon1" data-name="Rectangle 2233" transform="translate(928 392)"/>
        <g id="frame-landscape" transform="translate(937 371.671)">
            <path id="Path_12111" d="M30.874 33.331H.5a.5.5 0 0 0-.5.5v23.75a.5.5 0 0 0 .5.5h30.374a.5.5 0 0 0 .5-.5V33.828a.5.5 0 0 0-.5-.497zm-2.485 20.782l-6.836-7.079a.2.2 0 0 0-.281-.011L16.531 51.2l-6.06-7.462a.2.2 0 0 0-.162-.075.2.2 0 0 0-.159.082l-7.168 9.613V36.313h25.407z" className="frameLandscapeIcon2" data-name="Path 12111"/>
            <path id="Path_12112" d="M187.969 91.368a2.439 2.439 0 1 0-2.438-2.439 2.439 2.439 0 0 0 2.438 2.439z"
            className="frameLandscapeIcon2" data-name="Path 12112" transform="translate(-167.088 -47.875)"/>
        </g>
    </g>
</svg>
