import * as React from 'react'
import './klaviyo.css'

export const KlaviyoIcon = (props: any) =>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 1246 885.302" className={`system-icon ${props.className}`}>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="651.5861" y1="990.9395"
            x2="651.5861" y2="212.3118" gradientTransform="matrix(0.7071 0 0 0.7071 139.2433 -149.1257)">
            <stop  offset="0" className="klaviyo-3"/>
            <stop  offset="0.3314" className="klaviyo-4"/>
            <stop  offset="0.4859" className="klaviyo-5"/>
            <stop  offset="0.7506" className="klaviyo-6"/>
            <stop  offset="0.903" className="klaviyo-7"/>
            <stop  offset="1" className="klaviyo-7"/>
        </linearGradient>
        <path className="klaviyo-1" d="M208.444,250.314L574.335,7.925c14.428-9.234,36.935-9.234,51.363,0l365.891,242.388
            c21.93,14.428,21.93,38.09,0,52.517l-49.055,32.318c-72.139-113.692-199.104-188.717-342.806-188.717
            c-144.279,0-270.667,75.602-342.806,188.717l-49.055-32.318C186.514,288.403,186.514,264.164,208.444,250.314z M599.728,247.428
            c-109.075,0-204.299,57.711-258.547,143.124l84.259,55.98c35.781-58.866,99.841-98.11,173.712-98.11
            c73.294,0,137.93,39.244,173.712,98.11l84.259-55.98C804.604,305.139,708.803,247.428,599.728,247.428z M599.728,449.995
            c-38.09,0-71.562,21.353-89.453,52.517l56.557,36.935c9.234,7.503,20.199,12.119,32.896,12.119s24.239-4.617,32.896-12.119
            l56.557-36.935C672.444,471.926,638.394,449.995,599.728,449.995z"/>
        <path className="klaviyo-2" d="M81.084,763.365l79.2,113.765h-55.625l-59.718-87.995c0,28.912,0,59.082,0,87.995H0V652.426h44.941
            c0,29.854,0,59.713,0,89.568l56.571-89.568H154L81.084,763.365z M238.747,652.426h-44.942V877.13h116.28v-44.941h-71.339V652.426z
            M443.365,644.254l94.59,232.876H487.36l-9.115-24.197h-74.485l-8.799,24.197h-50.91l94.598-232.876H443.365z M461.586,810.817
            l-20.42-58.451l-21.06,58.451H461.586z M601.239,777.19l-46.195-124.764H504.45l94.59,232.876h4.715l94.598-232.876h-50.91
            L601.239,777.19z M732.103,877.13h44.942V652.426h-44.942V877.13z M957.135,652.426l-45.888,77.311l-45.879-77.311H810.68
            l78.254,123.511V877.13h44.626V775.937l78.569-123.511H957.135z M1245,760.689c0,64.305-52.13,116.436-116.436,116.436
            c-64.306,0-116.436-52.13-116.436-116.436c0-64.306,52.13-116.436,116.436-116.436C1192.87,644.254,1245,696.384,1245,760.689z
            M1200.99,760.689c0-40-32.426-72.426-72.425-72.426s-72.426,32.426-72.426,72.426c0,40,32.426,72.425,72.426,72.425
            S1200.99,800.689,1200.99,760.689z"/>
    </svg>
