import * as React from 'react'
import './eye-closed.css'

export const EyeClosedIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Component_385_1" width="33" height="33"
    data-name="Component 385 – 1" viewBox="0 0 33 33">
    <g id="Group_6161" data-name="Group 6161" transform="translate(-1214 -399)">
        <rect id="Rectangle_2243" width="33" height="33" className="eye-closed-1"
            data-name="Rectangle 2243" rx="5" transform="translate(1214 399)"/>
        <path id="Path_12120" d="M260.795 314.179a.428.428 0 0 0 .608-.6l-2.089-2.108a9.761 9.761 0 0 0 1.592-1.85.428.428 0 0 0-.049-.513c-.2-.214-4.883-5.244-10.786-5.244s-10.589 5.03-10.786 5.244a.428.428 0 0 0-.05.513 9.786 9.786 0 0 0 1.593 1.85l-2.089 2.108a.428.428 0 0 0 .608.6l2.129-2.149a12.6 12.6 0 0 0 1.37.963l-1.508 2.522a.428.428 0 1 0 .735.439l1.52-2.542a12.875 12.875 0 0 0 1.413.629l-.89 2.823a.428.428 0 1 0 .816.258l.884-2.8a13.755 13.755 0 0 0 1.546.371l-.435 2.948a.428.428 0 0 0 .361.486.413.413 0 0 0 .063 0 .428.428 0 0 0 .423-.366l.435-2.946q.688.083 1.434.1v2.964a.428.428 0 0 0 .856 0v-2.959q.758-.02 1.457-.105l.6 2.971a.429.429 0 0 0 .419.344.412.412 0 0 0 .085-.009.428.428 0 0 0 .335-.5l-.591-2.931a13.792 13.792 0 0 0 1.45-.346l.961 2.794a.428.428 0 0 0 .809-.278l-.958-2.786a12.921 12.921 0 0 0 1.484-.656l1.52 2.542a.428.428 0 1 0 .735-.439l-1.508-2.522a12.634 12.634 0 0 0 1.37-.963zm-20.639-4.738c1.012-1 5.11-4.724 9.916-4.724s8.9 3.725 9.916 4.724a12.93 12.93 0 0 1-19.832 0z" className="eye-closed-2" data-name="Path 12120" transform="translate(980.229 105.139)"/>
    </g>
</svg>
