import * as React from 'react'
import './markettingtools.css'

export const MarkettingToolsIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.width || 95.017} height={props.height || 94} viewBox="0 0 95.017 94">
    <g id="Group_6423" data-name="Group 6423" transform="translate(-255 -95)">
        <g id="Group_6297" data-name="Group 6297" transform="translate(255 95)">
            <g id="Group_6296" data-name="Group 6296">
                <g id="Ellipse_9" data-name="Ellipse 9">
                    <path id="Path_344-2" d="M185.638 245.524c2.091 0 4.222-1.289 6.244-1.029 2.066.271 3.774 2.114 5.746 2.635a45.016 45.016 0 0 1 5.617 2.2c1.915.784 4.344.375 6.121 1.389s3.2 2.588 4.83 3.827 2.688 3.019 4.145 4.455 4 1.916 5.256 3.531a47.44 47.44 0 0 1 3.23 5.276c1.03 1.754.922 4.04 1.719 5.93a44.759 44.759 0 0 1 2.391 5.557 45.592 45.592 0 0 1 .75 11.978c0 2.059 1.449 4.145 1.189 6.137a47.8 47.8 0 0 1-.79 6.144c-.536 1.979-1.842 3.759-2.624 5.63a46.738 46.738 0 0 1-2.624 5.558c-1.035 1.767-1.515 4.017-2.77 5.626s-4.081 1.92-5.538 3.356-2.508 3.276-4.139 4.517a47.187 47.187 0 0 1-5.375 2.982c-1.791 1.025-3.863 1.188-5.776 1.97-1.892.774-3.439 2.28-5.448 2.809a46.991 46.991 0 0 1-6.01 1.246c-2.019.26-4.045-.387-6.135-.387s-4.23 1.507-6.244 1.247a48.49 48.49 0 0 1-6.138-1.221c-2.006-.529-3.55-2.365-5.443-3.14a46.961 46.961 0 0 1-5.66-2.327c-1.791-1.022-4.259-1.128-5.89-2.367s-1.9-4.04-3.356-5.477-4.435-1.588-5.7-3.2a49.074 49.074 0 0 1-3.368-5.283c-1.026-1.753-.508-4.309-1.3-6.2-.785-1.867-2.593-3.414-3.129-5.395a47.163 47.163 0 0 1-1.495-6.023c-.265-1.989 1.159-4.138 1.159-6.2s-.421-4.079-.158-6.069c.269-2.038 1.465-3.812 1.991-5.759a43.567 43.567 0 0 1 2.163-5.57c.795-1.888.836-4.037 1.865-5.789a46.353 46.353 0 0 1 3.821-4.737c1.253-1.61 1.666-3.952 3.122-5.39s3.652-2.059 5.284-3.3 3-2.84 4.79-3.861a47.173 47.173 0 0 1 5.453-2.953c1.892-.774 4.054-.847 6.062-1.377 1.97-.522 3.816-2.036 5.882-2.3 2.012-.263 4.187 1.354 6.28 1.354z" className="marketting-tools-1" data-name="Path 344-2" transform="translate(-137.892 -244.141)"/>
                </g>
                <path id="Path_12181" d="M205.424 307.436c-4.758 4.036-5.635.961-11.907.43s-5.743-.409-12.012-.939-5.248.15-11.522-.38-10.594 1.3-14.609-3.48c-4.1-4.881-2.726-5.611-2.194-11.91s1.3-6.751 1.837-13.054-1.814-9.022 3.052-13.15c4.758-4.036 4.941-.949 11.213-.419s7.177-.079 13.446.451 6.342 1.528 12.615 2.059 9.22-2.18 13.236 2.6c4.1 4.881 1.172 5.76.639 12.058s-.4 6.249.413 12.527c.869 6.691.659 9.079-4.207 13.207z" className="marketting-tools-2" data-name="Path 12181" transform="translate(-134.77 -240.138)"/>
                <g id="Group_6295" data-name="Group 6295" transform="translate(25.86 28.512)">
                    <path id="Path_12182" d="M165.647 288.335a1.658 1.658 0 0 0 2.139 2.535l6.933-5.852-2.138-2.533zm31.9-14.524l-5.012.288a.981.981 0 0 0-.693 1.613l.763.905-5.637 4.107-4.047-4.794a1.656 1.656 0 0 0-2.336-.2l-1.241 1.048 6.085 7.262a1.658 1.658 0 0 0 2.243.271l7.076-5.157.978 1.159a.981.981 0 0 0 1.706-.413l1.126-4.892a.98.98 0 0 0-1.015-1.197z" className="marketting-tools-3" data-name="Path 12182" transform="translate(-157.956 -266.324)"/>
                    <path id="Path_12183" d="M200.655 299.64l-38-3.215.642-7.6c.023-.017.05-.021.07-.039l10.68-9.013 13.047 15.458a1.657 1.657 0 0 0 2.227.281l7.672-5.452a1.658 1.658 0 1 0-1.921-2.7l-6.428 4.568-13.132-15.559a1.656 1.656 0 0 0-2.336-.2l-9.485 8 1.246-14.736a1.658 1.658 0 1 0-3.3-.279l-2.426 28.646a1.658 1.658 0 0 0 1.512 1.792l39.653 3.355a1.658 1.658 0 1 0 .279-3.3z" className="marketting-tools-4" data-name="Path 12183" transform="translate(-159.205 -267.64)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
