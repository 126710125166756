import * as React from 'react'

export const EnterpriseReport = (props: any) =>
  <svg viewBox="0 0 44 44" fill="none" width={props.svgwidth} height={props.svgwidth}
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.5 7H13C12.2044 7 11.4413 7.31607 10.8787 7.87868C10.3161 8.44129 10 9.20435 10 10V34C10 34.7956 10.3161 35.5587 10.8787 36.1213C11.4413 36.6839 12.2044 37 13 37H31C31.7956 37 32.5587 36.6839 33.1213 36.1213C33.6839 35.5587 34 34.7956 34 34V14.5L26.5 7Z"
      fill="#B8E5E1" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M25 7V13C25 13.7956 25.3161 14.5587 25.8787 15.1213C26.4413 15.6839 27.2044 16 28 16H34" stroke="#1E4E69"
          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 31V29" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 31V22" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M28 31V26" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
